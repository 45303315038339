import useMediaPlayerStore from "stores/mediaPlayerStore";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetClose,
} from "components/Sheet";
import { useUserState } from "context/userContext";
import { MediaCardComponent } from "components/MediaCardComponent";
import { Tooltip } from "components/Tooltip";
import Icons from "components/icons/Icons";
import type { B2bQuest, Resource } from "types/__generated__/graphql";

export const QuestMediationLanding = ({ quest }: { quest: B2bQuest }) => {
  const isPlaying = useMediaPlayerStore((state) => state.isPlaying);
  const track = useMediaPlayerStore((state) => state.track);
  const { userAccessState } = useUserState();

  return (
    <Sheet>
      <SheetTrigger className="flex justify-center">
        <button type="button" className="rounded-lg">
          <Tooltip
            tooltipContent={<p className="caption-disclaimer">Learn more</p>}
          >
            <div className="p-2 rounded-[128px] border border-white-24a hover:bg-brown-24a justify-start items-center gap-2.5 inline-flex">
              <div className="justify-center items-center flex text-white-90a">
                <Icons name="list-filled" width={20} height={21} />
              </div>
            </div>
          </Tooltip>
        </button>
      </SheetTrigger>
      <SheetContent
        side={"bottom"}
        className="bg-white shadow-zinc-700/10 ring-zinc-700/10 top-12 md:top-16 lg:top-20 rounded-2xl shadow-lg ring-1 transition mx-auto lg:max-w-[1000px] xs:w-full xs:max-w-[1000px] !p-0 !rounded-t-3xl !rounded-b-none !overflow-y-scroll md:max-h-[calc(100dvh-64px)] max-h-[calc(100dvh-48px)] scrollbar-hide"
      >
        <div className="px-6 pt-4 pb-20">
          <div className="grid gap-4">
            <div className="flex">
              <SheetClose asChild>
                <button
                  type="button"
                  className="flex items-center text-cool-grey-700 py-1.5 px-3"
                  data-testid="quest-back-button"
                >
                  <Icons
                    name="arrow-left-circle-outlined"
                    width={20}
                    height={20}
                  />
                  <h3 className="px-1 button-text-medium py-px">Back</h3>
                </button>
              </SheetClose>
            </div>
            <div className="flex flex-col pr-[52px] gap-3 pb-6">
              <div className="flex flex-row items-center heading-9 text-cool-grey-450">
                Quest
                <div className="h-1 w-1 mx-1.5 rounded-full bg-cool-grey-450" />
                {quest.questResources.length}{" "}
                {quest.questResources.length > 1 ? "resources" : "resource"}
              </div>
              <div>
                <img
                  src={quest.wordmarkAsset?.url}
                  alt="wordmark"
                  className="invert"
                />
              </div>
              <div className="body-small text-cool-grey-600">
                {quest.description}
              </div>
            </div>
          </div>
          <div className="pb-4 sticky top-0 bg-white z-20 px-6 pt-2 -mx-6">
            <h3 className="text-cool-grey-450 body-2-xs">
              MEDITATIONS FROM MY QUEST
            </h3>
            <h3 className="text-cool-grey-600 heading-9 pb-2">{quest.name}</h3>
            <div className="border-black-8a border-t w-full" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-6">
            {quest.questResources.map((meditation) => (
              <MediaCardComponent
                userAccessState={userAccessState}
                key={meditation.id}
                resource={meditation as Resource}
                cardOrientation="horizontal"
                // @ts-expect-error Typing mistmach with graphql
                currentTrack={track}
                isPlaying={isPlaying}
                showDuration
                durationTextType="MM:SS"
              />
            ))}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
