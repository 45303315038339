import { useShallow } from "zustand/shallow";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useCollectionModalStore from "stores/collectionModalStore";
import useMixers from "hooks/useMixers";
import type {
  Resource,
  Rendition,
  B2bCollection,
} from "types/__generated__/graphql";

export const MediaSubType = {
  VOCAL_WITH_BACKGROUND: "vocal_with_background",
  VOCAL: "vocal",
  AMBIENT: "ambient",
  BACKGROUND: "background",
};

export const MEDITATION_TYPES = [
  MediaSubType.VOCAL_WITH_BACKGROUND,
  MediaSubType.VOCAL,
];

export function isMeditationMedia(subtype?: string): boolean {
  if (!subtype) return false;
  return MEDITATION_TYPES.includes(subtype);
}
export const SOUNDSCAPE_TYPES = [MediaSubType.BACKGROUND, MediaSubType.AMBIENT];

export function isSoundscapeMedia(subtype?: string): boolean {
  if (!subtype) return false;
  return SOUNDSCAPE_TYPES.includes(subtype);
}

export function useMediaPlayerHook() {
  const {
    track,
    isPlaying,
    setIsPlaying,
    setTrack,
    setTrackNowPlayingTitle,
    setTrackNowPlayingSubtitle,
    setSupportingMixer,
    setMixers,
  } = useMediaPlayerStore(
    useShallow((state) => ({
      isPlaying: state.isPlaying,
      track: state.track,
      setIsPlaying: state.setIsPlaying,
      setTrack: state.setTrack,
      setTrackNowPlayingTitle: state.setTrackNowPlayingTitle,
      setTrackNowPlayingSubtitle: state.setTrackNowPlayingSubtitle,
      setSupportingMixer: state.setSupportingMixer,
      setMixers: state.setMixers,
    }))
  );

  const setCollection = useCollectionModalStore((state) => state.setCollection);
  const { data: mixersData } = useMixers();

  function play() {
    setIsPlaying(true);
  }

  function pause() {
    setIsPlaying(false);
  }

  async function playTrack({
    track,
    collection,
    forceSoundscape, // To override the track subtype
  }: {
    track: Resource;
    collection?: B2bCollection | null;
    forceSoundscape?: boolean;
  }) {
    if (!track) return;

    pause();
    setCollection(collection ?? null);

    const trackIsSoundscape =
      forceSoundscape ?? isSoundscapeMedia(track.subtype);

    const nowPlayingTitle = collection
      ? "NOW PLAYING FROM COLLECTION"
      : "NOW PLAYING";
    const nowPlayingSubtitle = collection
      ? collection.title || track.title || ""
      : trackIsSoundscape
      ? "SOUNDSCAPE"
      : "MEDITATION";

    setTrackNowPlayingTitle(nowPlayingTitle);
    setTrackNowPlayingSubtitle(nowPlayingSubtitle);

    if (!trackIsSoundscape) {
      setMixers(mixersData);

      if (track.supportingResource) {
        setSupportingMixer(
          createSupportingMixerFromResource(track.supportingResource)
        );
      } else {
        setSupportingMixer(null);
      }
    } else {
      setSupportingMixer(null);
    }

    if (collection) {
      setTrackNowPlayingTitle("Now Playing from collection");
      setTrackNowPlayingSubtitle(collection.title || track.title || "");
    }

    setTrack(track);
    play();
  }

  return {
    play,
    pause,
    playTrack,
  };
}

function createSupportingMixerFromResource(resource: Resource) {
  return {
    id: resource.id,
    title: resource.title,
    image: resource.coverAsset?.url,
    sources:
      resource.mediaAsset?.renditions?.map((rendition: Rendition) => ({
        type: rendition.contentType,
        src: rendition.url,
      })) || [],
  };
}
