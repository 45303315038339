import {
  useGetLanguagesQuery,
  type GetLanguagesQueryVariables,
} from "types/__generated__/graphql";

export const languages = [
  { code: "en", label: "English" },
  { code: "es", label: "Español" },
  { code: "fr", label: "Français" },
  { code: "de", label: "Deutsch" },
  { code: "ru", label: "Русский" },
  { code: "it", label: "Italiano" },
  { code: "pt", label: "Português" },
  { code: "zh", label: "中文" },
];

function useLanguages(variables: GetLanguagesQueryVariables) {
  const { data, loading, error } = useGetLanguagesQuery({
    variables,
  });

  return {
    data: data?.languages?.edges?.map((edge) => {
      const language = edge.node;
      const matchedLanguage = languages.find(
        (lang) => lang.code === language?.iso2Code
      );
      return {
        ...language,
        label: matchedLanguage ? matchedLanguage.label : language?.iso2Code,
      };
    }) ?? [],
    loading,
    error
  };
};

function useSortedLanguages(variables: GetLanguagesQueryVariables) {
  const { data, loading, error } = useGetLanguagesQuery({
    variables,
  });

  return {
    data: languages.map((lang) => {
      const matchedLanguageNode = data?.languages?.edges?.find(edge => edge.node?.iso2Code === lang.code);
      const language = matchedLanguageNode?.node;
      return {
        ...language,
        label: lang.label,
      };
    }) ?? [],
    loading,
    error
  };
};

export { useLanguages, useSortedLanguages };
