import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button";
import { cn } from "libs/classMerger";
import Icons from "components/icons/Icons";
import useCategories from "hooks/useCategories";
import { SidepanelCard } from "./components/SidepanelCard";
import useQuest from "hooks/useQuest";
import useChannelBySlug from "hooks/useChannelBySlug";
import { useUserState } from "context/userContext";
import type { ICategoryNode } from "types/interfaces";
import { B2bQuest, CollectionHighlightStatusFilterEnum } from "types/__generated__/graphql";
import LockedModal from "components/LockedModal";
import Preloader from "components/loading/Preloader";
import { app, FrameContexts } from "@microsoft/teams-js";

type ItemType = {
  id: string;
  title: string;
  image: string;
  duration: string;
};

type Tab = "Programs" | "Meditations" | "Soundscapes";

interface SubMenuItems {
  [key: string]: string[];
}

const subMenuItems: SubMenuItems = {
  Meditations: ["Scenarios", "Authors", "Languages", "Others"],
  Soundscapes: []
};

const meditationSlugs: { [key: string]: string } = {
  Scenarios: "b2b-meditations-collections-scenarios",
  Authors: "b2b-meditations-collections-authors",
  Languages: "b2b-meditations-collections-languages",
  Others: "b2b-meditations-collections-other"
};

export default function AppInMeetingView() {
  const navigate = useNavigate();
  const [isSidePanel, setIsSidePanel] = useState(false);
  const [selectedTab, setSelectedTab] = useState<Tab>("Programs");
  const [selectedSubMenu, setSelectedSubMenu] = useState<string>("");
  const { categoriesData } = useCategories();
  const { isAuthenticated, userAccessState } = useUserState();
  const [currentCategory, setCurrentCategory] = useState<ICategoryNode>();

  useEffect(() => {
    const init = async () => {
      const context = await app.getContext();
      const frameContext = context.page.frameContext;
      setIsSidePanel(frameContext == FrameContexts.sidePanel);
    };
    init();
  }, [])

  const { data: programQuests, loading: loadingQuests } = useQuest({
    variables: {
      category: currentCategory?.id,
      language: "en"
    },
    skippable: selectedTab !== "Programs"
  });

  const { data: meditationsData, loading: loadingMeditations } = useChannelBySlug({
    slug: meditationSlugs[selectedSubMenu] || meditationSlugs.Scenarios,
    highlightStatus: CollectionHighlightStatusFilterEnum.All,
    skippable: selectedTab !== "Meditations"
  });

  const { data: soundscapesData, loading: loadingSoundscapes } = useChannelBySlug({
    slug: "b2b-soundscape-collections",
    highlightStatus: CollectionHighlightStatusFilterEnum.All,
    skippable: selectedTab !== "Soundscapes"
  });

  const [isLockedModalOpen, setIsLockedModalOpen] = useState(false);

  const handleItemClick = (type: string, id: string) => {
    if (userAccessState === "freemium") {
      setIsLockedModalOpen(true);
      return;
    }

    switch (type) {
      case "Programs":
        const selectedQuest = programQuests?.find(quest => quest.id === id);
        navigate(`/sidepanel/program/${selectedQuest?.slug}`);
        break;
      case "Meditations":
        const selectedMeditation = meditationsData?.collections?.find(item => item.id === id);
        navigate(`/sidepanel/resource/meditations/${id}`, { state: { collection: selectedMeditation } });
        break;
      case "Soundscapes":
        const selectedSoundscape = soundscapesData?.collections?.find(item => item.id === id);
        navigate(`/sidepanel/resource/soundscapes/${id}`, { state: { collection: selectedSoundscape } });
        break;
    }
  };

  const handleSearchClick = () => {
    navigate("/sidepanel/search");
  };

  useEffect(() => {
    if (selectedTab === "Programs" && categoriesData.length > 0) {
      setSelectedSubMenu(categoriesData[0].name);
      setCurrentCategory(categoriesData[0]);
    } else if (selectedTab !== "Programs") {
      setSelectedSubMenu(subMenuItems[selectedTab][0]);
    }
  }, [selectedTab, categoriesData]);

  useEffect(() => {
    if (selectedTab === "Programs") {
      const category = categoriesData.find(cat => cat.name === selectedSubMenu);
      setCurrentCategory(category);
    }
  }, [selectedSubMenu, categoriesData, selectedTab]);

  const getCurrentSubMenuItems = () => {
    if (selectedTab === "Programs") {
      return categoriesData.map(category => category.name);
    }
    if (selectedTab === "Soundscapes") {
      return [];
    }
    return subMenuItems[selectedTab];
  };

  const iconName = (tab: string) =>
    tab === "Programs" ? "mountain-outlined" : (tab === "Meditations" ? "spiritual-outlined" : "music-outlined")

  return (
    <div className="flex flex-col h-full">
      <div className={cn("flex items-center pb-4",
        !isSidePanel && "px-4 pt-4"
      )}>
        <Button
          variant="ghost"
          size="icon"
          className="shrink-0 bg-primary/10 hover:bg-primary/20 mr-2"
          onClick={handleSearchClick}
        >
          <Icons name="search-outlined" height={16} width={16} />
        </Button>

        <div className={cn("flex overflow-x-auto hide-scrollbar",
          !isSidePanel && "px-4"
        )}>
          {["Programs", "Meditations", "Soundscapes"].map((tab) => (
            <Button
              key={tab}
              variant={"icon"}
              className={cn(
                "py-2 relative whitespace-nowrap transition-colors",
                selectedTab === tab ? "text-cool-grey-700" : "text-cool-grey-500 hover:text-cool-grey-600"
              )}
              onClick={() => setSelectedTab(tab as Tab)}
            >
              <Icons name={iconName(tab)} height={16} width={16} className="mr-2" />
              {tab}
              {selectedTab === tab && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-red-600" />
              )}
            </Button>
          ))}
        </div>
      </div>

      <div className={cn("flex overflow-x-auto hide-scrollbar",
        !isSidePanel && "p-4 gap-2"
      )}>
        {getCurrentSubMenuItems().map((item) => (
          <Button
            key={item}
            variant={"outline"}
            className={cn(
              "px-3 py-1.5 relative whitespace-nowrap text-sm font-medium transition-colors border rounded-lg mr-1",
              selectedSubMenu === item
                ? "text-white bg-cool-grey-700 border-cool-grey-700"
                : "text-cool-grey-700 border-cool-grey-700 hover:bg-cool-grey-50"
            )}
            onClick={() => setSelectedSubMenu(item)}
          >
            {item}
            {selectedSubMenu === item && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-transparent" />
            )}
          </Button>
        ))}
      </div>

      <div className={cn("flex-1 overflow-y-auto py-4",
        !isSidePanel && "px-4"
      )}>
        {selectedTab === "Programs" ? (
          <div className="flex flex-col gap-4">
            {loadingQuests ? (
              <Preloader showWhiteLogo={false} autoRedirect={false} />
            ) : programQuests?.map((quest: B2bQuest) => (
              <div
                onClick={() => handleItemClick(selectedTab, quest.id!)}
              >
                <SidepanelCard
                  key={quest.id}
                  item={{
                    name: quest.name || "",
                    coverAsset: quest.coverAsset,
                    authors: quest.authors
                  }}
                  category={currentCategory}
                />
              </div>
            ))}
          </div>
        ) : selectedTab === "Meditations" && meditationsData?.collections ? (
          <div className="flex flex-col gap-4">
            {loadingMeditations ? (
              <Preloader showWhiteLogo={false} autoRedirect={false} />
            ) : meditationsData.collections.map((item) => (
              <div
                key={item.id}
                onClick={() => handleItemClick(selectedTab, item.id!)}
              >
                <SidepanelCard
                  key={item.id}
                  item={{
                    name: item.title || "",
                    coverAsset: item.coverAsset,
                    authors: item.authors
                  }}
                />
              </div>
            ))}
          </div>
        ) : selectedTab === "Soundscapes" && soundscapesData?.collections ? (
          <div className="flex flex-col gap-4">
            {loadingSoundscapes ? (
              <Preloader showWhiteLogo={false} autoRedirect={false} />
            ) : soundscapesData.collections.map((item) => (
              <div
                key={item.id}
                onClick={() => handleItemClick(selectedTab, item.id!)}
              >
                <SidepanelCard
                  key={item.id}
                  item={{
                    name: item.title || "",
                    coverAsset: item.coverAsset,
                    authors: item.authors
                  }}
                />
              </div>
            ))}
          </div>
        ) : <Preloader showWhiteLogo={false} autoRedirect={false} />}
      </div>
      <LockedModal
        onCancel={() => setIsLockedModalOpen(false)}
        open={isLockedModalOpen}
        userAccessState={userAccessState}
      />
    </div>
  );
}
