import { memo, useCallback } from "react";
import { useShallow } from "zustand/shallow";
import MVSoundscapeResource from "components/MVPlayer/MVSoundscapesResource";
import MVMeditationResource from "components/MVPlayer/MVMeditationResource";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useCollectionModalStore from "stores/collectionModalStore";
import { MediaSubType } from "hooks/useMediaPlayer";

export const MediaPlayer = memo(() => {
  const {
    track,
    trackNowPlayingTitle,
    trackNowPlayingSubtitle,
    mixersData,
    supportingMixer,
    isPlaying,
    setIsPlaying,
  } = useMediaPlayerStore(
    useShallow((state) => ({
      track: state.track,
      trackNowPlayingTitle: state.trackNowPlayingTitle,
      trackNowPlayingSubtitle: state.trackNowPlayingSubtitle,
      mixersData: state.mixers,
      supportingMixer: state.supportingMixer,
      isPlaying: state.isPlaying,
      setIsPlaying: state.setIsPlaying,
    }))
  );

  const { collection, openWithCollection, reset } = useCollectionModalStore(
    useShallow((state) => ({
      collection: state.collection,
      openWithCollection: state.openWithCollection,
      reset: state.reset,
    }))
  );

  const handleClose = useCallback(() => {
    reset();
    setIsPlaying(false);
  }, [reset, setIsPlaying]);

  const handleCollectionOpen = useCallback(() => {
    if (collection) {
      openWithCollection(collection);
    }
  }, [collection, openWithCollection]);

  if (!isPlaying || !track) return null; // TEMP solution. Need to pause or close via Mv vue player

  return (
    <div className="fixed z-[999] pointer-events-auto">
      {track?.subtype === MediaSubType.BACKGROUND ? (
        <MVSoundscapeResource
          key={track?.id}
          media={track}
          showCollection={!!collection}
          nowPlayingTitle={{
            title: trackNowPlayingTitle ?? undefined,
            subtitle: trackNowPlayingSubtitle ?? undefined,
          }}
          muted={false}
          autoPlay={true}
          onClose={handleClose}
          handleCollectionOpen={handleCollectionOpen}
        />
      ) : (
        <MVMeditationResource
          key={track.id}
          media={track}
          nowPlayingTitle={{
            title: trackNowPlayingTitle ?? undefined,
            subtitle: trackNowPlayingSubtitle ?? undefined,
          }}
          showCollection={!!collection}
          backgroundSounds={mixersData}
          defaultBackgroundSound={supportingMixer}
          muted={false}
          autoPlay={true}
          onClose={handleClose}
          handleCollectionOpen={handleCollectionOpen}
        />
      )}
    </div>
  );
});
