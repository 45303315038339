import { h, defineComponent, type PropType } from "vue";
import { MVSoundscapeResource } from "@mindvalley/mv-universal-player";
import type { Resource, Rendition, Tag } from "types/__generated__/graphql";

// TEMPORARY FIX TO MAP THE MIME TYPES
const ALLOWED_MIME_TYPES = [
  "application/x-mpegURL",
  "video/mp4",
  "audio/mpeg",
  "audio/mp3",
  "audio/ogg",
];

export default defineComponent({
  name: "MVSoundscapesWrapper",
  components: {
    MVSoundscapeResource,
  },
  props: {
    media: {
      type: Object as PropType<Resource>,
      required: true,
    },
    nowPlayingTitle: {
      type: Object as PropType<{ title: string; subtitle: string }>,
      required: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
    showCollection: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function as PropType<(...args: any[]) => any>,
      required: true,
      default: () => {},
    },
    handleCollectionOpen: {
      type: Function as PropType<(...args: any[]) => any>,
      required: false,
      default: () => {},
    },
  },
  methods: {
    formatSources(localSources: Array<Rendition | null> = []) {
      return (localSources || [])
        .filter(
          (source): source is Rendition =>
            !!source &&
            !!source.contentType &&
            ALLOWED_MIME_TYPES.includes(source.contentType)
        )
        .map((source) => ({
          type: source.contentType,
          src: source.url || "",
        }));
    },
    formatTags(tags: Tag[] = []) {
      if (!tags || !Array.isArray(tags)) return [];

      return tags
        .map((tag) => tag.name)
        .filter((name): name is string => !!name);
    },
  },
  render() {
    const media = this.media;
    const mediaId = media.id || "";
    const title = media.title || "";
    const description = media.description || "";
    const artistName = media.author?.name || "";
    const posterUrl = media.coverAsset?.url || "";
    const backgroundPosterUrl = media.landscapeCoverAsset?.url || "";
    const renditions = media.mediaAsset?.renditions || [];

    const nowPlayingTitle = this.nowPlayingTitle;

    return h(
      "div",
      {
        class: "mv-universal-player-container w-full",
      },
      [
        h(MVSoundscapeResource, {
          id: mediaId,
          audioSources: this.formatSources(renditions),
          title,
          artistName,
          posterUrl,
          backgroundPosterUrl,
          showCollection: this.showCollection,
          showPreviousNext: false,
          autoPlay: this.autoPlay,
          nowPlayingTitle: nowPlayingTitle?.title || "NOW PLAYING",
          nowPlayingSubtitle: nowPlayingTitle?.subtitle || "SOUNDSCAPE",
          description,
          ratings: Number(media.averageRating?.toFixed(1)),
          tags: this.formatTags(this.media.tags),
          onClose: this.onClose,
          onCollectionOpen: this.handleCollectionOpen,
          ...(media.trailerLoopingAsset
            ? {
                videoSources: this.formatSources(
                  media.trailerLoopingAsset.renditions
                ),
              }
            : {}),
        }),
      ]
    );
  },
});
