import {
  useGetChannelBySlugQuery,
  type GetChannelBySlugQueryVariables,
  CollectionHighlightStatusFilterEnum,
  type GetChannelBySlugQuery,
  type B2bChannel,
} from "types/__generated__/graphql";

export type ChannelBySlug = GetChannelBySlugQuery["b2bChannelBySlug"];

function useChannelBySlug({
  slug,
  highlightStatus = CollectionHighlightStatusFilterEnum.All,
  limit = 50,
  skippable = false,
}: {
  slug: string;
  highlightStatus?: CollectionHighlightStatusFilterEnum;
  limit?: number;
  skippable?: boolean;
}) {
  const variables: GetChannelBySlugQueryVariables = {
    slug,
    highlightStatus,
    limit,
  };

  const { data, loading, error, refetch } = useGetChannelBySlugQuery({
    variables,
    fetchPolicy: "no-cache", // TODO:: TEMP FIX Theres cache normalize issue
    skip: skippable,
  });

  const channelData = data?.b2bChannelBySlug ?? null;

  return {
    data: channelData as B2bChannel | null,
    loading,
    error,
    refetch,
  };
}

export default useChannelBySlug;
