import { Skeleton } from "../Skeleton";

export const ProgramsWallSkeleton = () => {
  return (
    <div className="flex  flex-col gap-y-5">
      <Skeleton className="m-auto w-[1280] h-[500]" />
      <div className="flex flex-col gap-y-8 w-full max-w-[1280] m-auto">
        <div className="flex justify-between ">
          <Skeleton className="w-[650] h-[40] rounded-full" />
          <Skeleton className="w-[240] h-[40] rounded-full" />
        </div>
        <Skeleton className="h-32 w-full" />
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-y-2">
            <Skeleton className="w-40 h-7 rounded-full" />
            <Skeleton className="w-32 h-7 rounded-full" />
          </div>
          <div className="flex flex-col gap-y-1 w-3/4">
            <Skeleton className=" h-3 " />
            <Skeleton className=" h-3 " />
            <Skeleton className=" h-3 " />
            <Skeleton className=" h-3 " />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProgramsQuestSkeleton = () => {
  return (
    <div className="grid gap-6">
      <div className="grid gap-5">
        <Skeleton className="w-full h-32 rounded-2xl " />
        <div className="grid grid-cols-1 md:grid-cols-2 w -full gap-4">
          <div className="grid gap-4 w-full">
            <Skeleton className="w-8/12 h-8 rounded-full" />
            <Skeleton className="w-5/12 h-7 rounded-full" />
          </div>
          <div className="grid gap-2 w-full">
            <Skeleton className="w-full h-4 rounded-full" />
            <Skeleton className="w-full h-4 rounded-full" />
            <Skeleton className="w-full h-4 rounded-full" />
            <Skeleton className="w-6/12 h-4 rounded-full" />
          </div>
        </div>
      </div>
      <Skeleton className="w-[200px] h-5 rounded-full" />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6">
        {[...Array(15)].map((_, idx) => (
          <div
            key={`programs-wall${
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              idx
            }`}
            className="w-full grid gap-3"
          >
            <Skeleton className="aspect-video rounded-xl" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-8/12" />
              <Skeleton className="h-4 w-6/12" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
