import React, { useEffect, useRef } from "react";
import { createApp } from "vue";
import { ENV_ENUMS } from "config/teamsConfig";
import MVUniversalMedialayerWrapper from "./MVUniversalMediaPlayerWrapper";
import { svgSpriteDirectivePlugin } from "vue-svg-sprite";
import MVUniversalPlayer from "@mindvalley/mv-universal-player";
import svgSprite from "@mindvalley/design-system/dist/icons/sprites/ui-icons.svg";
import "./videoPlayer.css";

function MVUniversalMediaPlayerReact({ media, muted, autoplay }) {
  const containerRef = useRef(null);
  const vueAppRef = useRef(null);
  const iconPath =
    process.env.NODE_ENV === ENV_ENUMS.DEVELOPMENT
      ? ""
      : `${process.env.REACT_APP_TAB_ENDPOINT}/`;

  useEffect(() => {
    if (!containerRef.current) return;

    // Create the Vue app

    const app = createApp(MVUniversalMedialayerWrapper, {
      media,
      muted,
      autoplay,
    });
    app.use(MVUniversalPlayer);
    app.use(svgSpriteDirectivePlugin, { url: svgSprite });
    app.mount(containerRef.current);

    vueAppRef.current = app;

    return () => {
      // Clean up the Vue instance on unmount
      if (vueAppRef.current) {
        vueAppRef.current.unmount();
      }
    };
  }, [media, muted, autoplay]);

  return (
    <div className="w-full max-w-7xl px-6 md:px-16 xl:px-[120px] py-8">
      <div ref={containerRef} className="mv-universal-player overflow-hidden" />
    </div>
  );
}

export default React.memo(MVUniversalMediaPlayerReact);
