import { getNextIncompletePreparationPage } from "utils/navigation";
import type { B2bQuest } from "types/__generated__/graphql";

export function getQuestContinueButtonText(quest: B2bQuest): string {
  if (!quest.userProgress) {
    return "Start quest";
  }

  // console.log("quest", quest);

  const totalIntros = quest.userProgress?.totalIntros ?? 0;
  const totalCompleted = quest.userProgress?.totalIntrosCompleted ?? 0;
  const hasRemainingIntros = totalIntros > 0 && totalCompleted < totalIntros;

  const remainingIntroPosition = getNextIncompletePreparationPage(
    quest.pages
  )?.position;

  // const nextPosition = quest.pages?.find(
  //   (page) => page.type === "day" && page.completed === false
  // );

  const currentPosition = quest.userProgress?.resumePage;

  // const nextPosition =
  //   quest.userProgress?.nextPage?.position ??
  //   quest.userProgress?.resumePage?.position; // FALLBACK for  very edge case

  // const questTypeText = quest.type === "weekly" ? " lesson " : " day ";

  let questTypeText = "";
  switch (quest.userProgress.resumePage?.type) {
    case "intro":
      questTypeText = "Intro";
      break;
    case "weekly":
    case "day":
      questTypeText = "lesson";
      break;
    default:
      questTypeText = "lesson";
      break;
  }

  // return hasRemainingIntros
  //   ? `Continue intro ${remainingIntroPosition}`
  //   : `Continue ${questTypeText} ${nextPosition?.position}`;
  return `Continue ${questTypeText} ${currentPosition?.position}`;
}
