import { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/helper.css";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useLogout } from "hooks/useLogout";
import { NavigationEnum } from "router/navigationType";
import NavbarAvatar from "./Avatar";
import LockedModal from "./LockedModal";
import Icons from "./icons/Icons";

enum DropdownSections {
  profile = "profile",
  logout = "logout",
}

enum DropdownLabels {
  profile = "My profile",
  support = "Support",
  logout = "Log out",
}

const menuStyle =
  "flex gap-3 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-2 rounded-lg";

const NavDropdownMenuTabletMobile = ({
  userData,
  setIsMenuOpen,
  menuRender,
}: {
  menuRender: Array<any>;
  userData: any;
  setIsMenuOpen: () => void;
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [activeAnimation, setActiveAnimation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const links = menuRender;
  const { userAccessState } = useUserState();
  const logout = useLogout();

  function Logout() {
    setIsModalOpen(false);
    logout();
  }

  const profileLink = {
    icon: <Icons name="user-outlined" height={20} width={20} fill="#292D38" />,
    label: DropdownLabels.profile,
    section: DropdownSections.profile,
    link: "/account/profile",
    onClick: () => setIsMenuOpen(),
  };

  const supportLink = {
    icon: (
      <Icons
        name="life-buoy-help-outlined"
        height={20}
        width={20}
        fill="#292D38"
      />
    ),
    label: DropdownLabels.support,
    section: DropdownSections.profile,
    link: "https://support.getworkway.com/",
    onClick: () => setIsMenuOpen(),
  };

  const logoutLink = {
    icon: <Icons name="log-out-filled" height={20} width={20} fill="#292D38" />,
    label: DropdownLabels.logout,
    section: DropdownSections.logout,
    link: "/",
    onClick: () => {
      Logout();
    },
  };

  const pageNumberHandler = (value: number) => {
    setActiveAnimation(true);
    setPageNumber(value);
  };

  const userDetails = (isFirstPage = true) => (
    <div
      className="px-4 flex justify-between items-center gap-4 md:px-8"
      onClick={() => pageNumberHandler(isFirstPage ? 2 : 1)}
      onKeyDown={() => pageNumberHandler(isFirstPage ? 2 : 1)}
      onKeyPress={() => pageNumberHandler(isFirstPage ? 2 : 1)}
      onKeyUp={() => pageNumberHandler(isFirstPage ? 2 : 1)}
      role="button"
    >
      <div className="flex gap-3 py-3 items-center ">
        {!isFirstPage && (
          <div className="rotate-180">
            <Icons
              name="chevron-right-filled"
              width={24}
              height={24}
              fill="#979CA5"
            />
          </div>
        )}
        <div className="h-10 w-10">
          <NavbarAvatar
            imageUrl={userData?.profilePhoto}
            fallbackText={`${userData?.firstName.charAt(
              0
            )}${userData?.lastName.charAt(0)}`}
            className="lg:hidden"
          />
        </div>
        <div className="text-left">
          <div className="text-sm font-semibold sm:title-9 text-cool-grey-600">
            {userData.name}
          </div>
          <div className="text-xs body-2-xs text-cool-grey-500">
            {userData.email}
          </div>
        </div>
      </div>
      {isFirstPage && (
        <div>
          <Icons
            name="chevron-right-filled"
            width={24}
            height={24}
            fill="#979CA5"
          />
        </div>
      )}
    </div>
  );

  const menuDetails = () => (
    <div className="flex flex-col border-b border-cool-grey-300 px-4 md:px-8 ">
      {links.map((el) => (
        <Link
          to={el.link}
          key={el.id}
          className="py-4 text-cool-grey-600 title-6 px-2"
          onClick={(e) => handleClickTabNavigation({ e, link: el.link })}
        >
          {el.label}
        </Link>
      ))}
    </div>
  );

  const adminPanel = () => {
    return (
      <div className="px-4 md:px-8 pb-4">
        <div className="py-2 w-full text-center bg-cool-grey-200 rounded-lg text-cool-grey-600 hover:bg-red-8a hover:text-red-600 cursor-pointer">
          <Link className="body-sm font-semibold" to={userData.adminPanelURL}>
            Go to admin panel
          </Link>
        </div>
      </div>
    );
  };

  const handleClickTabNavigation = ({
    e,
    link,
  }: {
    e: React.MouseEvent<HTMLAnchorElement>;
    link: string;
  }) => {
    if (
      userAccessState !== UserAccessStateEnum.Premium &&
      link.toLocaleLowerCase() !== NavigationEnum.Home.toLocaleLowerCase() &&
      link.toLocaleLowerCase() !== NavigationEnum.Programs.toLocaleLowerCase()
    ) {
      setIsModalOpen(true);
      e.preventDefault();
    } else {
      setIsMenuOpen();
    }
  };

  const NavDropdownTablet = () => {
    return (
      <div className="fixed md:absolute h-[95vh] md:h-auto w-full top-12 z-[50] bg-white border-cool-grey-300 animate-fade-in  ">
        <div className="sm:flex sm:flex-col  sm:h-full sm:gap-y-3">
          {menuDetails()}
        </div>

        <div className="m-auto flex max-w-[480px] rounded-2xl">
          <LockedModal
            onCancel={() => setIsModalOpen(false)}
            open={isModalOpen}
            userAccessState={userAccessState}
          />
        </div>
      </div>
    );
  };

  //Freemium state dropdown menu for tablet
  const NavDropdownTabletFreemium = () => {
    return (
      <div className="fixed md:absolute h-[95vh] md:h-auto w-full top-12 z-[50] bg-white border-cool-grey-300 animate-fade-in  ">
        <div className="sm:flex sm:flex-col  sm:h-full sm:gap-y-3">
          {menuDetails()}
          <div className=" md:px-8 py-4 flex flex-col gap-4 border-b px-4 border-cool-grey-300">
            {[supportLink, logoutLink].map((el) =>
              el.label === DropdownLabels.support ? (
                <div key={el.label} className={menuStyle}>
                  <div>{el.icon}</div>
                  <div className="title-6 ">
                    <a target="_blank" rel="noreferrer" href={el.link}>
                      {el.label}
                    </a>
                  </div>
                </div>
              ) : (
                <Link
                  to={el.link}
                  key={el.label}
                  className={menuStyle}
                  onClick={el.onClick}
                >
                  <div className="">{el.icon}</div>
                  <div className="title-6">{el.label}</div>
                </Link>
              )
            )}
          </div>
        </div>

        <div className="m-auto flex max-w-[480px] rounded-2xl">
          <LockedModal
            onCancel={() => setIsModalOpen(false)}
            open={isModalOpen}
            userAccessState={userAccessState}
          />
        </div>
      </div>
    );
  };

  const NavdropDownTabletPremium = () => {
    return (
      <div className="fixed md:absolute h-[95vh] md:h-auto w-full top-12 z-[50] bg-white border-cool-grey-300 animate-fade-in  ">
        {pageNumber === 1 ? (
          <div
            className={`${activeAnimation
              ? "fade-in-15 animation-right-left "
              : "sm:flex sm:flex-col  sm:h-full sm:gap-y-3"
              }`}
          >
            {menuDetails()}
            {userDetails()}
            {userData?.isAdmin && adminPanel()}
          </div>
        ) : (
          <div className="fade-in-animation-left-right">
            <div className="border-b border-cool-grey-300">
              {userDetails(false)}
              {userData?.isAdmin && adminPanel()}
            </div>
            <div className=" md:px-8 py-4 flex flex-col gap-6 border-b px-4 border-cool-grey-300">
              {[profileLink, supportLink].map((el) =>
                el.label === DropdownLabels.support ? (
                  <div key={el.label} className={menuStyle}>
                    <div>{el.icon}</div>
                    <div className="title-6 ">
                      <a target="_blank" rel="noreferrer" href={el.link}>
                        {el.label}
                      </a>
                    </div>
                  </div>
                ) : (
                  <Link
                    onClick={el.onClick}
                    to={el.link}
                    key={el.label}
                    className={menuStyle}
                  >
                    <div>{el.icon}</div>
                    <div className="title-6 ">{el.label}</div>
                  </Link>
                )
              )}
            </div>
            <div className="px-4 md:px-8 py-4 flex flex-col gap-4 ">
              {[logoutLink].map((el) => (
                <Link
                  to={el.link}
                  onClick={el.onClick}
                  key={el.label}
                  className={menuStyle}
                >
                  <div>{el.icon}</div>
                  <div className="title-6">{el.label}</div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {userAccessState === UserAccessStateEnum.Freemium && (
        <NavDropdownTabletFreemium />
      )}
      {userAccessState === UserAccessStateEnum.Premium && (
        <NavdropDownTabletPremium />
      )}
      {userAccessState === UserAccessStateEnum.Unauthenticated && (
        <NavDropdownTablet />
      )}
    </>
  );
};

export default NavDropdownMenuTabletMobile;
