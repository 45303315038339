import { useNavigate, useParams } from "react-router-dom";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { useState, useEffect } from "react";
import { useGetQuestBySlugAndLanguageQuery, type B2bQuest } from "types/__generated__/graphql";
import Preloader from "components/loading/Preloader";
import { ProgramListItem } from "./components/ProgramListItem";
import { useShareToStage } from "./hooks/useShareToStage";
import { app, FrameContexts } from "@microsoft/teams-js";
import { cn } from "libs/classMerger";

export default function ProgramDetailView() {
    const { programId } = useParams();
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [collapsedGroups, setCollapsedGroups] = useState<{ [key: string]: boolean }>({});
    const [playingResourceId, setPlayingResourceId] = useState<string | null>(null);
    const [isInTeamsMeeting, setIsInTeamsMeeting] = useState(false);
    const [isSidePanel, setIsSidePanel] = useState(false);

    const { shareToStage } = useShareToStage({ setPlayingResourceId });

    useEffect(() => {
        const init = async () => {
            const context = await app.getContext();
            const frameContext = context.page.frameContext;
            setIsSidePanel(frameContext == FrameContexts.sidePanel);
        };
        init();
    }, [])

    useEffect(() => {
        const initializeTeams = async () => {
            try {
                await app.initialize();
                const context = await app.getContext();
                setIsInTeamsMeeting(context.meeting?.id !== undefined);
            } catch (error) {
                console.error('Error initializing Teams:', error);
            }
        };
        initializeTeams();
    }, []);

    const { data, loading, error } = useGetQuestBySlugAndLanguageQuery({
        variables: {
            slugAndLanguage: {
                slug: programId || "",
                language: "en" // Default language
            }
        }
    });

    const quest = data?.b2bQuestBySlugAndLanguage as B2bQuest;

    if (loading) return <Preloader showWhiteLogo={false} autoRedirect={false} />;
    if (error) return <div>Error loading program details</div>;
    if (!quest) return <div>Program not found</div>;

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleGroupCollapse = (groupId: string) => {
        setCollapsedGroups(prev => ({
            ...prev,
            [groupId]: !prev[groupId]
        }));
    };

    const filteredCategories = quest.categories?.filter((c) => c.type === "b2b");
    const displayTags = isExpanded ? filteredCategories?.map(cat => cat.name) || [] : (filteredCategories?.slice(0, 3).map(cat => cat.name) || []);
    const hasMoreTags = (filteredCategories?.length || 0) > 3;

    return (
        <div className={cn("h-full overflow-y-auto", !isSidePanel && "p-4")}>
            <div className="flex flex-col gap-4">
                <Button
                    variant="ghost"
                    size="icon"
                    className="shrink-0 text-cool-grey-500 bg-primary/10 hover:bg-primary/20 w-fit"
                    onClick={() => navigate(-1)}
                >
                    <Icons name="arrow-left-circle-outlined" height={24} width={24} />
                    <p className="px-1 button-text-medium">Back</p>
                </Button>
                <div className="flex flex-col gap-1">
                    <h1 className="text-left text-red-600">Quest</h1>
                    {quest.wordmarkAsset ? (
                        <img
                            src={quest.wordmarkAsset.url}
                            alt={quest.name}
                            className="max-h-14 max-w-[280px] lg:max-h-16 lg:max-w-[300px] object-contain invert"
                        />
                    ) : (
                        <p className="text-xl text-cool-grey-600">{quest.name}</p>
                    )}
                    <p className="mt-1 text-xs font-semibold text-cool-grey-500">{quest.authors?.[0]?.name}</p>
                    <p className={`text-sm text-cool-grey-500 mt-2 ${!isExpanded ? 'line-clamp-4' : ''}`}>{quest.description}</p>
                    <div className="flex flex-wrap gap-2 mt-3">
                        {displayTags.map((tag, index) => (
                            <Button
                                key={index}
                                variant="outline"
                                size="sm"
                                className="text-sm rounded-full"
                            >
                                {tag?.replace("-B2B", "")}
                            </Button>
                        ))}
                        {!isExpanded && hasMoreTags && (
                            <span className="rounded-full bg-cool-grey-100 px-3 py-1 text-sm text-cool-grey-600">...</span>
                        )}
                    </div>
                    <button
                        onClick={handleExpandClick}
                        className="text-sm text-cool-grey-400 underline w-fit pt-1"
                    >
                        {isExpanded ? 'Read less' : 'Read more'}
                    </button>
                    {quest.trailerAsset && (
                        <Button
                            variant="outline"
                            size="default"
                            className="flex items-center gap-2 w-fit bg-white mt-4"
                            onClick={() => isInTeamsMeeting && quest.trailerAsset?.url && shareToStage({
                                id: 'trailer',
                                mediaUrl: quest.trailerAsset.url,
                                coverAssetUrl: quest.coverAsset?.url || ""
                            })}
                        >
                            <Icons name="film-outlined" height={20} width={20} />
                            <span>Watch trailer</span>
                        </Button>
                    )}
                    <div className="mt-6">
                        <h2 className="text-sm text-cool-grey-400">LESSONS FROM QUEST</h2>
                        <div className="heading-7 mt-1">{quest.name}</div>
                    </div>
                    <div className="w-full h-[1px] bg-cool-grey-200 my-4" />
                    <div className="flex flex-col gap-4">
                        {quest.groups?.map((group) => {
                            const groupPages = quest.pages?.filter(page => page.groupName === group.name) || [];
                            const isFirstGroup = quest.groups?.[0]?.name === group.name;
                            let lessonCounter = 0;
                            if (!isFirstGroup) {
                                const previousGroups = quest.groups?.slice(0, quest.groups.findIndex(g => g.name === group.name)) || [];
                                lessonCounter = previousGroups.reduce((count, prevGroup) => {
                                    return count + (quest.pages?.filter(page => page.groupName === prevGroup.name)?.length || 0);
                                }, 0);
                            }

                            return (
                                <div key={group.id} className="flex flex-col gap-2">
                                    <Button
                                        onClick={() => handleGroupCollapse(group.id || '')}
                                        variant={"dropdown"}
                                        className="flex items-center justify-between p-4 hover:bg-gray-50 border-none rounded-none"
                                    >
                                        <div className="heading-7 text-cool-grey-600">{group.name}</div>
                                        <Icons
                                            name={collapsedGroups[group.id || ''] ? "chevron-down-outlined" : "chevron-up-outlined"}
                                            height={24}
                                            width={24}
                                        />
                                    </Button>

                                    {!collapsedGroups[group.id || ''] && (
                                        <div className="flex flex-col">
                                            {groupPages.map((page, index) => (
                                                <div
                                                    key={page.id}
                                                    onClick={() => isInTeamsMeeting && shareToStage({
                                                        id: page.id,
                                                        mediaUrl: page.sections?.find(section => section.primaryAsset?.url)?.primaryAsset?.url ?? "",
                                                        coverAssetUrl: page.coverAsset?.url || ""
                                                    })}
                                                    className="cursor-pointer"
                                                >
                                                    <ProgramListItem
                                                        coverAssetUrl={page.coverAsset?.url}
                                                        name={page.name ?? ""}
                                                        isFirstGroup={isFirstGroup}
                                                        index={index}
                                                        lessonCounter={lessonCounter}
                                                        isPlaying={playingResourceId === page.id}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}