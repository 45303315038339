import { useMutation } from "@apollo/client";
import { Button } from "components/Button";
import { Dropzone } from "components/Dropzone";
import ImageCrop from "components/ImageCrop";
import Icons from "components/icons/Icons";
import ProfileCardSkeleton from "components/loading/ProfileCardSkeleton";
import { useUserState } from "context/userContext";
import { useToast } from "hooks/use-toast";
// import useGetCurrentOrg from "hooks/useGetCurrentOrg";
import { cn } from "libs/classMerger";
import { useState } from "react";
import gqlQuery from "services/graphql/queries";
import type { ICurrentProfile } from "types/interfaces";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/Dialog";

const ConfirmRemovePhotoDialog = ({
  open = false,
  onConfirm,
  onCancel,
}: {
  open?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <Dialog open={open}>
      <DialogContent className="z-100 p-10 flex flex-col gap-4">
        <DialogHeader>
          <DialogTitle>
            <div className="title-6">Remove profile photo</div>
          </DialogTitle>
        </DialogHeader>
        <p className="body text-cool-grey-600">
          Are you sure you want to remove your profile photo?
        </p>
        <DialogFooter className="flex">
          <Button
            size={"link"}
            className="py-3 px-[14px]"
            onClick={onConfirm}
            variant={"neutral"}
            type="submit"
          >
            Yes
          </Button>
          <Button
            size={"sm"}
            className="py-3 px-[14px]"
            onClick={onCancel}
            variant={"ghost"}
            type="submit"
          >
            No
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const UploadPhoto = ({
  open = false,
  setImage,
  onCancel,
}: {
  open?: boolean;
  setImage: (file: string | ArrayBuffer) => void;
  onCancel: () => void;
}) => {
  return (
    <Dialog open={open}>
      <DialogContent className="z-[50] py-6 ">
        <DialogHeader>
          <DialogTitle>
            <div className="heading-5">Upload a new profile photo</div>
          </DialogTitle>
          <DialogDescription>
            <div className="body text-cool-grey-400">
              For best results, make sure your photo is square.
            </div>
          </DialogDescription>
        </DialogHeader>
        <Dropzone setFile={setImage} />
        <DialogFooter>
          <Button
            onClick={onCancel}
            className="flex items-center align-middle w-full"
            variant={"ghost"}
            type="submit"
          >
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const CropPhoto = ({
  open = false,
  imageSrc,
  setCropImageUrl,
  onCancel,
  onSave,
}: {
  open?: boolean;
  imageSrc: string | ArrayBuffer;
  setCropImageUrl: (croppedImg: string) => void;
  onSave: () => void;
  onCancel: () => void;
}) => {
  return (
    <Dialog open={open}>
      <DialogContent className="py-6 z-[50] mx-auto">
        <DialogHeader>
          <DialogTitle className="mb-4">
            <div className="title-7 text-center">Crop photo</div>
          </DialogTitle>
        </DialogHeader>
        <ImageCrop imageSrc={imageSrc} setCropImageUrl={setCropImageUrl} />
        <DialogFooter>
          <DialogClose asChild>
            <div className="flex gap-2 px-4 justify-between w-full">
              <Button
                onClick={onCancel}
                className="flex items-center align-middle w-full"
                variant={"ghost"}
                type="submit"
              >
                Cancel
              </Button>
              <Button
                onClick={onSave}
                className="flex items-center align-middle w-full"
                variant={"primary"}
                type="submit"
              >
                Save
              </Button>
            </div>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const ProfileCard = ({
  profileData,
  editProfile = false,
  loading,
}: {
  profileData?: ICurrentProfile;
  editProfile?: boolean;
  loading?: boolean;
}) => {
  const [profileImg, setProfileImage] = useState<string | ArrayBuffer | null>(
    null
  );
  const FALLBACK_PROFILE_IMG =
    "https://cdn-assets.workway.app/images/avatar-b1fa94a2cba30f742fc8b95793f60f98.svg?vsn=d";
  const [croppedImgUrl, setCropImageUrl] = useState<string>("");
  const [openUploadPhotoModal, showUploadPhotoModal] = useState(false);
  const [openCropImageModal, showCropImgModal] = useState(false);
  const [openRemoveProfilePicDialog, showRemoveProfilePicDialog] =
    useState(false);

  const { organizationData } = useUserState();
  const { toast } = useToast();

  const [
    updateProfile,
    { data, loading: updatingProfile, error: updateProfileError },
  ] = useMutation(gqlQuery.updateCurrentProfile);

  if (loading) {
    return <ProfileCardSkeleton />;
  }

  const uploadCroppedImg = () => {
    if (!croppedImgUrl) return;
    try {
      updateProfile({
        variables: {
          input: {
            avatarUrl: croppedImgUrl,
          },
        },
      });
      toast({
        description: "Profile photo updated successfully",
      });
    } catch (error) {
      toast({
        description: "Failed to update profile photo",
      });
    }
  };

  const removeProfileImg = () => {
    setCropImageUrl("");
    showRemoveProfilePicDialog(false);
    try {
      updateProfile({
        variables: {
          input: {
            avatarUrl: "",
          },
        },
      });
      toast({
        description: "Profile photo removed successfully",
      });
    } catch (error) {
      toast({
        description: "Failed to update profile photo",
      });
    }
  };

  return (
    <div className="w-full bg-brown-50 rounded-[24px]">
      <ConfirmRemovePhotoDialog
        open={openRemoveProfilePicDialog}
        onCancel={() => showRemoveProfilePicDialog(false)}
        onConfirm={removeProfileImg}
      />
      <UploadPhoto
        open={openUploadPhotoModal}
        setImage={(img: string | ArrayBuffer) => {
          setProfileImage(img);
          showUploadPhotoModal(false);
          showCropImgModal(true);
        }}
        onCancel={() => {
          showUploadPhotoModal(false);
          showCropImgModal(false);
          setCropImageUrl("");
        }}
      />
      <CropPhoto
        open={openCropImageModal}
        imageSrc={profileImg ?? ""}
        setCropImageUrl={setCropImageUrl}
        onCancel={() => {
          showCropImgModal(false);
          showUploadPhotoModal(true);
          setCropImageUrl("");
        }}
        onSave={() => {
          showUploadPhotoModal(false);
          showCropImgModal(false);
          uploadCroppedImg();
        }}
      />
      {editProfile && (
        <div className="flex w-full lg:hidden">
          <div className="p-4 bg-brown-50 rounded-3xl justify-start items-center gap-4 inline-flex w-full">
            <img
              alt="user-avatar"
              src={
                croppedImgUrl ||
                (profileData?.avatarUrl ?? FALLBACK_PROFILE_IMG)
              }
              className="h-[100px] w-[100px] rounded-full object-contain overflow-hidden"
              data-testid="user-avatar"
            />
            <div className="justify-start items-start gap-1 inline-flex">
              <Button
                variant={"secondary"}
                className="bg-black-4a"
                onClick={() => showUploadPhotoModal(true)}
              >
                Change photo
              </Button>
              <Button
                variant={"icon"}
                onClick={() => showRemoveProfilePicDialog(true)}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      )}
      <div
        className={cn(
          "lg:flex lg:w-[300px] py-6 px-4 md:px-9 lg:px-6 bg-brown-50 flex flex-col items-center rounded-3xl space-y-6",
          editProfile ? "hidden" : ""
        )}
      >
        <div className="rounded-full flex-col justify-start items-center gap-1.5 inline-flex">
          <div className="flex relative">
            <img
              alt="user-avatar"
              src={
                croppedImgUrl ||
                (profileData?.avatarUrl ?? FALLBACK_PROFILE_IMG)
              }
              className="max-h-[100px] max-w-[100px] rounded-full object-contain"
              data-testid="user-avatar"
            />
            {/* {editProfile && (
              <button
                type="button"
                onClick={() => showUploadPhotoModal(true)}
                className="absolute bottom-[8px] -right-[6px] p-2 bg-red-100 rounded-full justify-start items-start gap-2.5 flex"
              >
                <Icons
                  name="camera-filled"
                  height={20}
                  width={20}
                  fill="#D03C2F"
                />
              </button>
            )} */}
          </div>
          {/* {editProfile && (
            <Button
              variant={"ghost"}
              onClick={() => showRemoveProfilePicDialog(true)}
              className="px-2.5 py-1 rounded-[100px] justify-center items-center flex"
            >
              <div className="text-center button-text-small">Remove</div>
            </Button>
          )} */}
        </div>
        <div className="flex flex-col items-center space-y-2">
          <img
            alt="org logo"
            src={
              organizationData?.logoFullUrl ??
              "https://via.placeholder.com/48x48"
            }
            className="h-6"
            data-testid="cobrand-logo"
          />
          {/* <img
            alt="org logo"
            src={profileData?.orgLogo}
            className="h-6"
            data-testid="cobrand-logo"
          /> */}
          <div className="space-y-1">
            <div
              className="title-6 text-cool-grey-700 text-center"
              data-testid="user-name"
            >
              {`${profileData?.firstName} ${profileData?.lastName}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
