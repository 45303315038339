import { QuestLandingPage } from "../QuestLanding/QuestLandingPage";
import Icons from "components/icons/Icons";
import type { Page, B2bQuest } from "/types/__generated__/graphql";

export const QuestConsumptionHeaderMobile = ({
  page,
  total,
}: {
  page: Page;
  total?: number;
}) => {
  return (
    <div className="lg:hidden px-4 py-3 flex items-center justify-between bg-white">
      <div>
        <div className="title-8 text-cool-grey-700">{page?.quest?.name}</div>
        <div className="title-10 text-cool-grey-500 pt-0.5 capitalize">
          lesson {page?.position} of {total}
        </div>
      </div>
      <QuestLandingPage
        questArgs={page?.quest as B2bQuest}
        trigger={
          <button type="button">
            <Icons name="more-vertical-outlined" />
          </button>
        }
      />
    </div>
  );
};
