// src/components/VideoPlayer/MVUniversalPlayerWrapper.js
import { h, defineComponent } from "vue";
import MVUniversalPlayer, {
  MVAudioPlayer,
  MVAudioResource,
  MVVideoPlayer,
  MVVideoResource,
  MVAudioDescription,
  MVVideoDescription,
} from "@mindvalley/mv-universal-player";

// TEMPORARY FIX TO MAP THE MIME TYPES
const ALLOWED_MIME_TYPES = [
  "application/x-mpegURL",
  "video/mp4",
  "audio/mpeg",
  "audio/mp3",
  "audio/ogg",
];

export default defineComponent({
  name: "MVUniversalMedialayerWrapper",
  components: {
    MVUniversalPlayer,
    MVAudioPlayer,
    MVAudioResource,
    MVAudioDescription,
    MVVideoDescription,
    MVVideoPlayer,
    MVVideoResource,
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatSources(localSources = []) {
      return (localSources || [])
        .filter((source: any) =>
          ALLOWED_MIME_TYPES.includes(source.contentType)
        )
        .map((source: any) => ({
          type: source.contentType,
          src: source.url,
        }));
    },
  },
  render() {
    return h(
      "div",
      {
        class: "mv-universal-player-container w-full",
      },
      [
        h(MVVideoResource, {
          id: this.media.id,
          sources: this.formatSources(this.media.mediaAsset.renditions),
          posterUrl: this.media.posterUrl,
          duration: this.media.duration,
          // markers: this.media.markers,
          muted: this.muted,
          autoplay: this.autoplay,
          class: "mv-universal-player",
        }),

        h(MVVideoDescription, {
          title: this.media.title,
          imageSrc: this.media.authorImage,
          name: this.media.artistName,
          headline: this.media.headline,
          description: this.media.description,
          showMoreText: "Show More",
          showLessText: "Show Less",
          class: "h-fit pt-4",
          blurEffect: false,
          overlay: false,
        }),
      ]
    );
  },
});
