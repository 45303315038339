import { Button } from "components/Button";
import { CarouselNext, CarouselPrevious } from "components/Carousel";
import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import { Link } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";

import type { B2bQuest } from "/types/__generated__/graphql";

export function ContinueLearningNavigation({
  courses,
  currentItem,
  nextPerviousArrowHandler,
}: {
  courses: B2bQuest[];
  currentItem: number;
  nextPerviousArrowHandler: (value: number) => void;
}) {
  const hasSingleCourse = courses.length === 1;

  return (
    <div
      className={cn(
        "flex flex-col md:flex-row items-start justify-between",
        "w-full h-fit 2xl:pl-9 gap-4 py-6",
        "md:items-center lg:items-start"
      )}
    >
      {/* Left side content */}
      <div className="flex flex-col gap-3 w-full 2xl:w-8/12">
        <h2 className="heading-4 text-balance">Continue where you left off</h2>

        <div className="text-sm body-small mb-4">
          {hasSingleCourse ? (
            <span>
              While we encourage exploration, focusing on a few key programs at
              a time allows for richer learning experiences. Master one skill
              before moving on to the next.
            </span>
          ) : (
            <span>
              We know life gets busy, but revisiting your library helps maintain
              progress and keeps you motivated. Dive deeper into the skills
              you're building and unlock new learning opportunities within your
              existing programs.
            </span>
          )}
        </div>

        {/* Action button */}
        {hasSingleCourse ? (
          <Link to={NavigationEnum.Programs} className="w-full md:w-auto">
            <Button variant="neutral" className="gap-2 w-full md:w-auto">
              <span className="button-text-medium">Discover more</span>
              <Icons
                name="arrow-right-filled"
                width={14}
                height={14}
                fill="black"
                className="opacity-70"
              />
            </Button>
          </Link>
        ) : (
          <Link to={NavigationEnum.Library} className="w-full md:w-auto">
            <Button
              variant="ghost"
              className="gap-2 w-full md:w-auto bg-black-4a hover:bg-black-6a text-white"
            >
              <span className="button-text-medium text-cool-grey-700">
                Go to my library
              </span>
              <Icons
                name="arrow-right-filled"
                width={14}
                height={14}
                fill="black"
              />
            </Button>
          </Link>
        )}
      </div>

      {/* Navigation buttons - shown if more than 1 course */}
      {courses.length > 1 && (
        <div className="hidden md:flex items-center gap-2 self-start mt-2 md:mt-0">
          <CarouselPrevious
            disabled={currentItem === 0}
            onClick={() => nextPerviousArrowHandler(-1)}
            aria-label="View previous course"
          />

          <CarouselNext
            disabled={currentItem === courses.length - 1}
            onClick={() => nextPerviousArrowHandler(1)}
            aria-label="View next course"
          />
        </div>
      )}
    </div>
  );
}
