import { useState } from "react";
import { Button } from "components/Button";
import {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "components/Carousel";
import useScreenType from "hooks/useScreenType";
import useCollectionModalStore from "stores/collectionModalStore";
import type { B2bChannel, B2bCollection } from "types/__generated__/graphql";
import { cn } from "libs/classMerger";

export const CuratedCollections = ({
  type,
  data,
  isFetching,
  fetchingError,
  handleViewCollectionNavigation,
}: {
  type: "meditations" | "soundscapes";
  data: B2bChannel | null;
  isFetching: boolean;
  fetchingError: Error | undefined;
  handleViewCollectionNavigation: () => void;
}) => {
  const screenType = useScreenType();
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const isMobile = screenType.isSmallMobile || screenType.isMobile || screenType.isTablet;

  const openWithCollection = useCollectionModalStore(
    (state) => state.openWithCollection
  );
  const setMediaDisplayType = useCollectionModalStore(
    (state) => state.setMediaDisplayType
  );

  // TODO:: Handle with skelly
  if (!data || isFetching || fetchingError) return null;

  const filteredCollections =
    screenType.isMobile || screenType.isLargeDesktop
      ? data.collections
      : data.collections?.slice(0, 3);

  const handleCollectionClick = (colection: B2bCollection) => {
    setMediaDisplayType(type);
    openWithCollection(colection);
  };

  return (
    <div className="flex flex-col w-full md:gap-0">
      <Carousel
        opts={{
          align: "start",
          active: isMobile,
        }}
        orientation="horizontal"
        className="w-full"
        setApi={setCarouselApi}
      >
        <div className="flex justify-between items-center">
          <div className="heading-6">Explore curated collections</div>
          <div className="flex gap-2 items-center">
            <Button variant="ghost" onClick={handleViewCollectionNavigation}>
              See all
            </Button>
            <div className="hidden md:flex lg:hidden items-start space-x-2 ">
              <CarouselPrevious />
              <CarouselNext />
            </div>
          </div>
        </div>
        <CarouselContent className="-ml-4 mt-4">
          {filteredCollections?.map((item, index) => (
            <CarouselItem
              key={item?.id}
              className={cn(
                "basis-full md:basis-1/2 lg:basis-1/3 2xl:basis-1/4 pl-4",
                index === filteredCollections.length - 1 && "pr-0"
              )}
            >
              {item && (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleCollectionClick(item)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleCollectionClick(item);
                    }
                  }}
                >
                  <div className="flex flex-col gap-3">
                    <div className="overflow-hidden rounded-md">
                      <img
                        className="object-cover aspect-video rounded-md transition-all ease-in-out duration-500 hover:scale-105 "
                        alt={item.title ?? "Default Alt Text"}
                        src={item.coverAsset?.url ?? ""}
                      />
                    </div>
                    <div className="flex flex-col gap-1 items-start">
                      <div className="title-10 lg:hidden text-cool-grey-450">
                        {item.subtitle}
                      </div>
                      <div className="heading-7">{item.title}</div>
                      <div className="caption-disclaimer text-cool-grey-500 line-clamp-2 text-start">
                        {item.description}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
