import { Fragment } from "react";
import { Skeleton } from "components/Skeleton";
import { Button } from "components/Button";
import useCollectionModalStore from "stores/collectionModalStore";
import type { B2bCollection } from "types/__generated__/graphql";

export function CollectionsListGridSection({
  type,
  headerButtonArray,
  showTitle = true,
  copy,
  sectionRef,
  isFetching,
  collectionsData,
  isCollections = false,
  handleCategoryNavigation,
  selectedCategory,
  handleChangeCategory,
}: {
  type: "soundscapes" | "meditations";
  showTitle?: boolean;
  headerButtonArray?: string[];
  copy: {
    title: string;
  };
  sectionRef: React.RefObject<HTMLDivElement>;
  isFetching: boolean;
  collectionsData: B2bCollection[];
  isCollections?: boolean;
  handleCategoryNavigation: (slug?: string) => void;
  selectedCategory?: string;
  handleChangeCategory?: (category: string) => void;
}) {
  const openWithCollection = useCollectionModalStore(
    (state) => state.openWithCollection
  );

  const handleCollectionClick = (colection: B2bCollection) => {
    openWithCollection(colection);
  };

  return (
    <section ref={sectionRef} className="grid gap-4">
      <div className="py-2 w-full h-12 flex items-center bg-white sticky top-0 z-10">
        <>
          {showTitle && <h3 className="heading-6">{copy.title}</h3>}
          <ul className="flex gap-1 flex-wrap">
            {headerButtonArray?.map((button) => (
              <Button
                key={button}
                id={button}
                variant="outline"
                size="xs"
                data-selected={button === selectedCategory}
                className="!title-9"
                onClick={() => handleChangeCategory?.(button)}
              >
                {button}
              </Button>
            ))}
          </ul>
        </>
      </div>
      {isFetching ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-6">
            {[...Array(8)].map((_, index) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <Skeleton key={index} className="w-full h-[231px]" />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-6">
            {collectionsData.map((item) => (
              <Fragment key={item.id}>
                {isCollections ? (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => handleCollectionClick(item)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        handleCollectionClick(item);
                      }
                    }}
                    className="overflow-hidden rounded-md"
                  >
                    <img
                      className="object-cover aspect-video transition-all ease-in-out duration-500 hover:scale-105"
                      alt={item.title ?? ""}
                      src={item.coverAsset?.url ?? ""}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => handleCategoryNavigation(item.slug)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.preventDefault();
                        handleCategoryNavigation(item.slug);
                      }
                    }}
                    className="cursor-pointer"
                  >
                    <div className="overflow-hidden rounded-md">
                      <img
                        className="object-cover aspect-video transition-all ease-in-out duration-500 hover:scale-105 "
                        alt={item.title ?? ""}
                        src={item.coverAsset?.url ?? ""}
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </>
      )}
    </section>
  );
}
