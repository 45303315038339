import { useUserState } from "context/userContext";
import {
  useGetQuestsAssignmentsQuery,
  type GetQuestsAssignmentsQueryVariables,
  type WorkwayQuestAssignment,
} from "types/__generated__/graphql";

function useAssignedQuests(variables: GetQuestsAssignmentsQueryVariables) {
  const { isAuthenticated } = useUserState();

  const { loading, error, data } = useGetQuestsAssignmentsQuery({
    skip: !isAuthenticated,
    fetchPolicy: "cache-and-network",
    // returnPartialData: true,
    variables,
  });

  const quests = data?.currentWorkwayQuestAssignments?.edges?.map(
    (edge) => edge?.node
  ) as WorkwayQuestAssignment[] | null;

  return { data: quests, loading, error };
}

export default useAssignedQuests;
