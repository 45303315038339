import { useState, useEffect } from "react";
import "./QuestCard.css";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "components/Sheet";
import { UserAccessStateEnum } from "context/userContext";
import { cn } from "libs/classMerger";
import type { ICategoryNode } from "/types/interfaces";
import { QuestCardWithWordmark } from "./QuestCardWithWordmark";
import { QuestCardBase } from "./QuestCardBase";
import { QuestHoverCard } from "./QuestHoverCard";
import { QuestLandingModal } from "./questLandingModal/QuestLandingModal";

import {
  useGetQuestTrailerBySlugAndLanguageLazyQuery,
  type B2bQuest,
} from "types/__generated__/graphql";

export const QuestCardVariants = {
  Base: "base",
  Wordmark: "wordmark",
} as const;

export type QuestCardVariantType =
  (typeof QuestCardVariants)[keyof typeof QuestCardVariants];

export const QuestCard = ({
  quest,
  category,
  cardVariant,
  questAssignmentEndDate,
  canHover = false,
  className,
  userAccessState,
  freemiumLockedOverride = false,
}: {
  quest: B2bQuest;
  category?: ICategoryNode;
  cardVariant?: QuestCardVariantType;
  questAssignmentEndDate?: string;
  canHover?: boolean;
  className?: string;
  userAccessState: UserAccessStateEnum;
  freemiumLockedOverride?: boolean;
}) => {
  const [isSheetOpen, setSheetOpen] = useState(false);

  const [fetchQuestDetails, { data: questHoverDetails }] =
    useGetQuestTrailerBySlugAndLanguageLazyQuery({
      variables: {
        slugAndLanguage: {
          slug: quest.slug || "",
          language: quest.language || "",
        },
      },
      fetchPolicy: "no-cache", // TODO:: TEMP fix cache issue, im not graphql expert
    });

  const handleHover = () => {
    if (!questHoverDetails && !questHoverDetails) {
      fetchQuestDetails();
    }
  };

  const hoverCardData =
    questHoverDetails?.b2bQuestBySlugAndLanguage as B2bQuest;

  useEffect(() => {
    if (isSheetOpen) {
      // Pushing the change to the end of the call stack
      const timer = setTimeout(() => {
        document.body.style.pointerEvents = "";
      }, 0);

      return () => clearTimeout(timer);
    }

    document.body.style.pointerEvents = "auto";
  }, [isSheetOpen]);

  return (
    <HoverCard openDelay={0} closeDelay={0}>
      <Sheet open={isSheetOpen} onOpenChange={setSheetOpen}>
        <HoverCardTrigger asChild>
          <SheetTrigger asChild>
            <div
              onClick={() => setSheetOpen(true)}
              onKeyDown={() => {}}
              onPointerEnter={handleHover}
              className={cn(className)}
            >
              {cardVariant === QuestCardVariants.Wordmark ? (
                <QuestCardWithWordmark
                  quest={quest}
                  userAccessState={userAccessState}
                  freemiumLockedOverride={freemiumLockedOverride}
                />
              ) : (
                <QuestCardBase
                  quest={quest}
                  questAssignmentEndDate={questAssignmentEndDate}
                  userAccessState={userAccessState}
                  freemiumLockedOverride={freemiumLockedOverride}
                />
              )}
            </div>
          </SheetTrigger>
        </HoverCardTrigger>

        {canHover &&
          (userAccessState === UserAccessStateEnum.Premium ||
            (userAccessState === UserAccessStateEnum.Freemium &&
              freemiumLockedOverride)) && (
            <>
              <HoverCardContent
                key={quest.id}
                className="animate-fade-in shadow-md HoverCardContent fixed z-50"
                side="top"
                align="start"
                avoidCollisions={true}
                hideWhenDetached={true}
                collisionPadding={5}
                // forceMount={true} // Util to force mount the content
              >
                <QuestHoverCard
                  quest={quest}
                  category={category}
                  questAssignmentEndDate={questAssignmentEndDate}
                  questTrailerData={hoverCardData}
                />
              </HoverCardContent>

              <SheetContent
                side={"bottom"}
                className="bg-white shadow-zinc-700/10 ring-zinc-700/10 top-12 md:top-16 lg:top-20 rounded-2xl  shadow-lg ring-1 transition mx-auto lg:max-w-[1000px] xs:w-full xs:max-w-[1000px] !p-0 !rounded-t-3xl !rounded-b-none !overflow-y-scroll md:max-h-[calc(100dvh-64px)] max-h-[calc(100dvh-48px)] scrollbar-hide"
              >
                <SheetClose asChild>
                  <QuestLandingModal questArgs={quest} />
                </SheetClose>
              </SheetContent>
            </>
          )}
      </Sheet>
    </HoverCard>
  );
};
