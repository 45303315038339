const EmptyStateSearch = () => {
    return (
        <div className="flex flex-col items-center gap-4">
            <svg
                width="240"
                height="240"
                viewBox="0 0 240 240"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="120"
                    cy="120"
                    r="120"
                    fill="#F5F6F7"
                />
                <circle
                    cx="120"
                    cy="108"
                    r="48"
                    stroke="#71767F"
                    strokeWidth="8"
                />
                <line
                    x1="154.485"
                    y1="142.485"
                    x2="178.485"
                    y2="166.485"
                    stroke="#71767F"
                    strokeWidth="8"
                    strokeLinecap="round"
                />
            </svg>
            <h2 className="heading-8 text-cool-grey-700">Search for content</h2>
            <p className="body text-cool-grey-500 text-center">Try searching for an outcome or goal that you want to achieve.</p>
        </div>
    );
};

export default EmptyStateSearch;