import { LoginWithMicrosoftButton } from "components/LoginWithMicrosoftButton";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useMicrosoftLogin } from "hooks/useMicrosoftLogin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LockedModal from "./LockedModal";
import { NavigationEnum } from "router/navigationType";

const StickyBar = () => {
  const { userAccessState } = useUserState();
  const handleLogin = useMicrosoftLogin();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isFreemium = userAccessState === UserAccessStateEnum.Freemium;

  const orText = "or ";
  const genInTouchTexts = ["Get in touch", "get in touch"];
  const salesText = " with our sales team";

  const getInTouchButton = (text: string) => (
    <button
      className="underline text-red-600"
      onClick={() => navigate(NavigationEnum.InterestForm)}
      type="button"
    >
      {text}
    </button>
  );

  return (
    <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center min-h-16 bg-brown-90a backdrop-blur-[6px] w-screen py-1 gap-x-3 gap-y-3">
      <p className="lg:block md:block hidden title-9">Ready to get started?</p>
      {!isFreemium && (
        <LoginWithMicrosoftButton
          handleClick={handleLogin}
          iconSize={16}
          customClass="w-fit"
        />
      )}
      <p className="title-9">
        {!isFreemium && orText}
        {getInTouchButton(isFreemium ? genInTouchTexts[0] : genInTouchTexts[1])}
        {salesText}
      </p>
      <LockedModal
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        userAccessState={userAccessState}
      />
    </div>
  );
};

export default StickyBar;
