import {
  useGetQuestsByProgressQuery,
  type ResourceTypeFilterEnum,
  type GetQuestsByProgressQueryVariables,
  type B2bQuest,
} from "types/__generated__/graphql";

function useQuestsByProgress({
  progress,
  first = 4,
  type,
}: {
  progress: string;
  first: number;
  type: ResourceTypeFilterEnum;
}) {
  const variables: GetQuestsByProgressQueryVariables = {
    progress,
    first,
    type,
  };

  const { data, loading, error, refetch } = useGetQuestsByProgressQuery({
    variables,
    fetchPolicy: "cache-and-network",
  });

  const quests = data?.b2bQuests?.edges?.map((edge) => edge?.node) ?? null;

  return {
    data: quests as B2bQuest[] | null,
    loading,
    error,
    refetch,
  };
}

export default useQuestsByProgress;
