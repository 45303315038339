import { Drawer, DrawerContent, DrawerTrigger } from "./Drawer";

export const MenuDrawer = ({
    children,
    trigger,
}: {
    children: JSX.Element;
    trigger: JSX.Element;
}) => {
    return (
        <Drawer>
            <DrawerTrigger asChild>{trigger}</DrawerTrigger>
            <DrawerContent>
                <div className="mx-auto w-full max-w-[640px]">{children}</div>
            </DrawerContent>
        </Drawer>
    );
};