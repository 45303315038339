import axios from 'axios';
import { apiConfig } from "config/apiConfig";

async function searchTypesense(collection: string, query: string, queryBy: string, type: string | null = null) {
    try {
        const params = {
            q: query,
            query_by: queryBy,
            per_page: 10,
            filter_by: `language:=[en]${type ? ` && type:=${type}` : ''}`
        };
        const response = await axios.get(`${apiConfig.typeSenseEndpoint}/collections/${collection}/documents/search`, {
            headers: {
                'X-TYPESENSE-API-KEY': apiConfig.typeSenseApiKey
            },
            params
        });
        return response.data.hits;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export async function searchMeditations(query: string) {
    return await searchTypesense('production_meditations', query, "title,slug", "meditation");
}

export async function searchSoundscapes(query: string) {
    return await searchTypesense('production_meditations', query, "title,slug", "sound");
}

export async function searchQuests(query: string) {
    return await searchTypesense('production_quests', query, "name,slug");
}