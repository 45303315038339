import { pages } from "@microsoft/teams-js";
import { teamsConfig } from "config/teamsConfig";
import type { Page, B2bQuest } from "/types/__generated__/graphql";

export function tabNavigation({
  pageId,
  subPageId,
}: {
  pageId: string;
  subPageId?: string;
}) {
  if (pages.currentApp.isSupported()) {
    const navPromise = pages.currentApp.navigateTo({
      pageId: pageId,
      subPageId: subPageId,
    });
    navPromise.then(() => {}).catch((error) => {});
  } else {
    const navPromise = pages.navigateToApp({
      appId: teamsConfig.appId,
      pageId: pageId,
      subPageId: subPageId,
    });
    navPromise.then(() => {}).catch((error) => {});
  }
}

// TODO:: We might need to revisit this
// Hacky way or im not sure the proper way to do this to get the first incomplete preparation page. Platform should really return intro as part of the resume or next page node
export function getNextIncompletePreparationPage(pages?: Page[]): Page | null {
  return (
    pages?.find((page) => page.type === "intro" && !page.completed) ?? null
  );
}

export function getNextQuestNavigationUrl({
  quest,
  isStartingQuest = false,
}: {
  quest: B2bQuest;
  isStartingQuest?: boolean;
}): string {
  // Determine if there are any intros that haven't been completed.
  const totalIntros = quest.userProgress?.totalIntros ?? 0;
  const totalCompleted = quest.userProgress?.totalIntrosCompleted ?? 0;
  const hasRemainingIntros = totalIntros > 0 && totalCompleted < totalIntros;

  // TODO:: TEMP FIX To override platform issue
  // const nextPosition = quest.pages?.find(
  //   (page) =>
  //     (page.type === "day" || page.type === "weekly") &&
  //     page.completed === false
  // );

  // const nextPosition = quest.userProgress?.nextPage?.position

  const nextPageId =
    isStartingQuest && hasRemainingIntros
      ? getNextIncompletePreparationPage(quest.pages)?.id
      : quest.userProgress?.resumePage?.id; //  They never return properly

  console.log("quest", quest);

  console.log("nextPageId", nextPageId);
  const params = new URLSearchParams();
  if (nextPageId) {
    params.append("pageId", nextPageId);
  }

  if (isStartingQuest) {
    params.append("isNewEnrollment", "true");
  }

  const baseUrl = `/quests/${quest.language}/${quest.slug}`;
  const queryString = params.toString();
  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
}
