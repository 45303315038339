import { gql } from "@apollo/client";

export const GET_PICKED_FOR_YOU_QUESTS_PUBLIC = gql`
  query getPickedForYouQuestsPublic($limit: Int) {
    pickedForYouRecommendations(limit: $limit)
      @rest(
        type: "B2bQuest"
        path: "/quests/picked-for-you-quest-public?limit={args.limit}"
      ) {
      id
      name
      description
      slug
      language
      authors @type(name: "Author") {
        name
        avatarAsset {
          url
        }
      }
      coverAsset {
        url
      }
      headshotCoverAsset {
        url
      }
      wordmarkAsset {
        url
      }
      trailerAsset {
        contentType
        thumbnailUrl
        url
      }
      releases {
        id
        status
        perpetual
        publishedAt
      }
      introUrl
      url
      outcomes @type(name: "Outcome") {
        name
      }
      pages @type(name: "Page") {
        position
        type
        sections @type(name: "Section") {
          primaryAsset {
            url
          }
          type
        }
      }
      categories @type(name: "Category") {
        name
      }
      resources @type(name: "Resource") {
        mediaAsset {
          url
          renditions(labels: ["hls", "mp3", "ogg"]) {
            id
            url
            filesize
            contentType
          }
        }
      }
    }
  }
`;

export const GET_TRENDING_GLOBALLY_QUESTS = gql`
  query getTrendingGloballyQuests($limit: Int) {
    trendingGloballyRecommendations(limit: $limit)
      @rest(
        type: "B2bQuest"
        path: "/quests/trending-globally-quests-public?limit={args.limit}"
      ) {
      id
      name
      description
      slug
      type
      duration
      daysCount
      lessonsCount
      averageRating
      freeQuest
      authors @type(name: "Author") {
        name
        avatarAsset {
          url
        }
      }
      coverAsset {
        url
      }
      headshotCoverAsset {
        url
      }
      wordmarkAsset {
        url
      }
      trailerAsset {
        contentType
        thumbnailUrl
        url
      }
      introUrl
      url
      outcomes @type(name: "Outcome") {
        name
      }
      pages @type(name: "Page") {
        position
        type
        sections @type(name: "Section") {
          primaryAsset {
            url
          }
          type
        }
      }
      categories @type(name: "Category") {
        name
      }
      resources @type(name: "Resource") {
        mediaAsset {
          url
        }
      }
    }
  }
`;

export const GET_SOUNDSCAPES_OF_THE_WEEK = gql`
  query getSoundscapesOfTheWeek($limit: Int) {
    soundscapesOfTheWeek(limit: $limit)
      @rest(
        type: "Resource"
        path: "/meditations/soundscapes-of-the-week?limit={args.limit}"
      ) {
      id
      title
      description
      type
      subtype
      averageRating
      author {
        name
        avatarAsset {
          url
        }
      }
      landscapeCoverAsset {
        url
      }
      mediaAsset {
        duration
        url
        renditions(labels: ["hls", "mp3", "ogg"]) {
          id
          url
          filesize
          contentType
        }
      }
      coverAsset {
        url
      }
      trailerLoopingAsset {
        id
        name
        url
        thumbnailUrl
        contentType
        duration
        sizeOfFile
        renditions(labels: ["hls", "mp3", "ogg"]) {
          id
          url
          filesize
          contentType
        }
      }
    }
  }
`;

export const GET_MEDITATION_OF_THE_DAY = gql`
  query getMeditationOfTheDay($limit: Int) {
    meditationOfTheDay(limit: $limit)
      @rest(
        type: "Media"
        path: "/meditations/meditations-of-the-day?limit={args.limit}"
      ) {
      id
      title
      description
      type
      subtype
      author {
        name
      }
      duration
      averageRating
      totalDuration
      mediaAsset {
        id
        duration
        url
        renditions(labels: ["hls", "mp3", "ogg"]) {
          id
          url
          filesize
          contentType
        }
      }
      coverAsset {
        url
      }
      landscapeCoverAsset {
        url
      }
      trailerLoopingAsset {
        id
        url
        renditions(labels: ["hls", "mp3", "ogg"]) {
          id
          url
          filesize
          contentType
        }
      }
      supportingResource {
        id
        type
        subtype
        averageRating
        title
        description
        author {
          name
        }
        coverAsset {
          url
        }
        mediaAsset {
          id
          renditions(labels: ["hls", "mp3", "ogg"]) {
            id
            url
            filesize
            contentType
          }
        }
      }
    }
  }
`;

export const GET_RECOMMENDED_HARMONIZED_MEDITATIONS = gql`
  query getRecommendedHarmonizedMeditations($limit: Int, $orgId: ID!) {
    recommendedB2bHarmonizedMeditation(limit: $limit, orgId: $orgId)
      @rest(
        type: "RecommendationPayload"
        path: "/meditations/harmonized-meditations?orgId={args.orgId}&limit={args.limit}"
      ) {
      id
      title
      description
      type
      subtype
      averageRating
      author {
        name
        avatarAsset {
          url
        }
      }
      coverAsset {
        url
      }
      landscapeCoverAsset {
        url
      }
      mediaAsset {
        id
        duration
        url
        renditions(labels: ["hls", "mp3", "ogg"]) {
          id
          url
          filesize
          contentType
        }
      }
      trailerLoopingAsset {
        url
        renditions(labels: ["hls", "mp3", "ogg"]) {
          id
          url
          filesize
          contentType
        }
      }
      supportingResource {
        id
        mediaAsset {
          renditions(labels: ["hls", "mp3", "ogg"]) {
            id
            url
            filesize
            contentType
          }
        }
      }
    }
  }
`;
