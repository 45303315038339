import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import LockedModal from "components/LockedModal";
import { EnrollmentCongratsModal } from "./EnrollmentCongratsModal";
import { QuestConsumptionSkeleton } from "components/loading/QuestConsumptionSkeleton";
import { useUserState } from "context/userContext";
import useQuestMutations from "hooks/useQuestMutations";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import { QuestConsumptionFooter } from "./QuestConsumptionFooter";
import { MediaModal } from "components/MVPlayer/MediaModal";
import QuestDetails from "./QuestDetails";
import QuestConsumptionSideBar from "./QuestConsumptionSideBar";
import { QuestConsumptionHeaderMobile } from "./QuestConsumptionHeaderMobile";
import { getLessonsStatus } from "./utils";
import {
  useGetQuestPageByIdQuery,
  type B2bQuest,
  type Page,
  type UserProgress,
  type Media,
} from "types/__generated__/graphql";
import QuestConsumptionVideoResourcePlayer from "components/MVPlayer/QuestConsumptionVideoResourcePlayer";
// import MVMeditationResource from "components/MVPlayer/MVMeditationResource";
import MVUniversalAudioPlayer from "components/MVPlayer/MVUniversalAudioPlayer";

const QuestConsumption = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageId = searchParams.get("pageId");
  const isNewEnrollment = searchParams.get("isNewEnrollment") === "true";

  // STATE
  const { userAccessState } = useUserState();
  const [isCongratsModalOpen, setIsCongratsModalOpen] =
    useState(isNewEnrollment);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [currentResource, setCurrentResource] = useState<Media | null>(null);

  const {
    data: pageRaw,
    loading: pageLoading,
    refetch: refetchPage,
  } = useGetQuestPageByIdQuery({
    variables: {
      id: pageId || "",
    },
    skip: !pageId,
  });

  const {
    handleStartQuest,
    handleToggleTaskCompletion,
    handleMarkPageAsCompleted,
  } = useQuestMutations();

  const page = pageRaw?.page as Page;

  // const mainMedia = page?.sections?.find(
  //   (s) => s.type === "video"
  // )?.primaryAsset;

  const mainMedia = page?.sections?.find((s) => s.position === 1);
  const mediaData = mainMedia?.primaryAsset || null;

  // const mediaId = mainMedia?.id || "";
  // const mediaUrl = mainMedia?.primaryAsset?.renditions || [];
  // const markers = mainMedia?.primaryAsset?.markers || [];
  // const duration = mainMedia?.duration || 0;
  // const author = page?.quest?.authors?.[0]?.name || "";

  const { total } = getLessonsStatus(
    page?.quest?.type || "",
    page?.quest?.userProgress as UserProgress
  );

  const clearNewEnrollmentParam = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("isNewEnrollment");
    setSearchParams(updatedParams);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  function handleLessonSelect(selectedPage: Page) {
    refetchPage({
      id: selectedPage.id,
    });
  }

  async function handlePageCompleted(page: Page) {
    const res = await handleMarkPageAsCompleted(page.id);

    if (!res.success) return;

    const isLastPage =
      page?.position ===
      Math.max(
        ...(page.quest?.pages?.map((page) => page.position ?? 0) ?? [0])
      );

    if (isLastPage) {
      return;
    }

    refetchPage({
      id: res.nextPage?.id || page.quest?.userProgress?.nextPage?.id,
    });
  }

  if (pageLoading || !page) return <QuestConsumptionSkeleton />;

  return (
    <div className="bg-white flex flex-col min-h-screen lg:overflow-auto w-full">
      <div className="flex-1 flex flex-col lg:flex-row overflow-y-auto w-full">
        <div className="lg:h-full flex flex-col lg:flex-1 lg:overflow-y-auto no-scrollbar sticky lg:static top-0 z-10 touch-auto">
          <QuestConsumptionHeaderMobile page={page} total={total} />

          <div className="flex flex-1 lg:flex-none w-full relative">
            <div className="relative size-full">
              {mainMedia ? (
                <>
                  {mainMedia?.type === "video" && (
                    // <MVVideoPlayerResource
                    //   data={{
                    //     id: mediaId,
                    //     mediaAsset: {
                    //       renditions: mediaUrl,
                    //       duration: duration,
                    //       markers,
                    //     },
                    //     coverAsset: page.coverAsset,
                    //     title: page.name,
                    //     author,
                    //   }}
                    //   muted={false}
                    //   autoplay={!isNewEnrollment}
                    // />
                    <QuestConsumptionVideoResourcePlayer
                      media={{
                        id: mainMedia?.id || "",
                        title: page.name,
                        mediaAsset: mediaData,
                        coverAsset: page.coverAsset,
                        author: page.quest?.authors?.[0]?.name || "",
                      }}
                      muted={false}
                      autoplay={!isNewEnrollment}
                    />
                  )}
                  {mainMedia?.type === "media" && (
                    // {/* <MVMeditationResource
                    //     key={currentResource.id}
                    //     media={currentResource || null}
                    //     autoPlay={false}
                    //     muted={false}
                    //     onClose={() => setIsResourcesPlaying(false)}
                    //     onMaximize={handleMaximize}
                    //     onMinimize={handleMinimize}
                    //   /> */}
                    <MVUniversalAudioPlayer
                      media={mainMedia.media || null}
                      muted={false}
                      autoplay={false}
                    />
                  )}
                </>
              ) : (
                <div
                  className="size-full aspect-video bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${page?.quest?.coverAsset?.url})`,
                  }}
                />
              )}
            </div>
          </div>

          <div className="hidden lg:block m-6 ml-10">
            <QuestDetails
              page={page}
              onMarkLessonAsComplete={() => handlePageCompleted(page)}
              onStartProgram={() =>
                handleStartQuest({
                  questId: page.quest?.id,
                  releaseId: page.quest?.releases?.[0]?.id,
                })
              }
            />
          </div>
        </div>
        <div className="flex h-full">
          <QuestConsumptionSideBar
            page={page}
            // onLandinPageOpen={() => console.log("")}
            onLessonSelect={(selectedPage) => handleLessonSelect(selectedPage)}
            onStartProgram={() =>
              handleStartQuest({
                questId: page.quest?.id,
                releaseId: page.quest?.releases?.[0]?.id,
              })
            }
            onToggleTask={(task) => handleToggleTaskCompletion(task)}
            handleResourcePlay={(resource: any) => {
              console.log("resource", resource);
              if (resource.type === "meditation") {
                setCurrentResource(resource);
              }

              // if (track?.id === resource.id) {
              //   if (isPlaying) {
              //     setIsPlaying(false);
              //   } else {
              //     setIsPlaying(true);
              //   }
              //   return;
              // }

              // if (resource.type === "video") {
              //   setCurrentResource(resource);
              //   setIsMediaModalOpen(true);
              //   return;
              // }

              // playTrack(resource, false);
              // setSoundscapeMode(false);
            }}
          />
        </div>
        <EnrollmentCongratsModal
          quest={page.quest as B2bQuest}
          open={isCongratsModalOpen}
          onClose={() => {
            setIsCongratsModalOpen(false);
            clearNewEnrollmentParam();
          }}
        />
        <QuestConsumptionFooter
          page={page}
          onMarkPageAsCompleted={() => handlePageCompleted(page)}
        />
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          userAccessState={userAccessState}
        />
        <MediaModal
          onClose={() => setIsMediaModalOpen(false)}
          open={isMediaModalOpen}
          media={currentResource}
        />
      </div>
    </div>
  );
};

export default QuestConsumption;
