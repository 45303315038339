import { useQuery } from "@apollo/client";
import { GET_SOUNDSCAPES_OF_THE_WEEK } from "services/graphql/queries/dsApiQueries";

function useSoundscapeOfTheWeek({ prevData = [] }) {
  const { data, loading, error } = useQuery(GET_SOUNDSCAPES_OF_THE_WEEK, {
    variables: {
      limit: 4,
      skip: prevData.length > 0,
    },
    context: {
      clientName: "rest",
    },
  });

  const soundscapes = data?.soundscapesOfTheWeek || prevData;

  return { data: soundscapes, loading, error };
}

export default useSoundscapeOfTheWeek;
