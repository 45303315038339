import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetTitle,
  SheetDescription,
} from "components/Sheet";
import { QuestLandingModal } from "components/quest/questLandingModal/QuestLandingModal";
import type { B2bQuest } from "/types/__generated__/graphql";

export const QuestLandingPage = ({
  trigger,
  questData,
  questArgs,
  open,
  onOpenChange,
}: {
  trigger?: JSX.Element;
  questData?: B2bQuest;
  questArgs: {
    slug?: string;
    language?: string;
  };
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}) => {
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      {trigger && (
        <SheetTrigger className="flex justify-center">{trigger}</SheetTrigger>
      )}

      <SheetTitle className="sr-only">Quest Details</SheetTitle>
      <SheetDescription className="sr-only">
        Details about the quest.
      </SheetDescription>
      <SheetContent
        side="bottom"
        className="bg-white shadow-white/10 ring-zinc-700/10 top-12 md:top-16 lg:top-20 rounded-2xl shadow-lg transition mx-auto lg:max-w-[1000px] xs:w-full xs:max-w-[1000px] !p-0 !rounded-t-3xl !rounded-b-none !overflow-y-scroll md:max-h-[calc(100dvh-64px)] max-h-[calc(100dvh-48px)] scrollbar-hide z-50"
      >
        <QuestLandingModal preloadQuestData={questData} questArgs={questArgs} />
      </SheetContent>
    </Sheet>
  );
};
