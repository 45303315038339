import { useEffect, useState } from "react";
import BannerSkeleton from "components/loading/BannerSkeleton";
import FeaturedListComponent from "../../../components/FeaturedListComponent";
import type { NavigationEnum } from "router/navigationType";
import type { Resource } from "types/__generated__/graphql";
import LockedModal from "components/LockedModal";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useNavigate } from "react-router-dom";

const MEDITATION_FALLBACK_URL =
  "https://res.cloudinary.com/mindvalley/image/upload/v1734433063/workway/Web/meditations-fallback.jpg";
const SOUNDSCAPE_FALLBACK_URL =
  "https://res.cloudinary.com/mindvalley/image/upload/v1734433072/workway/Web/soundscapes-fallback.jpg";

const FeaturedSection = ({
  data,
  isFetching,
  error,
  copy,
  type,
  handleDiscoverMoreNavigation,
  previewEnabled = false,
}: {
  data: Resource[] | null;
  isFetching: boolean;
  error?: Error;
  copy: {
    title: string;
    description: string;
  };
  type: "meditations" | "soundscapes";
  handleDiscoverMoreNavigation: NavigationEnum;
  previewEnabled?: boolean;
}) => {
  if (!data || isFetching || error || !data[0])
    return <BannerSkeleton circleThumbnail={type === "soundscapes"} />;

  const [banner, setBanner] = useState("");
  const [selectedTrackBanner, setSelectedTrackBanner] = useState(banner);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userAccessState } = useUserState();
  const navigate = useNavigate();
  const backgroundFallbackUrl =
    type === "soundscapes" ? SOUNDSCAPE_FALLBACK_URL : MEDITATION_FALLBACK_URL;

  useEffect(() => {
    if (banner === "" && data) {
      const bannerURL =
        data[0]?.landscapeCoverAsset?.url ?? backgroundFallbackUrl;
      setBanner(bannerURL);
      setSelectedTrackBanner(bannerURL);
    }
  }, [data]);

  const handleDiscoverAction = () => {
    if (userAccessState !== UserAccessStateEnum.Premium) {
      setIsModalOpen(true);
    } else {
      navigate(handleDiscoverMoreNavigation);
    }
  };
  return (
    <>
      <section className="max-w-2xl mx-auto px-5">
        {data && (
          <FeaturedListComponent
            title={copy.title}
            description={copy.description}
            backdropImagePath={banner || ""}
            buttonLink={handleDiscoverMoreNavigation}
            data={data}
            onHover={(image: string) => {
              setBanner(image);
            }}
            type={type}
            onLeave={() => setBanner(selectedTrackBanner)}
            onClick={(image: string) => setSelectedTrackBanner(image)}
            handleMoreAction={handleDiscoverAction}
            previewEnabled={previewEnabled}
          />
        )}
      </section>
      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          userAccessState={userAccessState}
        />
      </div>
    </>
  );
};

export default FeaturedSection;
