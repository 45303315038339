import { Button } from "components/Button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import { QuestCard } from "components/quest/QuestCard";
import { useUserState } from "context/userContext";
import useFreeQuests from "hooks/useFreeQuests";
import { Link } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import FreeQuestSkeleton from "components/loading/FreeQuestSkeleton";
import useScreenType from "hooks/useScreenType";

export const FreeQuestsSection = () => {
  const { userAccessState } = useUserState();
  const { questData, loading, error } = useFreeQuests();
  const screenType = useScreenType();

  const isDraggable = screenType.isSmallMobile || screenType.isMobile || screenType.isTablet;

  if (loading || error) return <FreeQuestSkeleton />;

  return (
    <div className="flex px-15 py-12 bg-free-quests h-full">
      <div className="max-w-2xl mx-auto px-5 flex gap-4 flex-col justify-end">
        <div className="flex flex-start gap-6 lg:gap-10 flex-col lg:flex-row">
          <div className="gap-4 shrink-0 w-full lg:w-1/3 content-end">
            <div className="flex-col items-start gap-1 flex">
              <div className="px-3 py-1 bg-red-600 rounded-3xl">
                <div className="heading-9 text-white">Free Quests</div>
              </div>
              <div className="hidden md:block heading-2">
                Start your personal transformation journey here
              </div>
              <div className="heading-2 md:hidden">
                Start learning and begin exploring
              </div>
              <div className="hidden md:block body-small">
                Explore our free Quests today.
              </div>
              <div className="body-small md:hidden">
                Your adventure in learning begins today. Explore and enjoy our
                free Quests.
              </div>
            </div>
            <Link to={NavigationEnum.Programs} className="hidden md:flex mt-4">
              <Button variant={"neutral"}>Discover more</Button>
            </Link>
          </div>

          <div>
            <Carousel
              opts={{ align: "start", active: isDraggable }}
              orientation="horizontal"
              className="w-full "
            >
              {questData.length > 3 && (
                <div className="hidden md:flex gap-2 items-center justify-end">
                  <CarouselPrevious />
                  <CarouselNext />
                </div>
              )}
              <CarouselContent className="-ml-4 mt-4">
                {questData.map((quest) => (
                  <CarouselItem
                    key={quest.id}
                    className="basis-1/2 md:basis-1/2 2xl:basis-1/3 pl-0"
                  >
                    <QuestCard
                      //@ts-ignore TODO:: fix freemium quest type
                      quest={quest}
                      canHover={
                        screenType.isDesktop || screenType.isLargeDesktop
                      }
                      userAccessState={userAccessState}
                      freemiumLockedOverride={true}
                      className="pl-4"
                    />
                  </CarouselItem>
                ))}
              </CarouselContent>
            </Carousel>
          </div>
          <Link
            to={NavigationEnum.Programs}
            className="col-span-12 md:hidden w-full"
          >
            <Button variant={"neutral"} className="w-full">
              Discover more
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
