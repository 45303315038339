import {
  CarouselPrevious,
  CarouselNext,
  Carousel,
  CarouselContent,
  CarouselItem,
} from "components/Carousel";
import { Button } from "components/Button";
import { cn } from "libs/classMerger";

import type { B2bChannel } from "types/__generated__/graphql";

export const CategoriesSection = ({
  data,
  loading,
  error,
  handleSeeAllNavigation,
  handleCategoryNavigation,
  containerStyle,
}: {
  data: B2bChannel | null;
  loading: boolean;
  error: Error | undefined;
  handleSeeAllNavigation: () => void;
  handleCategoryNavigation: (slug?: string) => void;
  containerStyle?: string;
}) => {
  if (!data || loading || error) return null;

  const description = data.title?.startsWith("Meditation")
    ? "Discover individual meditations designed to help you focus, relax, and recharge whenever you need a moment of mindfulness."
    : data.description;

  return (
    <section className={cn(containerStyle)}>
      <div className="flex gap-y-0 w-full py-10 px-4 md:py-16 xl:py-16 gap-6 max-w-2xl px-5 2xl:px-40 mx-auto w-full">
        <div
          className={cn(
            "flex gap-10 w-full flex-col xl:flex-row relative justify-between"
          )}
        >
          <div className="flex flex-col gap-4 w-full xl:w-2/6">
            <div className="heading-3">{data.title}</div>
            <div className="body">{description}</div>
          </div>
          <div className="flex flex-col gap-4 w-full xl:w-4/6 relative">
            <div className="flex w-full lg:overflow-hidden">
              <Carousel
                opts={{
                  align: "center",
                }}
                orientation="horizontal"
                className="w-full"
              >
                <div className="flex gap-2 items-center justify-end">
                  <Button variant="ghost" onClick={handleSeeAllNavigation}>
                    See all
                  </Button>
                  <div className="hidden md:flex items-start space-x-2 ">
                    <CarouselPrevious />
                    <CarouselNext />
                  </div>
                </div>
                <CarouselContent className="-ml-4 mt-4">
                  {data.collections?.map((category) => (
                    <CarouselItem
                      key={category?.id}
                      className="basis-1/2 sm:basis-1/3 md:basis-1/5 xl:basis-1/4 2xl:basis-1/5 pl-4"
                    >
                      {category && (
                        <div
                          onClick={() => {
                            handleCategoryNavigation(category.slug);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                              handleCategoryNavigation(category.slug);
                            }
                          }}
                          className="rounded-2xl overflow-hidden relative cursor-pointer"
                        >
                          <img
                            className="size-full object-cover rounded-2xl transition-all ease-in-out duration-500 hover:scale-105 "
                            src={category.portraitAsset?.url ?? ""}
                            alt={category.title ?? ""}
                          />
                          <div className="w-full absolute top-0 flex p-3 heading-8 text-white justify-center text-center">
                            {category.title}
                          </div>
                        </div>
                      )}
                    </CarouselItem>
                  ))}
                </CarouselContent>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
