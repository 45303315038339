import { Button } from "components/Button";
import { useEffect } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "components/Carousel";
import { MediaCardComponent } from "components/MediaCardComponent";
import useCollectionModalStore from "stores/collectionModalStore";
import { useUserState } from "context/userContext";
import useScreenType from "hooks/useScreenType";
import { cn } from "libs/classMerger";

import type { B2bCollection } from "types/__generated__/graphql";

export const Collections = ({
  type,
  collection,
}: {
  type: "meditations" | "soundscapes";
  collection: B2bCollection;
}) => {
  const screenType = useScreenType();
  const openCollectionModal = useCollectionModalStore(
    (state) => state.toggleModal
  );
  const mediaDisplayType = useCollectionModalStore(
    (state) => state.mediaDisplayType
  );
  const setCollection = useCollectionModalStore((state) => state.setCollection);
  const { userAccessState } = useUserState();

  const isMobile = screenType.isSmallMobile || screenType.isMobile || screenType.isTablet;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  function handleOpenCollectionModal() {
    setCollection(collection);
    openCollectionModal();
  }

  return (
    <div
      className="flex flex-col gap-6 lg:overflow-hidden"
      id={collection.title ?? undefined}
    >
      <Carousel
        opts={{
          align: "start",
          active: isMobile,
        }}
        orientation="horizontal"
        className="w-full"
      >
        <div className="flex justify-between items-center">
          <div className="heading-6">{collection.title}</div>
          <div className="flex gap-2 items-center">
            <div>
              <Button
                variant="ghost"
                className="hidden lg:flex"
                onClick={handleOpenCollectionModal}
              >
                View collection
              </Button>
              <Button
                variant="ghost"
                className="lg:hidden"
                onClick={handleOpenCollectionModal}
              >
                View
              </Button>
            </div>
            <div className="hidden md:flex lg:hidden items-start space-x-2 ">
              <CarouselPrevious />
              <CarouselNext />
            </div>
          </div>
        </div>
        <CarouselContent className={cn("-ml-2 md:-ml-4 lg:-ml-6 mt-4")}>
          {collection.collectionResources.slice(0, 7).map((item, index) => (
            <CarouselItem
              key={item.id + (item.title ?? "")}
              className={cn(
                "basis-1/2 md:basis-1/4 lg:basis-1/6 2xl:basis-[14.28%] pl-2 md:pl-4 lg:pl-6",
                index === collection.collectionResources.length - 1 && "pr-0"
              )}
            >
              <MediaCardComponent
                type={type ?? mediaDisplayType}
                // @ts-expect-error
                resource={item}
                collectionsData={collection}
                bannerTile={false}
                userAccessState={userAccessState}
                cardOrientation="vertical"
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
