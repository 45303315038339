import { gql } from "@apollo/client";

export const COVER_ASSET_FRAGMENT = gql`
  fragment CoverAssetFragment on Asset {
    id
    url
    edgeUrl
    thumbnailUrl
  }
`;

export const RENDITIONS_FRAGMENT = gql`
  fragment RenditionsFragment on Rendition {
    name
    url
    filesize
    contentType
    duration
    sizeOfFile
    thumbnailUrl
    dashKeyUrl
  }
`;

export const TRAILER_LOOPING_ASSET_FRAGMENT = gql`
  fragment TrailerLoopingAssetFragment on Asset {
    id
    url
    renditions(labels: ["hls", "mp3", "ogg"]) {
      ...RenditionsFragment
    }
  }
  ${RENDITIONS_FRAGMENT}
`;

export const MARKER_FRAGMENT = gql`
  fragment MarkerFragment on Marker {
    id
    name
    status
    time
  }
`;

export const TRAILER_ASSET_FRAGMENT = gql`
  fragment TrailerAssetFragment on Asset {
    id
    thumbnailUrl
    url
    renditions(labels: ["hls", "mp3", "ogg"]) {
      ...RenditionsFragment
    }
    captions {
      id
      language
      label
      default
      isDefault
      url
    }
  }
  ${RENDITIONS_FRAGMENT}
`;

export const RESOURCE_FRAGMENT = gql`
  fragment ResourceFragment on Resource {
    id
    type
    subtype
    averageRating
    title
    description
    author {
      name
    }
    coverAsset {
      ...CoverAssetFragment
    }
    mediaAsset {
      id
      duration
      filesize
      url
      edgeUrl
      renditions(labels: ["hls", "mp3", "ogg"]) {
        ...RenditionsFragment
      }
    }
    tags {
      name
    }
    supportingResource {
      id
      type
      subtype
      averageRating
      title
      description
      author {
        id
        name
      }
      coverAsset {
        ...CoverAssetFragment
      }
      mediaAsset {
        id
        url
        duration
        renditions(labels: ["hls", "mp3", "ogg"]) {
          ...RenditionsFragment
        }
      }
    }
    outcomes {
      name
    }
    trailerLoopingAsset {
      ...TrailerLoopingAssetFragment
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${RENDITIONS_FRAGMENT}
  ${TRAILER_LOOPING_ASSET_FRAGMENT}
`;

export const USER_PROGRESS_FRAGMENT = gql`
  fragment UserProgressFragment on UserProgress {
    completed
    completedAt
    totalDays
    totalDaysCompleted
    daysCompleted
    totalLessonsCompleted
    lessonsCompleted
    totalLessons
    totalIntros
    introsCompleted
    totalIntrosCompleted
    totalLessonsMissed
    started
    startedAt
    ended
    endedAt
    nextPage {
      id
      name
      type
      position
      duration
      completed
      completionsCount
    }
    resumePage {
      id
      name
      type
      position
      duration
      completed
      completionsCount
    }
    currentLesson {
      id
      position
      name
    }
    currentGroup {
      id
      name
      position
    }
    currentDay {
      id
      name
      position
    }
    currentPage {
      id
      name
      position
      duration
      completed
      completionsCount
    }
  }
`;

export const PAGES_FRAGMENT = gql`
  fragment PagesFragment on Page {
    id
    name
    type
    missed
    nextPage {
      id
      name
    }
    groupName
    position
    locked
    preview
    completed
    duration
    coverAsset {
      ...CoverAssetFragment
    }
  }
  ${COVER_ASSET_FRAGMENT}
`;

export const MEDIA_FRAGMENT = gql`
  fragment MediaFragment on Media {
    id
    supportingResource {
      id
      type
      subtype
      averageRating
      title
      description
      author {
        id
        name
      }
      coverAsset {
        ...CoverAssetFragment
      }
      mediaAsset {
        id
        url
        duration
        renditions(labels: ["hls", "mp3", "ogg"]) {
          ...RenditionsFragment
        }
      }
    }
    author {
      avatarAsset {
        url
      }
      description
      headline
      id
      name
      portraitAsset {
        url
      }
      slug
    }
    averageRating
    buttonLink
    buttonText
    completed
    coverAsset {
      url
    }
    currentRating
    description
    descriptionHtml
    featured
    info
    isFavourite
    mediaAsset {
      id
      name
      url
      contentType
      duration
      markers {
        ...MarkerFragment
      }
      renditions(labels: ["hls", "mp3", "ogg"]) {
        ...RenditionsFragment
      }
      userProgress {
        watchTime
      }
    }
    mediaContents {
      completed
      contentAsset {
        renditions(labels: ["hls", "mp3", "ogg"]) {
          ...RenditionsFragment
        }
      }
      coverAsset {
        ...CoverAssetFragment
      }
      description
      duration
      group
      id
      position
      title
      type
    }
    publishedAt
    slug
    subtype
    title
    totalDuration
    type
  }
  ${MARKER_FRAGMENT}
  ${RENDITIONS_FRAGMENT}
  ${COVER_ASSET_FRAGMENT}
`;

export const SECTION_INFO_FRAGMENT = gql`
  fragment SectionInfoFragment on SectionInfo {
    id
    body
    caption
    enrollButtonText
    externalLink
    testimonialAuthorName
    link
    mode
    title
  }
`;

export const SECTION_FRAGMENT = gql`
  fragment SectionFragment on Section {
    id
    type
    coverAsset {
      url
      edgeUrl
    }
    info {
      ...SectionInfoFragment
    }
    media {
      ...MediaFragment
    }
    position
    primaryAsset {
      id
      contentType
      duration
      edgeUrl
      url
      filesize
      markers {
        ...MarkerFragment
      }
      name
      renditions(labels: ["hls", "mp3", "ogg"]) {
        ...RenditionsFragment
      }
      status
      thumbnailUrl
      url
      userProgress {
        watchProgress
        watchTime
        watched
      }
    }
  }
  ${SECTION_INFO_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${MARKER_FRAGMENT}
  ${RENDITIONS_FRAGMENT}
`;

export const GET_CURRENT_ORGANIZATION = gql`
  query getCurrentOrganization {
    currentOrganization {
      id
      name
      division
      logoFullUrl
      logoSquareUrl
    }
  }
`;

export const GET_PICKED_FOR_YOU_QUESTS = gql`
  query getPickedForYouQuests($first: Int, $orgId: ID!) {
    recommendedB2bQuestsPickedForYou(first: $first, orgId: $orgId) {
      edges {
        node {
          quest {
            id
            type
            slug
            name
            description
            language
            authors {
              name
            }
            coverAsset {
              ...CoverAssetFragment
            }
            headshotCoverAsset {
              url
            }
            wordmarkAsset {
              url
            }
            categories {
              name
              type
            }
            pages {
              ...PagesFragment
            }
            userProgress {
              ...UserProgressFragment
            }
            releases {
              id
              status
              perpetual
              publishedAt
            }
          }
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${PAGES_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
`;

export const GET_RECOMMENDED_QUEST = gql`
  query getRecommendedQuest($first: Int, $orgId: ID!) {
    recommendedB2bQuests(first: $first, orgId: $orgId) {
      edges {
        node {
          quest {
            id
            type
            slug
            name
            description
            language
            authors {
              name
            }
            coverAsset {
              ...CoverAssetFragment
            }
            wordmarkAsset {
              url
            }
            releases {
              id
              status
              perpetual
              publishedAt
            }
            pages {
              ...PagesFragment
            }
            userProgress {
              ...UserProgressFragment
            }
          }
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${PAGES_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
`;

export const GET_QUESTS_FROM_LIBRARY = gql`
  query getQuestsFromLibrary(
    $first: Int
    $status: String
    $membership: QuestMembershipFilterValues
  ) {
    b2bQuests(first: $first, progress: $status, membership: $membership) {
      edges {
        node {
          id
          type
          subtype
          slug
          name
          description
          language
          introsCount
          categories {
            id
            name
          }
          pages {
            ...PagesFragment
          }
          userProgress {
            ...UserProgressFragment
          }
          authors {
            name
          }
          coverAsset {
            ...CoverAssetFragment
          }
          wordmarkAsset {
            url
          }
          trailerAsset {
            ...TrailerAssetFragment
          }
          releases {
            id
            status
            publishedAt
          }
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${PAGES_FRAGMENT}
  ${TRAILER_ASSET_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
`;

export const GET_QUESTS_PUBLIC = gql`
  query getQuestPublic(
    $category: ID
    $membership: QuestMembershipFilterValues = ALL
    $first: Int = 999
    $owned: Boolean
    $language: [String]
  ) {
    quests(
      category: $category
      membership: $membership
      first: $first
      owned: $owned
      language: $language
    ) {
      edges {
        node {
          id
          description
          name
          type
          url
          slug
          language
          trailerAsset {
            ...TrailerAssetFragment
          }
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
          coverAsset {
            ...CoverAssetFragment
          }
          releases {
            id
            status
            perpetual
            publishedAt
          }
          authors {
            name
          }
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${TRAILER_ASSET_FRAGMENT}
`;

export const GET_QUESTS = gql`query getQuests(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $search: String
    $progress: String
    $category: ID
    $upcoming: QuestUpcomingFilterValues
    $outcomes: [ID]
    $owned: Boolean
    $language: [String]
    $meditation: QuestMeditationFilterValues
    $programBrandNames: [String]
    $membership: QuestMembershipFilterValues!
  ) {
    b2bQuests(
      after: $after
      first: $first
      before: $before
      last: $last
      search: $search
      progress: $progress
      category: $category
      upcoming: $upcoming
      outcomes: $outcomes
      owned: $owned
      language: $language
      meditation: $meditation
      programBrandNames: $programBrandNames
      membership: $membership
    ) {
      edges {
        node {
          id
          name
          description
          type
          slug
          language
          categories {
            id
            name
          }
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
          coverAsset {
            ...CoverAssetFragment
          }
          authors {
            name
          }
          releases {
            id
            status
            perpetual
            publishedAt
          }
          trailerAsset {
            renditions {
              contentType
              url
            }
            url
          }
          pages {
            ...PagesFragment
          }
          userProgress {
            ...UserProgressFragment
          }
          trailerAsset {
            ...TrailerAssetFragment
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${PAGES_FRAGMENT}
  ${TRAILER_ASSET_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
`;

export const GET_QUEST_PAGE_BY_ID = gql`
  query getQuestPageById($id: ID!) {
    page(id: $id) {
      id
      name
      type
      groupName
      position
      locked
      completed
      duration
      preview
      quest {
        id
        name
        type
        slug
        language
        owned
        authors {
          name
        }
        userProgress {
          ...UserProgressFragment
        }
        coverAsset {
          ...CoverAssetFragment
        }
        releases {
          id
          status
          perpetual
          publishedAt
        }
        groups {
          id
          locked
          name
          position
          type
          unlockAfterDays
        }
        pages {
          ...PagesFragment
        }
      }
      nextPage {
        id
      }
      url
      coverAsset {
        ...CoverAssetFragment
      }
      tasks {
        id
        type
        name
        position
        required
        completed
      }
      sections {
        ...SectionFragment
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${RENDITIONS_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${PAGES_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
  ${SECTION_FRAGMENT}
`;

export const GET_QUEST_TASKS = gql`
  query getQuestTasks($id: ID!) {
    page(id: $id) {
      tasks {
        id
        name
        position
        required
        completed
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
`;

export const GET_CHANNEL_BY_SLUG = gql`
  query getChannelBySlug(
    $slug: String!
    $highlightStatus: CollectionHighlightStatusFilterEnum!
    $limit: Int!
  ) {
    b2bChannelBySlug(slug: $slug) {
      id
      title
      description
      slug
      coverAsset {
        ...CoverAssetFragment
      }
      trailerLoopingAsset {
        ...TrailerLoopingAssetFragment
      }
      authors {
        name
      }
      collections(limit: $limit, highlightStatus: $highlightStatus) {
        id
        subtitle
        description
        title
        slug
        collectionResources(limit: 200) {
          id
          title
          description
          totalDuration
          ratingsCount
          averageRating
          trailerLoopingAsset {
            ...TrailerLoopingAssetFragment
          }
          ... on Resource {
            ...ResourceFragment
          }
          author {
            name
          }
          coverAsset {
            ...CoverAssetFragment
          }
          landscapeCoverAsset {
            url
            id
          }
        }
        coverAsset {
          ...CoverAssetFragment
        }
        landscapeCoverAsset {
          url
        }
        portraitAsset {
          url
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${RESOURCE_FRAGMENT}
  ${TRAILER_LOOPING_ASSET_FRAGMENT}
`;

export const GET_QUEST_BY_SLUG_AND_LANGUAGE = gql`
  query getQuestBySlugAndLanguage($slugAndLanguage: QuestSlugAndLanguage!) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      type
      name
      description
      slug
      language
      enrollmentsCount
      introsCount
      publishedAt
      duration
      currentAssignment {
        startDate
        endDate
      }
      authors {
        name
        bioTitle
        headline
        avatarAsset {
          url
        }
        bannerAsset {
          url
        }
        metadataTitle
        description
        headline
      }
      categories {
        name
        type
      }
      groups {
        id
        locked
        name
        position
        type
        unlockAfterDays
      }
      userProgress {
        ...UserProgressFragment
      }
      wordmarkAsset {
        url
      }
      owned
      pages {
        ...PagesFragment
      }
      outcomes {
        id
        name
      }
      daysCount
      lessonsCount
      coverAsset {
        ...CoverAssetFragment
      }
      trailerAsset {
        ...TrailerAssetFragment
      }
      trailerCoverAsset {
        url
      }
      releases {
        id
        status
        perpetual
        publishedAt
      }
      nextRelease {
        id
        status
        perpetual
        publishedAt
      }
      url
      productId
      owned
      language
      resources {
        id
        title
        isPartOfMembership
        type
        free
        description
        averageRating
        currentRating
        ratingsCount
        author {
          id
          name
          bioTitle
          headline
          avatarAsset {
            url
          }
        }
        mediaAsset {
          id
          contentType
          url
          duration
          sizeOfFile
          renditions(labels: ["hls", "mp3", "ogg"]) {
            ...RenditionsFragment
          }
        }
        supportingResource {
          id
          type
          subtype
          averageRating
          title
          description
          author {
            id
            name
          }
          coverAsset {
            ...CoverAssetFragment
          }
          mediaAsset {
            id
            url
            duration
            renditions(labels: ["hls", "mp3", "ogg"]) {
              ...RenditionsFragment
            }
          }
        }
        totalDuration
        description
        coverAsset {
          ...CoverAssetFragment
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${RENDITIONS_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${PAGES_FRAGMENT}
  ${TRAILER_ASSET_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
`;

export const GET_QUEST_TRAILER_BY_SLUG_AND_LANGUAGE = gql`
  query getQuestTrailerBySlugAndLanguage(
    $slugAndLanguage: QuestSlugAndLanguage!
  ) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      trailerAsset {
        ...TrailerAssetFragment
      }
    }
  }
  ${TRAILER_ASSET_FRAGMENT}
`;

export const GET_QUEST_USER_PROGRESS = gql`
  query getQuestUserProgress($slugAndLanguage: QuestSlugAndLanguage!) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      userProgress {
        ...UserProgressFragment
      }
    }
  }
  ${USER_PROGRESS_FRAGMENT}
`;

export const GET_CURRENT_PROFILE = gql`
  query getCurrentProfile {
    currentProfile {
      firstName
      lastName
      email
      role
      spokenLanguages {
        displayName
        iso2Code
      }
      country
      location
      industry
      avatarUrl
      tags
      bio
      userLevel {
        level
      }
    }
  }
`;

export const GET_LANGUAGES = gql`
  query getLanguages($first: Int, $type: LanguageType) {
    languages(first: $first, type: $type) {
      edges {
        node {
          id
          iso2Code
          displayName
        }
      }
      totalCount
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories {
    categories(first: 100, type: "b2b") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_QUEST_BY_ID = gql`
  query getQuestById($id: ID!) {
    quest(id: $id) {
      id
      description
      name
      type
      url
      slug
      authors {
        name
        avatarAsset {
          url
        }
      }
      headshotCoverAsset {
        url
      }
      outcomes {
        name
      }
      trailerAsset {
        id
      }
      wordmarkAsset {
        url
      }
      type
      daysCount
      lessonsCount
      duration
      averageRating
      coverAsset {
        ...CoverAssetFragment
      }
      resources {
        mediaAsset {
          id
          url
          contentType
        }
      }
      categories {
        name
        type
      }
      pages {
        position
        type
        sections {
          type
          primaryAsset {
            url
          }
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${TRAILER_ASSET_FRAGMENT}
`;

export const GET_FREE_QUESTS = gql`
  query getFreeQuests($first: Int, $owned: Boolean, $language: [String]) {
    b2bQuests(first: $first, owned: $owned, language: $language) {
      edges {
        node {
          id
          releases {
            id
          }
          type
          name
          description
          enrollmentStartedAt
          courseStartedAt
          courseEndedAt
          slug
          language
          pages {
            ...PagesFragment
          }
          userProgress {
            ...UserProgressFragment
          }
          url
          authors {
            name
          }
          coverAsset {
            ...CoverAssetFragment
          }
          resources {
            mediaAsset {
              id
              url
              thumbnailUrl
              duration
            }
          }
          groups {
            id
            locked
            name
            position
            type
            unlockAfterDays
          }
          trailerAsset {
            ...TrailerAssetFragment
          }
          wordmarkAsset {
            url
          }
        }
      }
      totalCount
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
  ${TRAILER_ASSET_FRAGMENT}
  ${PAGES_FRAGMENT}
`;

export const GET_QUESTS_ASSIGMENTS = gql`
  query getQuestsAssignments($first: Int) {
    currentWorkwayQuestAssignments(first: $first) {
      edges {
        node {
          id
          startDate
          endDate
          createdAt
          quest {
            authors {
              name
            }
            description
            url
            slug
            language
            enrollmentStartedAt
            courseStartedAt
            courseEndedAt
            type
            coverAsset {
              ...CoverAssetFragment
            }
            id
            name
            wordmarkAsset {
              url
            }
            pages {
              ...PagesFragment
            }
            userProgress {
              ...UserProgressFragment
            }
          }
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${PAGES_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
`;

export const RECOMMENDED_B2B_MEDITATIONS_POPULAR_IN_THE_ORG = gql`
  query recommendedB2bMeditationsPopularInTheOrg($first: Int, $orgId: ID!) {
    recommendedB2bMeditationsPopularInTheOrg(first: $first, orgId: $orgId) {
      edges {
        node {
          meditation {
            ...ResourceFragment
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
`;

export const RECOMMENDED_B2B_SOUNDSCAPE_POPULAR_IN_THE_ORG = gql`
  query recommendedB2bSoundscapesPopularInTheOrg($first: Int, $orgId: ID!) {
    recommendedB2bSoundscapesPopularInTheOrg(first: $first, orgId: $orgId) {
      edges {
        node {
          soundscape {
            ...ResourceFragment
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
`;

export const QUEST_BY_PROGRESS = gql`
  query getQuestsByProgress(
    $progress: String
    $first: Int
    $type: ResourceTypeFilterEnum!
  ) {
    b2bQuests(progress: $progress, first: $first) {
      edges {
        node {
          id
          name
          description
          coverAsset {
            ...CoverAssetFragment
          }
          authors {
            avatarAsset {
              url
            }
          }
          questResources(type: $type) {
            id
            title
            description
            totalDuration
            coverAsset {
              ...CoverAssetFragment
            }
            author {
              name
            }
            ...ResourceFragment
          }
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${RESOURCE_FRAGMENT}
`;

export const GET_RECOMMENDED_HARMONIZED_MEDITATIONS = gql`
  query getRecommendedHarmonizedMeditations(
    $first: Int
    $orgId: ID!
    $limit: String
  ) {
    recommendedB2bHarmonizedMeditation(
      first: $first
      orgId: $orgId
      limit: $limit
    ) {
      edges {
        node {
          soundscape {
            id
            title
            description
            author {
              name
            }
            coverAsset {
              ...CoverAssetFragment
            }
            trailerLoopingAsset {
              ...TrailerLoopingAssetFragment
            }
            landscapeCoverAsset {
              url
            }
            averageRating
            ratingsCount
            ...ResourceFragment
          }
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${RESOURCE_FRAGMENT}
  ${TRAILER_LOOPING_ASSET_FRAGMENT}
`;

export const GET_COLLECTIONS = gql`
  query getb2bCollections(
    $channelIdentifiers: [String!]!
    $status: SeriesPublishStatusFilterEnum!
    $first: Int
    $limit: Int!
    $resourceFeaturedStatus: ResourceFeaturedStatusFilterEnum!
    $resourceStatus: ResourceStatusFilterEnum!
    $type: ResourceTypeFilterEnum!
    $sortBy: ResourceSortByEnum!
  ) {
    b2bCollections(
      channelIdentifiers: $channelIdentifiers
      first: $first
      status: $status
    ) {
      edges {
        node {
          id
          title
          description
          slug
          landscapeCoverAsset {
            url
          }
          coverAsset {
            ...CoverAssetFragment
          }
          collectionResources(
            limit: $limit
            type: $type
            featuredStatus: $resourceFeaturedStatus
            status: $resourceStatus
            sortBy: $sortBy
          ) {
            id
            title
            description
            totalDuration
            author {
              name
            }
            coverAsset {
              ...CoverAssetFragment
            }
            trailerLoopingAsset {
              ...TrailerLoopingAssetFragment
            }
            ...ResourceFragment
          }
        }
      }
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${RESOURCE_FRAGMENT}
  ${TRAILER_LOOPING_ASSET_FRAGMENT}
`;
export const GET_MEDITATION_OF_THE_DAY = gql`
  query recommendedB2bMeditationsOfTheDay($first: Int, $orgId: ID!) {
    recommendedB2bMeditationsOfTheDay(first: $first, orgId: $orgId) {
      edges {
        node {
          meditation {
            id
            title
            description
            author {
              name
            }
            coverAsset {
              ...CoverAssetFragment
            }
            landscapeCoverAsset {
              url
            }
            ...ResourceFragment
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
  ${RESOURCE_FRAGMENT}
`;

export const GET_SOUNDSCAPE_OF_THE_WEEK = gql`
  query recommendedB2bSoundscapesOfTheWeek($first: Int, $orgId: ID!) {
    recommendedB2bSoundscapesOfTheWeek(first: $first, orgId: $orgId) {
      edges {
        node {
          soundscape {
            id
            title
            description
            author {
              name
            }
            coverAsset {
              ...CoverAssetFragment
            }
            landscapeCoverAsset {
              url
            }
            ...ResourceFragment
          }
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
  ${RESOURCE_FRAGMENT}
`;

export const GET_RESOURCES_BY_TYPE = gql`
  query getResourcesByType(
    $language: String!
    $first: Int
    $after: String
    $type: ResourceTypeFilterEnum!
  ) {
    standaloneResources(
      language: $language
      first: $first
      after: $after
      type: $type
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ResourceFragment
        }
      }
    }
  }
  ${RESOURCE_FRAGMENT}
`;

export const GET_RESOURCE_BY_ID = gql`
  query getResourceById($id: ID!) {
    standaloneResource(id: $id) {
      id
      type
      title
      description
      author {
        name
      }
      coverAsset {
        ...CoverAssetFragment
      }
      averageRating
      ...ResourceFragment
    }
  }
  ${COVER_ASSET_FRAGMENT}
  ${RESOURCE_FRAGMENT}
`;
