import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import { GET_RECOMMENDED_HARMONIZED_MEDITATIONS } from "services/graphql/queries/dsApiQueries";

export default function useHarmonizedMeditation({ limit = 7 }) {
  const { organizationData } = useUserState();

  const { data, loading, error } = useQuery(
    GET_RECOMMENDED_HARMONIZED_MEDITATIONS,
    {
      variables: {
        limit,
        orgId: organizationData?.id?.toString() || "",
      },
      // fetchPolicy: "no-cache", // TODO:: There's caching issue with this query
      context: {
        clientName: "rest",
      },
    }
  );

  return {
    data: data?.recommendedB2bHarmonizedMeditation || [],
    loading,
    error,
  };
}
