import { h, defineComponent, type PropType } from "vue";
import {
  MVVideoResource,
  MVVideoPlayer,
} from "@mindvalley/mv-universal-player";
import type { Rendition, Marker, Asset } from "types/__generated__/graphql";

// TEMPORARY FIX TO MAP THE MIME TYPES
const ALLOWED_MIME_TYPES = [
  "application/x-mpegURL",
  "video/mp4",
  "audio/mpeg",
  "audio/ogg",
];

export type VideoPlayerType = {
  id: string;
  mediaAsset: Asset | null;
  coverAsset?: Asset;
  title?: string;
  author?: string;
};

export default defineComponent({
  name: "QuestConsumptionVideoResourcePlayerWrapper",
  components: {
    MVVideoResource,
    MVVideoPlayer,
  },
  props: {
    media: {
      type: Object as PropType<VideoPlayerType>,
      required: true,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    handleOnPlay: {
      type: Function as PropType<(...args: any[]) => any>,
      required: false,
      default: () => {},
    },
    handleOnPause: {
      type: Function as PropType<(...args: any[]) => any>,
      required: false,
      default: () => {},
    },
  },
  methods: {
    formatSources(localSources: Array<Rendition | null> = []) {
      return (localSources || [])
        .filter(
          (source): source is Rendition =>
            !!source &&
            !!source.contentType &&
            ALLOWED_MIME_TYPES.includes(source.contentType)
        )
        .map((source) => ({
          type: source.contentType,
          src: source.url || "",
        }));
    },
    formatMarkers(markers: any = []) {
      if (!markers || !Array.isArray(markers)) return [];
      return [...markers]
        .filter(
          (marker) =>
            marker &&
            typeof marker === "object" &&
            marker.id &&
            marker.name &&
            typeof marker.time === "number"
        )
        .sort((a, b) => a.time - b.time)
        .map((marker) => ({
          id: marker.id,
          name: marker.name,
          time: marker.time,
          status: marker.status || null,
        }));
    },
  },

  render() {
    console.log("media", this.media);

    return h(
      MVVideoPlayer,
      {
        muted: this.muted,
      },
      {
        default: () =>
          h(MVVideoResource, {
            id: this.media.id,
            title: this.media.title,
            author: this.media.author,
            sources: this.formatSources(this.media.mediaAsset?.renditions),
            posterUrl: this.media.coverAsset?.url || "",
            duration: this.media.mediaAsset?.duration || 0,
            markers: this.formatMarkers(this.media.mediaAsset?.markers),
            autoplay: this.autoplay,
            onPlay: () => this.handleOnPlay(),
            onPause: () => this.handleOnPause(),
          }),
      }
    );
  },
});
