import { Button } from "components/Button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "components/Dialog";
import Icons from "components/icons/Icons";
import type { B2bQuest } from "types/__generated__/graphql";

export const EnrollmentCongratsModal = ({
  quest,
  open,
  onClose,
}: {
  quest: B2bQuest;
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogTitle className="hidden">Congratulations</DialogTitle>
      <DialogDescription className="hidden">
        You're enrolled in {quest.name}
      </DialogDescription>
      <DialogContent className="fixed flex items-center justify-center p-4 lg:p-10 z-[70] w-[360px] lg:w-[460px] rounded-[16px] overflow-hidden">
        <div className="absolute">
          <img
            src="/patterns/pattern-congrats-card.svg"
            alt="pattern"
            className="size-full [mask-image:linear-gradient(to_right,transparent,rgba(0,0,0,0.5))]"
          />
        </div>

        <div className="relative w-full rounded-2xl">
          <div className="grid gap-4">
            <div className="grid gap-3">
              <div className="heading-3">Congratulations</div>
              <div className="body">You're enrolled in</div>
              <div className="flex gap-3 flex-col items-start">
                {quest.wordmarkAsset?.url ? (
                  <img
                    src={quest.wordmarkAsset?.url}
                    className="invert max-h-[80px] max-w-[300px] lg:max-h-[96px] lg:max-w-[380px] "
                    alt="Quest Wordmark"
                  />
                ) : (
                  <p className="heading-3">{quest.name}</p>
                )}
                {quest.authors && quest.authors.length > 0 && (
                  <p className="title-7 w-full">with {quest.authors[0].name}</p>
                )}
              </div>
            </div>
            <div className="w-full h-px bg-black-12a" />
            <div className="flex">
              <p className="body-small">
                Get started learning at your own pace
              </p>
              <Button
                type="button"
                id="leave-program-button"
                className="w-[165px]"
                onClick={onClose} // It just placcebo action
              >
                <Icons
                  name="play-filled"
                  width={20}
                  height={20}
                  className="mr-1.5"
                />
                <p>Start now</p>
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
