import Hls from "hls.js";
import { useEffect, useRef } from "react";
import { cn } from "libs/classMerger";

interface VideoProps {
  src: string;
  posterUrl?: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  className?: string;
}

export const VideoPlayer = ({
  src,
  posterUrl,
  autoPlay,
  loop = false,
  muted = false,
  className,
}: VideoProps) => {
  const videoPlayerRef = useRef<HTMLVideoElement | null>(null);
  const hlsRef = useRef<Hls | null>(null);

  useEffect(() => {
    const video = videoPlayerRef.current;

    if (!video) {
      console.error("Video element is not available.");
      return;
    }

    // Cleanup existing HLS instance if any
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }

    /**
     * Initialize HLS.js for HLS streaming
     */
    const initializeHls = () => {
      if (Hls.isSupported()) {
        const hls = new Hls({
          enableWorker: true, // Enable separate thread for HLS parsing
          lowLatencyMode: true, // Enable low-latency mode
        });

        hlsRef.current = hls;

        // Load the HLS source
        hls.loadSource(src);
        hls.attachMedia(video);

        // Handle HLS manifest parsing
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log("HLS manifest parsed successfully.");
          if (autoPlay) {
            video.play().catch((error) => {
              console.error("Auto-play was prevented:", error);
            });
          }
        });

        // Handle HLS errors
        hls.on(Hls.Events.ERROR, (_, data) => {
          console.error("HLS error:", data);
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                console.error("HLS network error, retrying...");
                hls.startLoad(); // Retry loading
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.error("HLS media error, recovering...");
                hls.recoverMediaError(); // Attempt to recover
                break;
              default:
                console.error("Fatal HLS error, destroying instance...");
                hls.destroy(); // Destroy HLS instance
                break;
            }
          }
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Native HLS support (e.g., Safari)
        console.log("Using native HLS support.");
        video.src = src;
        video.load();
        if (autoPlay) {
          video.play().catch((error) => {
            console.error("Auto-play was prevented:", error);
          });
        }
      } else {
        console.error("HLS is not supported in this browser.");
      }
    };

    initializeHls();

    // Cleanup HLS instance on unmount
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
  }, [src, autoPlay]);

  return (
    <video
      ref={videoPlayerRef}
      poster={posterUrl}
      muted={muted}
      loop={loop}
      className={cn(
        "aspect-video transition-opacity duration-500 ease-in-out round",
        className
      )}
      autoPlay={autoPlay}
      playsInline
      controlsList="nodownload"
      controls={false}
      aria-label="Video player"
    >
      Your browser does not support the video element.
    </video>
  );
};
