import { DialogTitle } from "@fluentui/react-components";
import { Button } from "components/Button";
import { Dialog, DialogContent } from "components/Dialog";
import type React from "react";
import type { LegacyRef } from "react";

interface ConfirmationModalProps {
  authorName?: string;
  modalRef: LegacyRef<HTMLDivElement>;
  onConfirmation: () => void;
  onClose: () => void;
  questName?: string;
  type: ConfirmationModalType;
  url?: string;
}

export enum ConfirmationModalType {
  Reset = "Reset",
  Leave = "Leave",
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  type,
  questName,
  url,
  authorName,
  onClose,
  onConfirmation,
  modalRef,
}) => {
  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent
        ref={modalRef}
        className="fixed flex items-center justify-center p-4 lg:p-10 z-[70] w-[360px] lg:w-[460px] bg-[url('https://cdn-assets.workway.app/images/quest_options_background-3f074f1151c6dfe2a064d6d34f22a344.webp?vsn=d')]"
      >
        <DialogTitle className="hidden">Confirmation Modal</DialogTitle>
        <div className="relative w-full rounded-2xl">
          <div className={"flex gap-4 flex-col"}>
            {type === ConfirmationModalType.Leave ? (
              <p className={"heading-7"}>Are you sure you want to leave</p>
            ) : (
              <p className={"heading-7"}>
                Are you sure you want to reset your progress for
              </p>
            )}
            <div className={"flex gap-3 flex-col items-start"}>
              {url ? (
                <img
                  src={url}
                  className={
                    "invert max-h-[80px] max-w-[300px] lg:max-h-[96px] lg:max-w-[380px] "
                  }
                  alt="Quest Wordmark"
                />
              ) : (
                <p className={"heading-3"}>{questName || "Quest"}</p>
              )}
              {authorName && (
                <p className={"title-8 w-full"}>with {authorName}</p>
              )}
            </div>

            <div className={"flex gap-1"}>
              <Button
                type="button"
                id="leave-program-button"
                onClick={onConfirmation}
              >
                <p>
                  Yes,{" "}
                  {type === ConfirmationModalType.Leave ? "leave" : "reset"}{" "}
                  now
                </p>
              </Button>
              <Button
                variant={"link"}
                type="button"
                id="cancel-button"
                onClick={onClose}
              >
                <p>Cancel</p>
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ConfirmationModal;
