import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import { cn } from "libs/classMerger";
import * as React from "react";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-full button-text-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary:
          "bg-red-600 hover:bg-gradient-to-b from-red-400 to-red-600 text-white",
        neutral: "bg-cool-grey-700 hover:bg-cool-grey-600 text-white",
        neutralLight:
          "bg-black-4a text-cool-grey-700 enabled:hover:bg-black-6a enabled:hover:text-black disabled:bg-black-4a disabled:text-black-18a",
        secondary:
          "bg-cool-grey-300 text-secondary-foreground hover:bg-secondary/80",
        tertiary: "text-red-600 hover:bg-red-4a",
        dropdown: "!caption-disclaimer bg-brown-100 border border-brown-600",
        // destructive:
        //   "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "w-fit p-2 rounded-lg border border-black-12a text-cool-grey-600 hover:bg-brown-100 data-[selected=true]:bg-brown-800 data-[selected=true]:text-white data-[selected=true]:hover:bg-brown-700",
        ghost:
          "button-text text-cool-grey-700 py-3.5 px-6 rounded-100 cursor-pointer text-center group-hover:bg-black-4a hover:bg-black-4a",
        gostLink: "text-cool-grey-700 hover:text-red-600",
        link: "text-red-600",
        "caption-disclaimer": "caption-disclaimer",
        icon: "",
        roundedIcon: "rounded-full bg-brown-12a",
        inherit: "inherit",
      },
      size: {
        default: "h-10 px-3.5 py-3",
        sm: "py-1.5 px-3",
        xs: "py-2 px-3",
        icon: "p-0",
        roundedIcon: "p-0 size-9",
        link: "p-0",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
