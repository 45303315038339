// @ts-ignore
import {
  useMemo,
  useState,
  Fragment,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
} from "react";
import "../Programs.css";
import { Button } from "components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/Dropdown";
import Icons from "components/icons/Icons";

export const LanguagePicker = ({
  languages,
  selectedLang,
  setSelectedLang,
}: {
  languages: any;
  selectedLang: any;
  setSelectedLang: (arg0: any) => void;
}) => {
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  const handleSelectLanguage = (code: string) => {
    setSelectedLang(code);
    if (code !== selectedLang) setIsLanguageMenuOpen(false);
  };

  return (
    <div className="flex items-center justify-end gap-x-2 w-4/12">
      <div className="text-cool-grey-400 heading-9">BY LANGUAGE</div>
      <div>
        <DropdownMenu
          open={isLanguageMenuOpen}
          onOpenChange={setIsLanguageMenuOpen}
        >
          <DropdownMenuTrigger asChild>
            <Button
              asChild
              variant="dropdown"
              className="p-1.5 rounded-lg bg-brown-50 cursor-pointer hover:bg-brown-100 w-[130px] border-none"
            >
              <div className="flex justify-between items-center px-1.5 py-1 !text-cool-grey-600">
                <div className="!title-8">
                  {
                    languages.find(
                      (lang: { iso2Code: string }) =>
                        lang.iso2Code === selectedLang
                    )?.label
                  }
                </div>
                <Icons name="chevron-down-filled" height={20} width={20} />
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            sideOffset={-40}
            className="rounded-lg shadow-lg bg-brown-50 p-2 space-y-1.5 w-[280px] border-none"
          >
            {languages.map(
              (language: { iso2Code: string; label: string }, idx: number) => (
                <Fragment key={language.iso2Code}>
                  {idx === 1 && <div className="h-px w-full bg-black-8a" />}
                  <DropdownMenuItem
                    data-selected={language.iso2Code === selectedLang}
                    className="p-2 flex justify-between items-center self-stretch rounded-lg cursor-pointer hover:bg-brown-100"
                    onSelect={(event) => {
                      event.preventDefault();
                      handleSelectLanguage(language.iso2Code ?? "");
                    }}
                  >
                    <p className="text-cool-grey-600 title-8">
                      {language.label}
                    </p>
                    {language.iso2Code === selectedLang && (
                      <Icons
                        name="check-filled"
                        height={20}
                        width={20}
                        fill="currentColor"
                      />
                    )}
                  </DropdownMenuItem>
                </Fragment>
              )
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
