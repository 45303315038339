import { useState, useEffect, useMemo } from "react";
import {
  type Resource,
  useGetResourcesByTypeLazyQuery,
  ResourceTypeFilterEnum,
} from "types/__generated__/graphql";
import type { BackgroundSound } from "@mindvalley/mv-universal-player/dist/types/adaptive";

function useMixers() {
  const [mixers, setMixers] = useState<Resource[]>([]);
  const [fetchMixers, { data, loading, error }] =
    useGetResourcesByTypeLazyQuery({
      onError: (error) => console.error("Error fetching mixers:", error),
    });

  useEffect(() => {
    fetchMixers({
      variables: {
        language: "all",
        first: 30,
        type: ResourceTypeFilterEnum.Sound,
      },
      fetchPolicy: "no-cache", // TODO:: Got cache issue, need to fix it
    });
  }, [fetchMixers]);

  useEffect(() => {
    if (data?.standaloneResources?.edges) {
      const newMixers = data.standaloneResources.edges
        .map((edge) => edge.node)
        .filter((node): node is Resource => Boolean(node));
      setMixers(newMixers);
    }
  }, [data]);

  const modifiedData = useMemo(
    () =>
      mixers.map((mixer) => {
        return {
          id: mixer.id,
          title: mixer.title,
          image: mixer.coverAsset?.url,
          sources: mixer.mediaAsset?.renditions?.map((rendition) => ({
            type: rendition.contentType,
            src: rendition.url,
          })),
        };
      }) as BackgroundSound[],
    [mixers]
  );

  const refresh = () => {
    return fetchMixers({
      variables: {
        language: "all",
        first: 30,
        type: ResourceTypeFilterEnum.Sound,
      },
      fetchPolicy: "network-only",
    });
  };

  return {
    data: modifiedData,
    loading,
    error,
    refresh,
  };
}

export default useMixers;
