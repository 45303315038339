import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/Tab";
import Icons from "components/icons/Icons";
import { SidebarLessonTabContent } from "./SidebarLessonTabContent";
import "./GuideContent.css";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarGuideTabContent } from "./SidebarGuideTabContent";
import type { Task, B2bQuest, Page, Media } from "types/__generated__/graphql";

const SidebarTabs = ({
  page,
  onLessonSelect,
  onToggleTask,
  handleResourcePlay,
}: {
  page?: Page;
  onLessonSelect: (page: Page) => void;
  onToggleTask: (task: Task) => void;
  handleResourcePlay: (media: any) => void;
}) => {
  return (
    <div className="flex items-center gap-6 lg:pt-6 lg:bg-transparent border-b border-cool-grey-300">
      <Tabs defaultValue="guide" className="w-full">
        <TabsList className="flex flex-row gap-x-6 border-b border-cool-grey-300 justify-start px-4 pb-0 sticky top-0 bg-white lg:bg-brown-50 h-11.5 rounded-none">
          <TabsTrigger
            className="rounded-none border-x-0 flex w-1/2 lg:w-auto gap-x-2 flex-row items-center pb-4 justify-center lg:justify-start text-cool-grey-500 border-0 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 data-[state=active]:text-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
            value="guide"
          >
            <div className="flex gap-[6px] justify-between items-center">
              <Icons name="compass-outlined" />
              <div className="title-8">Guide</div>
            </div>
          </TabsTrigger>
          <TabsTrigger
            className="rounded-none border-x-0 flex w-1/2 lg:w-auto gap-x-2 flex-row items-center pb-4 justify-center lg:justify-start text-cool-grey-500 border-0 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 data-[state=active]:text-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
            value="lessons"
          >
            <div className="flex gap-[6px] items-center">
              <Icons name="book-open-outlined" />
              <div className="title-8">Lessons</div>
            </div>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="guide">
          <div className="lg:h-dvh lg:overflow-y-auto">
            <SidebarGuideTabContent
              page={page}
              onToggleTask={onToggleTask}
              handleResourcePlay={handleResourcePlay}
            />
          </div>
        </TabsContent>
        <TabsContent value="lessons">
          <div className="lg:h-dvh lg:overflow-y-auto">
            <SidebarLessonTabContent
              page={page}
              onLessonSelect={onLessonSelect}
            />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

const QuestConsumptionSideBar = ({
  page,
  onLessonSelect,
  onStartProgram,
  onToggleTask,
  handleResourcePlay,
  onLandinPageOpen,
}: //
{
  page?: Page;
  onLessonSelect: (page: Page) => void;
  onStartProgram: () => void;
  onToggleTask: (task: Task) => void;
  handleResourcePlay: (media: Media) => void;
  onLandinPageOpen?: () => void;
}) => {
  return (
    <aside className="xl:w-[520px] lg:w-[460px] md:w-[360px] lg:bg-brown-50 overflow-y-auto relative flex-1 lg:flex-none pb-[50px] lg:pb-0">
      <SidebarHeader
        quest={page?.quest as B2bQuest}
        onStartProgram={onStartProgram}
        onLandinPageOpen={onLandinPageOpen}
      />
      <SidebarTabs
        page={page}
        onLessonSelect={onLessonSelect}
        onToggleTask={onToggleTask}
        handleResourcePlay={handleResourcePlay}
      />
    </aside>
  );
};

export default QuestConsumptionSideBar;
