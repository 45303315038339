import { Button } from "components/Button";
import { ProgressBar } from "components/ProgressBar";
import { SheetClose } from "components/Sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/Tabs";
import Icons from "components/icons/Icons";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssignedBanner } from "tabs/Home/components/AssignedBanner";
import ConfirmationModal, {
  ConfirmationModalType,
} from "tabs/Quest/QuestLanding/ConfirmationModal";
import { getNextQuestNavigationUrl } from "utils/navigation";
import { convertSecondsToReadableFormat } from "utils/time";
import { Lessons } from "./Lessons";
import { Overview } from "./Overview";
import { Resources } from "./Resources";
import { Stories } from "./Stories";
import useQuestMutations from "hooks/useQuestMutations";
import { MediaPlayer, type MediaPlayerHandle } from "components/MediaPlayer";
import { getRendition } from "utils/mediaHelpers";
import { getQuestContinueButtonText } from "utils/textHelpers";
import { cn } from "libs/classMerger";
import {
  useGetQuestBySlugAndLanguageQuery,
  type B2bQuest,
} from "types/__generated__/graphql";
import Preloader from "components/loading/Preloader";

export const QuestLandingModal = ({
  preloadQuestData,
  questArgs,
}: {
  preloadQuestData?: B2bQuest;
  questArgs: {
    slug?: string;
    language?: string;
  };
}) => {
  const navigate = useNavigate();

  const {
    data,
    loading,
    error,
    refetch: refetchQuest,
  } = useGetQuestBySlugAndLanguageQuery({
    variables: {
      slugAndLanguage: {
        slug: questArgs.slug || "",
        language: questArgs.language || "",
      },
    },
    // fetchPolicy: "no-cache", // TODO:: normalize cache issue
  });

  const quest = data?.b2bQuestBySlugAndLanguage as B2bQuest;

  const { handleStartQuest, handleLeaveQuest } = useQuestMutations(
    quest,
    refetchQuest
  );

  const mediaPlayerRef = useRef<MediaPlayerHandle | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [showCaptions, setShowCaptions] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [confirmationModalType, setConfirmationModalType] =
    useState<ConfirmationModalType | null>(null);

  const handleStartProgram = async () => {
    await handleStartQuest({
      questId: quest.id,
      releaseId: quest.releases?.[0]?.id,
    });
  };

  const handleLeaveProgram = async () => {
    await handleLeaveQuest(quest);
  };

  const togglePlay = () => {
    if (isPlaying) {
      mediaPlayerRef.current?.pause();
    } else {
      mediaPlayerRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    mediaPlayerRef.current?.mute(!isMuted);
    setIsMuted(!isMuted);
  };

  // const toggleCaptions = () => {
  //   mediaPlayerRef.current?.toggleCaptions(!showCaptions);
  //   setShowCaptions(!showCaptions);
  // };

  const handleFullScreen = () => {
    // mediaPlayerRef.current?.enterFullScreen();
  };

  const getMaxHeightAndWidth = () => {
    return quest.userProgress?.started
      ? "max-h-12 max-w-[200px]"
      : "max-h-20 max-w-80";
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      optionsRef.current?.contains(event.target as Node) ||
      modalRef.current?.contains(event.target as Node)
    ) {
      return;
    }

    setShowOptions(false);
    setConfirmationModalType(null);
    document.removeEventListener("mousedown", handleClickOutside);
  };

  const closeModal = () => {
    setShowOptions(false);
    setConfirmationModalType(null);
  };

  const onConfirmation = () => {
    if (confirmationModalType === ConfirmationModalType.Leave)
      handleLeaveProgram();
    setShowOptions(false);
    setConfirmationModalType(null);
  };

  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
    if (!showOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const getCompletedCount = () =>
    quest.type === "weekly"
      ? quest.userProgress?.totalLessonsCompleted
      : quest.userProgress?.totalDaysCompleted;

  const getTotalCount = () =>
    quest.type === "weekly"
      ? quest.userProgress?.totalLessons
      : quest.userProgress?.totalDays;

  const renditionUrl = getRendition(quest?.trailerAsset?.renditions, [
    "application/x-mpegURL",
    "video/mp4",
  ]);

  const handleExporeSimilarPrograms = () => {
    navigate(`/programs?category=${quest.categories?.[0]?.id}`);
  };

  if (!data || loading)
    return <Preloader showWhiteLogo={false} autoRedirect={false} />;

  return (
    <div id="quest-landing-modal-content">
      <div className="md:relative bg-black">
        <MediaPlayer
          key={`${quest.id}-media-player`}
          ref={mediaPlayerRef}
          mediaUrl={renditionUrl?.src || quest?.trailerAsset?.url || ""}
          thumbnailUrl={quest.coverAsset?.url || ""}
          autoplay={true}
          muted={true}
          looped
          showVControls={false}
          // playOnHover={true}
          // pauseOnLeave={true}
          playerClasses="aspect-auto relative"
        />
        <div
          className={cn(
            "md:absolute inset-x-0 bottom-0 md:py-8 py-6 md:px-9 px-4 md:pb-8 pb-6 bg-black-90a bg-transparent pt-6 md:bg-gradient-to-t md:from-black/100 md:via-black/52 md:to-transparent`",
            quest.currentAssignment ? "mb-0 md:mb-8" : ""
          )}
        >
          {!quest.userProgress?.started && (
            <div className="flex items-center gap-2 md:pb-4 pb-3">
              <p className="heading-7  text-white-70a h-55">Quest</p>
              <div className="h-55 flex items-center justify-center">
                <div className="size-[4px] rounded-full bg-white-70a" />
              </div>
              <p className="heading-7 text-white-70a h-55 lowercase">
                {quest.categories
                  ?.find((c) => c.type === "b2b")
                  ?.name?.replace("-B2B", "") ?? ""}
              </p>
            </div>
          )}
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row gap-0 md:gap-6 items-start md:items-center space-y-3 ">
              {quest.wordmarkAsset?.url ? (
                <img
                  src={quest.wordmarkAsset.url}
                  className={`${getMaxHeightAndWidth()} lg:max-w-[360px] h-auto w-auto`}
                  alt="Quest Wordmark"
                />
              ) : (
                <p
                  className={`${
                    quest.userProgress?.started
                      ? "heading-7 max-w-[320px]"
                      : "heading-2 max-w-[680px]"
                  } text-white`}
                >
                  {quest.name}
                </p>
              )}
              {quest.userProgress?.started && (
                <div className="flex gap-4 items-center w-[100%]">
                  <ProgressBar
                    progressClasses="!bg-yellow-500"
                    value={getCompletedCount()}
                    maxValue={getTotalCount()}
                  />
                  <p className="title-8 text-white text-nowrap">
                    {getCompletedCount()} / {getTotalCount()} completed
                  </p>
                  {quest.userProgress?.completed && (
                    <p className="title-8">🎉</p>
                  )}
                </div>
              )}
            </div>
            {quest.userProgress?.started && !quest.userProgress?.completed && (
              <>
                <p className="heading-6 text-white mt-4">
                  {quest.userProgress.resumePage?.name}
                </p>
                <p className="caption-disclaimer text-cool-grey-400 mt-1">
                  {quest.userProgress.resumePage &&
                    convertSecondsToReadableFormat(
                      quest.userProgress.resumePage.duration
                    )}
                </p>
              </>
            )}
            {quest.userProgress?.completed && (
              <p className="heading-5 text-white mt-3.5">
                Congratulations on completing the program.
              </p>
            )}
            {!quest.userProgress?.started && (
              <p className="title-7 text-cool-grey-300 mt-3">
                with {quest.authors?.[0]?.name ?? ""}
              </p>
            )}
            <div className="flex items-center mt-5 gap-1.5">
              {quest.userProgress?.completed ? (
                <div
                  className={
                    "flex flex-col md:flex-row gap-x-1.5 gap-y-2 w-full"
                  }
                >
                  <Button
                    type="button"
                    id="landing-modal-explore-program-button"
                    onClick={handleExporeSimilarPrograms}
                    className="flex rounded-full items-center justify-center enabled:cursor-pointer text-center focus:outline-none bg-red-600 text-white enabled:hover:gradient-red-wave-top-down disabled:bg-black-8a disabled:text-black-18a button-text-medium h-10 px-3.5 py-3 md:w-fit"
                  >
                    <p className="px-1.5">Explore similar programs</p>
                  </Button>
                </div>
              ) : (
                <div className={"flex gap-x-1.5 w-full"}>
                  {!quest.userProgress?.started && (
                    <Button
                      variant={"primary"}
                      onClick={handleStartProgram}
                      className={"w-full lg:w-[234px]"}
                    >
                      <Icons
                        name="play-filled"
                        height={20}
                        width={20}
                        fill="#fff"
                      />
                      <p className="px-1.5">Start program</p>
                    </Button>
                  )}
                  {quest.userProgress?.started && (
                    <Button
                      variant={"primary"}
                      onClick={() =>
                        navigate(getNextQuestNavigationUrl({ quest }))
                      }
                      className={"w-full lg:w-[234px]"}
                    >
                      <Icons
                        name="play-filled"
                        height={20}
                        width={20}
                        fill="#fff"
                      />
                      <p className="px-1.5">
                        {getQuestContinueButtonText(quest)}
                      </p>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {quest.currentAssignment && (
          <AssignedBanner
            assignment={quest.currentAssignment}
            className="bg-brown-900"
          />
        )}
      </div>
      <div className="flex flex-col absolute top-0 w-full">
        <div className="flex md:px-10 px-4 md:py-4 py-2 w-full justify-between">
          <SheetClose asChild>
            <button
              type="button"
              className="flex items-center justify-between md:px-3 px-2 inset-x-0 md:py-1.5 py-1.5 text-center text-white"
              data-testid="quest-back-button"
            >
              <Icons
                name="arrow-left-circle-outlined"
                width={24}
                height={24}
                fill="#FFFFFF"
              />
              <h3 className="px-1 button-text-medium py-px hidden md:block">
                Back
              </h3>
            </button>
          </SheetClose>
          {(quest.userProgress?.started || quest.userProgress?.completed) && (
            <div
              className="absolute top-0 right-0 py-[7px] md:py-3.5 px-4 md:px-10"
              ref={optionsRef}
            >
              <button
                type="button"
                className="flex items-center justify-between md:px-3 px-2 inset-x-0 md:py-1.5 py-1.5 text-center text-white hover:bg-white-10a rounded-full"
                onClick={toggleOptions}
                data-testid="quest-back-button"
              >
                <Icons
                  name={"more-horizontal-filled"}
                  height={20}
                  width={20}
                  fill="#fff"
                />
                <h3 className="px-1 button-text-medium py-px hidden md:block">
                  Options
                </h3>
              </button>
              {showOptions && (
                <div
                  id="quest-options-menu"
                  className="absolute right-[-30%] md:right-[15%] flex flex-col flex-start bg-white rounded-lg"
                >
                  <div className="relative">
                    <Icons
                      name={"triangle-filled"}
                      fill={"white"}
                      width={18}
                      height={18}
                      className={"absolute right-[50px] top-[-15px]"}
                    />

                    <button
                      type="button"
                      className="flex flex-start p-1 gap-2.5 bg-grey-100"
                      onClick={() =>
                        setConfirmationModalType(ConfirmationModalType.Leave)
                      }
                    >
                      <h3 className="min-w-[182px] px-2 py-1.5 title-9 text-cool-grey-700 hover:text-red-600 hover:bg-red-8a rounded whitespace-nowrap text-left">
                        Leave program
                      </h3>
                    </button>
                  </div>
                  {confirmationModalType !== null && (
                    <ConfirmationModal
                      modalRef={modalRef}
                      type={confirmationModalType}
                      questName={quest.name}
                      url={quest.wordmarkAsset?.url}
                      authorName={quest.authors?.[0].name}
                      onClose={closeModal}
                      onConfirmation={onConfirmation}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex md:px-10 px-4 justify-end">
          <div className="flex gap-2">
            <button
              type="button"
              onClick={togglePlay}
              className="w-9 h-9 p-2 bg-black/70 rounded-[100px]"
            >
              <Icons
                name={isPlaying ? "pause-filled" : "play-filled"}
                height={20}
                width={20}
                fill="#fff"
              />
            </button>
            <button
              type="button"
              onClick={toggleMute}
              className="w-9 h-9 p-2 bg-black/70 rounded-[100px]"
            >
              <Icons
                name={isMuted ? "volume-x-filled" : "volume-2-filled"}
                height={20}
                width={20}
                fill="#fff"
              />
            </button>
            {/* {quest.trailerAsset?.captions &&
              quest.trailerAsset?.captions?.length > 0 && (
                <button
                  type="button"
                  onClick={toggleCaptions}
                  className="w-9 h-9 p-2 bg-black/70 rounded-[100px]"
                >
                  <Icons
                    name={showCaptions ? "caption-filled" : "caption-x-filled"}
                    height={20}
                    width={20}
                    fill="#fff"
                  />
                </button>
              )} */}
            <button
              type="button"
              onClick={handleFullScreen}
              className="w-9 h-9 p-2 bg-black/70 rounded-[100px]"
            >
              <Icons
                name="maximize-filled"
                height={20}
                width={20}
                fill="#fff"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white w-full top-0 z-10 ">
        <div className="space-x-8 w-full">
          <Tabs defaultValue="overview">
            <TabsList className="z-10 mb-6 h-[50px] pb-0 sticky top-0 bg-white px-4 md:px-10 gap-6 md:gap-8 justify-start border-b border-b-cool-grey-300 rounded-none w-full">
              <TabsTrigger
                value="overview"
                className="px-0 py-3 flex gap-x-2 flex-row items-center text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
              >
                <div className="heading-8">Overview</div>
              </TabsTrigger>
              <TabsTrigger
                value="lessons"
                className="px-0 py-3 flex gap-x-2 flex-row items-center text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
              >
                <div className="heading-8">Lessons</div>
              </TabsTrigger>
              <TabsTrigger
                value="resources"
                className="px-0 py-3 flex gap-x-2 flex-row items-center text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
              >
                <div className="heading-8">Resources</div>
              </TabsTrigger>
              <TabsTrigger
                value="stories"
                className="px-0 py-3 flex gap-x-2 flex-row items-center text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
              >
                <div className="heading-8">Stories</div>
              </TabsTrigger>
            </TabsList>
            <TabsContent value="overview">
              <Overview {...quest} />
            </TabsContent>
            <TabsContent value="lessons">
              <Lessons {...quest} />
            </TabsContent>
            <TabsContent value="resources">
              <Resources {...quest} />
            </TabsContent>
            <TabsContent value="stories">
              <Stories />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
