import { create } from "zustand";
import { useShallow } from "zustand/shallow";
import type { B2bCollection } from "types/__generated__/graphql";

export type MediaDisplayType =
  | "meditations"
  | "soundscapes"
  | "circle-with-blurred-background";

interface CollectionModalState {
  readonly mediaDisplayType: MediaDisplayType;
  readonly collection: B2bCollection | null;
  readonly previousCollection: B2bCollection | null;
  readonly isOpen: boolean;
  readonly isLoading: boolean;

  setMediaDisplayType: (mediaDisplayType: MediaDisplayType) => void;
  setCollection: (collection: B2bCollection | null) => void;
  openModal: () => void;
  closeModal: () => void;
  toggleModal: () => void;
  openWithCollection: (collection?: B2bCollection | null) => boolean;
  reset: () => void;
  setIsLoading: (isLoading: boolean) => void;
}

const initialState: Omit<
  CollectionModalState,
  | "setMediaDisplayType"
  | "setCollection"
  | "openModal"
  | "closeModal"
  | "toggleModal"
  | "openWithCollection"
  | "reset"
  | "setIsLoading"
> = {
  mediaDisplayType: "meditations" as const,
  collection: null,
  previousCollection: null,
  isOpen: false,
  isLoading: false,
};

const useCollectionModalStore = create<CollectionModalState>((set, get) => ({
  ...initialState,

  setMediaDisplayType: (mediaDisplayType: MediaDisplayType) =>
    set({ mediaDisplayType }),

  setCollection: (collection: B2bCollection | null) =>
    set((state) => {
      // Return state unchanged if collection IDs match
      if (state.collection?.id === collection?.id) return state;

      return {
        collection,
        previousCollection: state.collection,
        isLoading: !!collection,
      };
    }),

  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false }),
  toggleModal: () => set((state) => ({ isOpen: !state.isOpen })),

  openWithCollection: (collection?: B2bCollection | null) => {
    if (!collection) {
      console.warn("Collection is missing");
      return false;
    }

    const currentCollection = get().collection;
    const isCurrentlyOpen = get().isOpen;

    // If same collection and already open, close the modal
    if (currentCollection?.id === collection.id && isCurrentlyOpen) {
      set({ isOpen: false });
      return true;
    }

    // Otherwise, open with the new collection
    set({
      collection,
      previousCollection: currentCollection,
      isOpen: true,
      isLoading: true,
    });

    return true;
  },

  reset: () => set(initialState),
  setIsLoading: (isLoading) => set({ isLoading }),
}));

export const useCollectionModalData = () => {
  return useCollectionModalStore(
    useShallow((state) => ({
      collection: state.collection,
      isOpen: state.isOpen,
      isLoading: state.isLoading,
      title: state.collection?.title ?? null,
      coverImage: state.collection?.coverAsset?.url ?? null,
      tracksCount: state.collection?.collectionResources?.length ?? 0,
    }))
  );
};

export const useCollectionModalActions = () => {
  return useCollectionModalStore(
    useShallow((state) => ({
      openWithCollection: state.openWithCollection,
      closeModal: state.closeModal,
      reset: state.reset,
    }))
  );
};

export default useCollectionModalStore;
