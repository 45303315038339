export const DevEnvironments = {
    development: "development",
    dev: "dev",
    local: "local",
    stg: "stg",
    staging: "staging",
    testTool: "testtool"
};

export const devEnvs = Object.values(DevEnvironments);

export function isDevelopmentEnvironment(): boolean {
    return devEnvs.includes(process.env.TEAMSFX_ENV || "");
}
