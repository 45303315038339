import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { app } from "@microsoft/teams-js";
import useGetResourceById from '../hooks/useGetResourceById';
import { useMediaPlayerHook } from '../hooks/useMediaPlayer';

const MessageHandler = () => {
    const navigate = useNavigate();
    const { fetchResource, data, loading, error } = useGetResourceById();
    const { playTrack } = useMediaPlayerHook();

    useEffect(() => {
        app.initialize().then(() => {
            app.getContext().then((context: app.Context) => {
                const subPageId = context.page.subPageId;
                console.log("subPageId:", subPageId);
                if (subPageId) {
                    if (subPageId.startsWith('openUrl:')) {
                        const url = subPageId.replace('openUrl:', '');
                        navigate(url);
                        return;
                    } else if (subPageId.startsWith('resources:')) {
                        const resourceId = subPageId.replace('resources:', '');
                        fetchResource({ variables: { id: resourceId } });
                        return;
                    } else {
                        switch (subPageId) {
                            case 'openProgramsTab':
                                navigate('/programs');
                                break;
                            case 'open3MIQ':
                                navigate('/quests/en/questions');
                                break;
                            case 'open6PM':
                                navigate('/quests/en/learn-meditation');
                                break;
                            case 'openMeditaions':
                                navigate('/meditations');
                                break;
                            case 'openSoundscapes':
                                navigate('/soundscapes');
                                break;
                            default:
                                console.warn("Unknown subPageId:", subPageId);
                                break;
                        }
                    }
                    context.page.subPageId = '';
                }
            });
        });
        return () => {
            app.getContext().then((context: app.Context) => {
                context.page.subPageId = '';
            });
        };
    }, []);

    useEffect(() => {
        if (data?.standaloneResource && !loading) {
            const isSoundscape = data.standaloneResource.type !== 'meditation';
            playTrack(data.standaloneResource);
        }
    }, [data, loading]);

    return null;
};

export default MessageHandler;