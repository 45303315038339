// src/components/VideoPlayer/MVUniversalPlayerWrapper.js
import { h, defineComponent, type PropType } from "vue";
import { MVMeditationResource } from "@mindvalley/mv-universal-player";
import type { BackgroundSound } from "@mindvalley/mv-universal-player/dist/types/adaptive";
import type { Media, Rendition, Resource } from "types/__generated__/graphql";

// TEMPORARY FIX TO MAP THE MIME TYPES
const ALLOWED_MIME_TYPES = [
  "application/x-mpegURL",
  "video/mp4",
  "audio/mpeg",
  "audio/mp3",
  "audio/ogg",
];

export default defineComponent({
  name: "MVMeditationResourceWrapper",
  components: {
    MVMeditationResource,
  },
  props: {
    media: {
      type: Object as PropType<Media | Resource>,
      required: true,
    },
    nowPlayingTitle: {
      type: Object as PropType<{ title: string; subtitle: string }>,
      required: false,
    },
    defaultBackgroundSound: {
      type: Object as PropType<BackgroundSound>,
      required: false,
      default: null,
    },
    backgroundSounds: {
      type: Object as PropType<BackgroundSound[]>,
      required: false,
      default: () => [],
    },
    startFullscreen: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
    showCollection: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function as PropType<(...args: any[]) => any>,
      required: true,
    },
    handleCollectionOpen: {
      type: Function as PropType<(...args: any[]) => any>,
      required: false,
      default: () => {},
    },
  },
  methods: {
    formatSources(localSources: Array<Rendition | null> = []) {
      return (localSources || [])
        .filter(
          (source): source is Rendition =>
            !!source &&
            !!source.contentType &&
            ALLOWED_MIME_TYPES.includes(source.contentType)
        )
        .map((source) => ({
          type: source.contentType,
          src: source.url || "",
        }));
    },
  },

  render() {
    const media = this.media;
    const mediaId = media.id || "";
    const title = media.title || "";
    const description = media.description || "";
    const artistName = media.author?.name || "";
    const posterUrl = media.coverAsset?.url || "";
    const duration = media.totalDuration || 0;
    const renditions = media.mediaAsset?.renditions || [];
    const defaultBackgroundSounds = this.defaultBackgroundSound || null;
    const backgroundSounds = this.backgroundSounds || null;
    const nowPlayingTitle = this.nowPlayingTitle;

    return h(
      "div",
      {
        class: "mv-universal-player-container w-full",
        id: `container-${mediaId}`,
      },
      [
        h(MVMeditationResource, {
          id: mediaId,
          audioSources: this.formatSources(renditions),
          backgroundSounds,
          title,
          description,
          artistName,
          posterUrl,
          duration,
          showCollection: this.showCollection,
          showPreviousNext: false,
          autoPlay: this.autoPlay,
          nowPlayingTitle: nowPlayingTitle?.title || "NOW PLAYING",
          nowPlayingSubtitle: nowPlayingTitle?.subtitle || "MEDITATION",
          onClose: this.onClose,
          onCollectionOpen: this.handleCollectionOpen,
          ...(media.averageRating != null
            ? { ratings: Number(media.averageRating.toFixed(1)) }
            : {}),
          ...(defaultBackgroundSounds
            ? { defaultBackgroundSound: defaultBackgroundSounds }
            : {}),
        }),
      ]
    );
  },
});
