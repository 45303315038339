import NavbarAvatar from "components/Avatar";
import LockedModal from "components/LockedModal";
import Icons from "components/icons/Icons";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import useUserProfile from "hooks/useUserProfile";
import { cn } from "libs/classMerger";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NavDropdownMenu from "../components/NavDropdownMenu";
import NavDropdownMenuTabletMobile from "../components/NavDropdownMenuTabletMobile";
import { WorkwayLogoFull } from "../components/logo/WorkwayLogo";
import WorkwaySmallLogo from "../components/logo/WorkwaySmallLogo";

enum NavLinks {
  Home = "Home",
  Programs = "Programs",
  Soundscapes = "Soundscapes",
  Meditations = "Meditations",
}

interface IUserdata {
  name: string;
  id: string;
  email: string;
  profilePhoto: string;
  smallLogo: string;
  role: string;
  isMicrosoftUser: boolean;
  isAdmin: boolean;
  adminPanelURL: string;
  profileURL: string;
  firstName: string;
  lastName: string;
}

const Navbar = () => {
  const { userAccessState, isAuthenticated, organizationData } = useUserState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [userData, setUserData] = useState<IUserdata>();

  const { loading, data } = useUserProfile();

  useEffect(() => {
    if (!data || !data.currentProfile || loading) return;
    //TODO: Fix mock data
    setUserData({
      name: `${data.currentProfile.firstName} ${data.currentProfile.lastName}`,
      id: "",
      email: data.currentProfile.email,
      profilePhoto: data.currentProfile.avatarUrl,
      smallLogo:
        "https://s3.amazonaws.com/mv-osiris/uploads/organization_logos/square/96f35e57-90e2-47cc-87b1-0bda3c741996.svg",
      role: data.currentProfile.role,
      isMicrosoftUser: false,
      isAdmin: false,
      adminPanelURL: "/",
      profileURL: "/",
      firstName: data.currentProfile.firstName,
      lastName: data.currentProfile.lastName,
    });
  }, [data]);

  const location = useLocation();

  const menuHandler = (value = false) => {
    setIsMenuOpen(value);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setIsVisible(false);
      }

      if (window.scrollY <= 20) {
        // if scroll up show the navbar
        setIsVisible(true);
      }
      menuHandler(false);
      setLastScrollY(window.scrollY);
    }
  };

  const menuRender = () => {
    return [
      { label: NavLinks.Home, link: "/" },
      { label: NavLinks.Programs, link: `/${NavLinks.Programs}` },
      { label: NavLinks.Meditations, link: `/${NavLinks.Meditations}` },
      { label: NavLinks.Soundscapes, link: `/${NavLinks.Soundscapes}` },
    ];
  };

  const handleClickTabNavigation = ({
    e,
    link,
  }: {
    e: React.MouseEvent<HTMLAnchorElement>;
    link: string;
  }) => {
    if (
      userAccessState !== UserAccessStateEnum.Premium &&
      (link === `/${NavLinks.Meditations}` ||
        link === `/${NavLinks.Soundscapes}`)
    ) {
      setIsModalOpen(true);
      e.preventDefault();
      return;
    }
    if (!isAuthenticated && link !== "/" && link !== `/${NavLinks.Programs}`) {
      setIsModalOpen(true);
      e.preventDefault();
      return;
    }
  };

  const profileMenu = () =>
    loading ? (
      <div> Loading </div>
    ) : (
      <div
        onClick={() => menuHandler(!isMenuOpen)}
        onKeyUp={() => menuHandler(false)}
        onKeyDown={() => menuHandler(false)}
        onKeyPress={() => menuHandler(!isMenuOpen)}
      >
        {isAuthenticated && (
          <NavbarAvatar
            imageUrl={userData?.profilePhoto}
            fallbackText={`${userData?.firstName.charAt(
              0
            )}${userData?.lastName.charAt(0)}`}
            className="size-8 hidden lg:block"
          />
        )}
      </div>
    );

  const burgerMenu = () => (
    <div
      className="lg:hidden"
      onClick={() => menuHandler(!isMenuOpen)}
      onKeyUp={() => menuHandler(false)}
      onKeyDown={() => menuHandler(false)}
      onKeyPress={() => menuHandler(!isMenuOpen)}
    >
      <Icons
        name={!isMenuOpen ? "menu-filled" : "x-filled"}
        height={24}
        width={24}
        fill="#595E67"
      />
    </div>
  );

  return (
    <div className="w-full">
      <div className="relative z-50">
        <div className="bg-white/90 w-full flex items-center justify-between py-4 border-b lg:border-b-0  border-cool-grey-300 backdrop-blur mx-auto px-5">
          <div className="flex items-center gap-12">
            <div className="relative flex items-center gap-3">
              <div className="hidden md:block">
                <WorkwayLogoFull width="147.88px" />
              </div>
              <div className="md:hidden">
                <WorkwaySmallLogo />
              </div>
              {userAccessState === UserAccessStateEnum.Premium &&
                organizationData?.logoSquareUrl && (
                  <>
                    <div className="w-0.5 py-3 bg-cool-grey-300" />
                    <img
                      src={organizationData.logoSquareUrl}
                      alt="brand-logo"
                      width={28}
                      height={28}
                    />
                  </>
                )}
            </div>
            <div className="items-center gap-9 hidden lg:flex">
              {menuRender().map((item) => (
                <Link
                  key={item.label}
                  className={cn(
                    "title-7 transition-colors hover:text-cool-grey-700 data-[selected]:text-cool-grey-700",
                    location.pathname === item.link
                      ? "text-cool-grey-700"
                      : "text-cool-grey-450"
                  )}
                  onClick={(e) =>
                    handleClickTabNavigation({ e, link: item.link })
                  }
                  to={item.link}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center">
            <div className="relative">
              {profileMenu()}
              {burgerMenu()}
              {isMenuOpen && (
                <div
                  className="hidden lg:block"
                  onMouseLeave={() => menuHandler(false)}
                >
                  <NavDropdownMenu
                    userData={userData}
                    organizationLogo={organizationData?.logoFullUrl}
                    setIsMenuOpen={() => menuHandler(false)}
                    userAccessState={userAccessState}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div
            className="w-full lg:hidden"
            onMouseLeave={() => menuHandler(false)}
          >
            <NavDropdownMenuTabletMobile
              menuRender={menuRender()}
              userData={userData}
              setIsMenuOpen={() => menuHandler(false)}
            />
          </div>
        )}
      </div>

      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          userAccessState={userAccessState}
        />
      </div>
    </div>
  );
};
export default Navbar;
