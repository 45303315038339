import { HeroCarousel } from "tabs/Home/components/HeroCarousel";
import { HeroHeading } from "../components/HeroHeading";

export function HeroSection({ onMoreDetail }: { onMoreDetail?: (ref: number) => void; }) {
  return (
    <section className="flex flex-col gap-8">
      <div className="px-6">
        <HeroHeading />
      </div>
      <HeroCarousel onMoreDetail={onMoreDetail} />
    </section>
  );
}
