import { app, meeting } from "@microsoft/teams-js";
import { useNavigate } from "react-router-dom";
import { Resource } from "types/__generated__/graphql";

type UseShareToStageProps = {
    setPlayingResourceId?: (id: string | null) => void;
};

type ShareToStageContent = {
    id?: string;
    mediaUrl: string;
    coverAssetUrl: string;
};

export const useShareToStage = ({ setPlayingResourceId }: UseShareToStageProps = {}) => {
    const navigate = useNavigate();

    const shareToStage = async (content: ShareToStageContent) => {
        try {
            const context = await app.getContext();
            const frameContext = context.page.frameContext;

            if (frameContext === "content") {
                navigate(
                    `/stageView?mediaUrl=${encodeURIComponent(content.mediaUrl)}&thumbnailUrl=${encodeURIComponent(
                        content.coverAssetUrl
                    )}&autoplay=true&isStageViewSupported=false`
                );
            } else {
                const appContentUrl = `${window.location.origin}/stageView?mediaUrl=${encodeURIComponent(
                    content.mediaUrl
                )}&thumbnailUrl=${encodeURIComponent(content.coverAssetUrl)}&autoplay=true`;

                meeting.shareAppContentToStage((err, result) => {
                    if (result) {
                        console.log("Successfully shared to stage:", result);
                        if (content.id && setPlayingResourceId) {
                            setPlayingResourceId(content.id);
                        }
                    }
                    if (err) {
                        console.error("Error sharing to stage:", err);
                    }
                }, appContentUrl);
            }
        } catch (error) {
            console.error('Error in shareToStage:', error);
        }
    };

    return { shareToStage };
};