import * as Avatar from "@radix-ui/react-avatar";
import { cn } from "libs/classMerger";
import type React from "react";

// Define the props for the Avatar component
interface AvatarProps {
  imageUrl?: string;
  fallbackText?: string;
  className?: string; // Accept a className prop for custom styling
}

const NavbarAvatar: React.FC<AvatarProps> = ({
  imageUrl,
  fallbackText = "MV",
  className = "", // Default to empty string if no className is provided
}) => {
  return (
    <Avatar.Root className={cn("rounded-full cursor-pointer block", className)}>
      <Avatar.Image
        className="aspect-square object-cover rounded-full"
        src={
          imageUrl ??
          "https://cdn-assets.workway.app/images/avatar-b1fa94a2cba30f742fc8b95793f60f98.svg?vsn=dg"
        }
        alt="User Avatar"
      />
    </Avatar.Root>
  );
};

export default NavbarAvatar;
