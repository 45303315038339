import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { useState } from "react";
import { cn } from "libs/classMerger";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import LockedModal from "components/LockedModal";
import { Tooltip } from "components/ui/tooltip";
import type { Resource } from "types/__generated__/graphql";

export const PopularInOrganisation = ({
  data,
}: {
  data: Resource[] | null;
}) => {
  const { userAccessState, organizationData } = useUserState();
  const isPlaying = useMediaPlayerStore((state) => state.isPlaying);
  const track = useMediaPlayerStore((state) => state.track);
  const setIsPlaying = useMediaPlayerStore((state) => state.setIsPlaying);
  const { playTrack } = useMediaPlayerHook();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handlePlayPause = async (trackData: Resource) => {
    if (userAccessState !== UserAccessStateEnum.Premium) {
      return setIsModalOpen(true);
    }

    if (track?.id === trackData.id) {
      if (isPlaying) {
        setIsPlaying(false);
      } else {
        setIsPlaying(true);
      }
      return;
    }
    playTrack({
      track: trackData,
    });
  };

  // TODO: SKelly
  if (!data) return null;

  return (
    <section>
      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          userAccessState={userAccessState}
        />
      </div>
      <div className="flex flex-col gap-6">
        <div className="heading-6">Popular in {organizationData?.name}</div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {data.map((item) => (
            <div
              key={item.id}
              role="button"
              tabIndex={0}
              onClick={() => handlePlayPause(item)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  handlePlayPause(item);
                }
              }}
              className="flex w-full rounded-lg shrink-0 group"
            >
              <div className="relative w-[124px] h-[124px] overflow-hidden rounded-tl-lg rounded-bl-lg shrink-0">
                <div
                  className={cn(
                    "absolute inset-0 bg-center rounded-tl-lg rounded-bl-lg blur-[4px]",
                    item.subtype !== "background" && "hidden"
                  )}
                  style={{
                    backgroundImage: `url(${item.coverAsset?.url})`,
                  }}
                />
                <div
                  className={cn(
                    "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-contain bg-center rounded-full w-[124px] h-[124px]",
                    item.subtype !== "background" && "rounded-none"
                  )}
                  style={{
                    backgroundImage: `url(${item.coverAsset?.url})`,
                  }}
                />
              </div>
              <div className="flex gap-3 px-3 justify-between w-full items-center rounded-tr-lg rounded-br-lg border border-black-12a border-l-0">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-1">
                    <div className="title-8"> {item.title}</div>
                    <div className="caption-disclaimer text-cool-grey-500">
                      {" "}
                      {item.author?.name}
                    </div>
                  </div>
                  {item.subtype !== "background" && (
                    <div className="body-2-xs py-1 px-2 w-fit rounded-[100px] bg-brown-90a border border-black-12a">
                      {item.outcomes?.[0].name ?? ""}
                    </div>
                  )}
                </div>

                <Tooltip
                  delayDuration={0}
                  tooltipContent={<p className="caption-disclaimer">Play</p>}
                >
                  <Button
                    variant="ghost"
                    size="roundedIcon"
                    className="border border-black-12a shrink-0"
                    onClick={() => handlePlayPause(item)}
                  >
                    {track?.id === item.id ? (
                      !isPlaying ? (
                        <Icons name="play-filled" height={20} width={20} />
                      ) : (
                        <Icons name="pause-filled" height={20} width={20} />
                      )
                    ) : (
                      <Icons name="play-filled" height={20} width={20} />
                    )}
                  </Button>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
