import Icons from "components/icons/Icons";
import type { Page } from "types/__generated__/graphql";

export const QuestConsumptionFooter = ({
  page,
  onMarkPageAsCompleted,
}: {
  page?: Page;
  onMarkPageAsCompleted: (page?: Page) => void;
}) => {
  return (
    <div className="fixed w-full bottom-0 flex lg:hidden h-12 z-30">
      {page?.tasks && page?.tasks.length > 0 && (
        <div className="flex flex-1 bg-cool-grey-600 gap-x-1 items-center justify-center">
          <Icons name="edit-outlined" fill="white" />
          <p className="text-cool-grey-100 title-9">
            {page?.tasks?.filter((task) => task.completed).length}/
            {page?.tasks?.length} tasks completed
          </p>
        </div>
      )}

      {page?.completed && (
        <div className="title-9 flex flex-1 items-center justify-center gap-x-1 bg-green-50 text-green-600">
          <Icons name="checklist-circle-filled" className="text-green" />
          <p>Completed</p>
        </div>
      )}

      {!page?.completed && (
        <button
          type="button"
          className="title-9 flex flex-1 gap-x-1 items-center justify-center bg-red-600 text-white active:text-white/80"
          onClick={() => onMarkPageAsCompleted(page)}
        >
          Mark as complete
          <Icons name="chevron-right-filled" />
        </button>
      )}
    </div>
  );
};
