import { useUserState } from "context/userContext";
import {
  type RecommendedB2bMeditationsPopularInTheOrgQueryVariables,
  useRecommendedB2bMeditationsPopularInTheOrgQuery,
  type Resource,
} from "types/__generated__/graphql";

function useMeditationPopularInTheOrg(first = 6) {
  const { organizationData } = useUserState();
  const variables: RecommendedB2bMeditationsPopularInTheOrgQueryVariables = {
    orgId: organizationData?.id?.toString() ?? "0",
    first,
  };

  const { data, loading, error, refetch } =
    useRecommendedB2bMeditationsPopularInTheOrgQuery({
      variables,
      fetchPolicy: "cache-and-network",
    });

  const meditations: Resource[] | null =
    data?.recommendedB2bMeditationsPopularInTheOrg?.edges
      ?.map((edge) => edge.node?.meditation)
      .filter((meditation): meditation is Resource => meditation != null) ??
    null;

  return {
    data: meditations,
    loading,
    error,
    refetch,
  };
}

export default useMeditationPopularInTheOrg;
