import { useEffect, useRef } from "react";
import { createApp, type App } from "vue";
import { ENV_ENUMS } from "config/teamsConfig";
import MVUniversalAudioPlayerWrapper from "./MVUniversalAudioPlayerWrapper";
import MVUniversalPlayer from "@mindvalley/mv-universal-player";
import svgSprite from "@mindvalley/design-system/dist/icons/sprites/ui-icons.svg";
import useMixers from "hooks/useMixers";
import type { Media } from "types/__generated__/graphql";
import "./videoPlayer.css";

const iconPath =
  process.env.NODE_ENV === ENV_ENUMS.DEVELOPMENT
    ? ""
    : `${process.env.REACT_APP_TAB_ENDPOINT}/`;

const MVUniversalAudioPlayer = ({
  media,
  muted,
  autoplay,
}: {
  media: Media | null;
  muted: boolean;
  autoplay: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const vueAppRef = useRef<App<Element> | null>(null);
  const { data: mixersData } = useMixers();

  useEffect(() => {
    if (!containerRef.current) return;

    const app = createApp(MVUniversalAudioPlayerWrapper, {
      media,
      defaultBackgroundSound: mixersData ?? [],
      muted,
      autoplay,
    });

    app.use(MVUniversalPlayer, {
      svgSpriteOptions: { url: `${iconPath}${svgSprite}` },
    });
    app.mount(containerRef.current);

    vueAppRef.current = app;

    return () => {
      if (vueAppRef.current) {
        vueAppRef.current.unmount();
      }
    };
  }, [media, muted, autoplay, mixersData]);

  return <div ref={containerRef} className="mv-universal-player-container" />;
};

export default MVUniversalAudioPlayer;
