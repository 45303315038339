import { useEffect, useRef, memo } from "react";
import { createApp, type App } from "vue";
import { ENV_ENUMS } from "config/teamsConfig";
import QuestConsumptionVideoResourcePlayerWrapper, {
  type VideoPlayerType,
} from "./QuestConsumptionVideoResourcePlayerWrapper";
import MVUniversalPlayer from "@mindvalley/mv-universal-player";
import svgSprite from "@mindvalley/design-system/dist/icons/sprites/ui-icons.svg";

import "./videoPlayer.css";

const iconPath =
  process.env.NODE_ENV === ENV_ENUMS.DEVELOPMENT
    ? ""
    : `${process.env.REACT_APP_TAB_ENDPOINT}/`;

function QuestConsumptionVideoResourcePlayer({
  media,
  muted,
  autoplay,
  handleOnPlay,
  handleOnPause,
}: {
  media: VideoPlayerType;
  muted?: boolean;
  autoplay?: boolean;
  handleOnPlay?: () => void;
  handleOnPause?: () => void;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const vueAppRef = useRef<App<Element> | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    if (vueAppRef.current) {
      vueAppRef.current.unmount();
      vueAppRef.current = null;
    }

    const app = createApp(QuestConsumptionVideoResourcePlayerWrapper, {
      media,
      muted,
      autoplay,
      handleOnPlay,
      handleOnPause,
    });
    app.use(MVUniversalPlayer, {
      svgSpriteOptions: { url: `${iconPath}${svgSprite}` },
    });
    app.mount(containerRef.current);
    vueAppRef.current = app;

    return () => {
      if (vueAppRef.current) {
        vueAppRef.current.unmount();
        vueAppRef.current = null;
      }
    };
  }, [media, muted, autoplay, handleOnPlay, handleOnPause]);

  return (
    <div
      ref={containerRef}
      className="mv-universal-player-container mv-universal-player"
    />
  );
}

// TEMP FIX to prevent re-rendering of the component
function arePropsEqual(prevProps: any, nextProps: any) {
  const handlersEqual =
    prevProps.handleOnPlay === nextProps.handleOnPlay &&
    prevProps.handleOnPause === nextProps.handleOnPause;

  const mediaEqual =
    prevProps.media.id === nextProps.media.id &&
    JSON.stringify(prevProps.media.mediaAsset) ===
      JSON.stringify(nextProps.media.mediaAsset) &&
    prevProps.media.title === nextProps.media.title &&
    prevProps.media.author === nextProps.media.author &&
    (prevProps.media.coverAsset?.url === nextProps.media.coverAsset?.url ||
      (!prevProps.media.coverAsset && !nextProps.media.coverAsset));

  return (
    prevProps.muted === nextProps.muted &&
    prevProps.autoplay === nextProps.autoplay &&
    mediaEqual &&
    handlersEqual
  );
}

export default memo(QuestConsumptionVideoResourcePlayer, arePropsEqual);
