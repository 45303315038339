import { microsoftAuthUrl } from "config/authConfig";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authLoginPopUp } from "utils/authHelper";
import { promise } from "zod";

export function useMicrosoftLogin() {
  const navigate = useNavigate();
  const { userAccessState } = useUserState();

  async function handleLogin() {
    try {
      const accessToken = await authLoginPopUp(microsoftAuthUrl);

      if (!accessToken) return;
      window.location.reload();
    } catch (error) {
      console.warn("Error during login:", error);
    }
  }

  return handleLogin;
}
