import { UserAccessStateEnum } from "context/userContext";
import { useMicrosoftLogin } from "hooks/useMicrosoftLogin";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "./Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./Dialog";
import { LoginWithMicrosoftButton } from "./LoginWithMicrosoftButton";
// @ts-ignore
import Getstarted from "./logo/GetStarted.png";
import { NavigationEnum } from "router/navigationType";

const LockedModal = ({
  open = false,
  onCancel,
  userAccessState,
}: {
  open?: boolean;
  onCancel: () => void;
  userAccessState: UserAccessStateEnum;
}) => {
  const navigate = useNavigate();
  const handleLogin = useMicrosoftLogin();

  const FreemiumView = (
    <div>
      <div className="flex flex-col gap-y-4">
        <p className="body-small">
          To access our full library of exclusive content, get in touch with our
          sales team about a membership for your organization today.
        </p>
        <Button
          variant="primary"
          className="text-md"
          onClick={() => navigate(NavigationEnum.InterestForm)}
        >
          Get in touch now
        </Button>
      </div>
      <div className="py-[6px]">
        <p className="title-9 text-center">
          or{" "}
          <Link
            className="text-red-600 underline"
            to="https://support.getworkway.com/"
            target="_blank"
          >
            learn more
          </Link>{" "}
          about Workway
        </p>
      </div>
    </div>
  );

  const PreLoggedinView = (
    <div>
      <div className="flex flex-col gap-y-4">
        <p className="body-small">
          To access our full library of exclusive content, log in now with your
          Microsoft Account.
        </p>
        <LoginWithMicrosoftButton
          handleClick={handleLogin}
          iconSize={16}
          customClass="w-full"
        />
      </div>

      <div className="py-[6px]">
        <p className="title-9 text-center">
          or{" "}
          <Link
            className="text-red-600 underline"
            to={NavigationEnum.InterestForm}
          >
            get in touch
          </Link>{" "}
          with our sales team
        </p>
      </div>
    </div>
  );

  return (
    <Dialog open={open}>
      <DialogContent
        className="z-[100]  md:max-w-sm max-w-[360px] flex p-0 flex-col sm:rounded-2xl rounded-2xl bg-brown-50"
        onInteractOutside={onCancel}
      >
        <DialogTitle className="hidden">Locked Content</DialogTitle>
        <DialogHeader className="">
          <div className="w-full h-full relative">
            <img
              className="w-full h-full object-cover rounded-t-2xl aspect-video "
              src={Getstarted}
              alt="cover"
            />
          </div>
        </DialogHeader>

        <DialogFooter className="flex flex-col py-6 px-10">
          {userAccessState === UserAccessStateEnum.Freemium
            ? FreemiumView
            : PreLoggedinView}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LockedModal;
