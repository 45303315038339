import { cn } from "libs/classMerger";

interface ProgramListItemProps {
    coverAssetUrl?: string;
    name: string;
    isFirstGroup: boolean;
    index: number;
    lessonCounter: number;
    className?: string;
    isPlaying?: boolean;
}

export const ProgramListItem = ({
    coverAssetUrl,
    name,
    isFirstGroup,
    index,
    lessonCounter,
    className,
    isPlaying,
}: ProgramListItemProps) => {
    return (
        <div className={cn("flex items-start gap-4 p-3 hover:bg-gray-50 rounded-lg", className)}>
            <div className="relative">
                <img
                    src={coverAssetUrl}
                    alt={name}
                    className="w-32 h-20 object-cover rounded-lg"
                />
                {isPlaying && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black/50 text-white text-sm font-medium rounded-lg">
                        Now playing
                    </div>
                )}
            </div>
            <div className="flex flex-col gap-1">
                <p className="text-sm text-cool-grey-500">
                    {isFirstGroup ? `Intro ${index + 1}` : `Lesson ${lessonCounter + index + 1}`}
                </p>
                <h4 className="text-base font-medium text-cool-grey-700 line-clamp-2">{name}</h4>
            </div>
        </div>
    );
};