import { MyLibraryAssignedSkeletion } from "components/loading/MyLibraryListSkeleton";
import { QuestCard, QuestCardVariants } from "components/quest/QuestCard";
import { CardVariant3 } from "components/quest/QuestCardVariant3";
import { useUserState } from "context/userContext";
import useAssignedQuests from "hooks/useAssignedQuests";
import EmptyState from "../components/EmptyState";
import useScreenType from "hooks/useScreenType";
import type { B2bQuest } from "types/__generated__/graphql";

const AssignedToMe = () => {
  const { userAccessState } = useUserState();
  const { data: quests, loading: assignedQuestsLoading } = useAssignedQuests({
    first: 20,
  });

  const screenType = useScreenType();
  const unEnrolledAssignedQuests =
    quests?.filter(
      (assignment) => assignment.quest.userProgress?.started === false
    ) || [];
  const inProgressAssignedQuests =
    quests?.filter(
      (assignment) => assignment.quest.userProgress?.started === true
    ) || [];

  if (assignedQuestsLoading) return <MyLibraryAssignedSkeletion />;

  return (
    <div className="flex flex-col gap-8 ">
      <div className="flex flex-col w-fit gap-1">
        <p className="heading-5 hidden lg:block">Assigned to me</p>
        <p className="body-small">
          These learning materials are assigned by your organization for
          completion by a specific deadline. Your progress is tracked to keep
          you on track.
        </p>
      </div>
      {!quests?.length ? (
        <EmptyState section="assignedtome" />
      ) : (
        <div className=" flex flex-col gap-y-12">
          {unEnrolledAssignedQuests.length > 0 && (
            <div className="items-stretch flex flex-col gap-y-6">
              <p className="heading-7">{`Unenrolled (${unEnrolledAssignedQuests.length})`}</p>

              <div className="flex flex-col md:flex-row gap-5">
                {unEnrolledAssignedQuests.map((quest) => {
                  return (
                    <QuestCard
                      quest={quest.quest as B2bQuest}
                      canHover={
                        screenType.isDesktop || screenType.isLargeDesktop
                      }
                      cardVariant={QuestCardVariants.Base}
                      questAssignmentEndDate={quest.endDate}
                      userAccessState={userAccessState}
                      className="basis-1/4"
                      key={quest.quest.id}
                    />
                  );
                })}
              </div>
            </div>
          )}

          {inProgressAssignedQuests.length > 0 && (
            <div
              className={`items-stretch ${inProgressAssignedQuests.length > 0 ? "flex" : " hidden"
                }  flex-col gap-y-6`}
            >
              <p className="heading-7">{`In progress (${inProgressAssignedQuests.length})`}</p>
              <div className={"flex flex-col md:flex gap-5"}>
                {inProgressAssignedQuests?.map((quest) => (
                  <CardVariant3
                    endDate={quest.endDate}
                    quest={quest.quest as B2bQuest}
                    key={quest.quest.id}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AssignedToMe;
