import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CarouselPlayerBanner } from "components/soundscapesAndMeditations/CarouselPlayerBanner";
import { CuratedCollections } from "components/soundscapesAndMeditations/CuratedCollections";
import { Collections } from "components/soundscapesAndMeditations/Collections";
import { FeaturedCollectionSection } from "components/soundscapesAndMeditations/FeaturedCollection";
import { PopularInOrganisation } from "components/soundscapesAndMeditations/PopularInOrganisationSection";
import useMeditationsOfTheDay from "hooks/useMeditationsOfTheDay";
import { CategoriesSection } from "components/soundscapesAndMeditations/CategoriesSection";
import { MyQuestMeditationsSection } from "./sections/MyQuestMeditationsSeciton";
import useChannelBySlug from "hooks/useChannelBySlug";
import useQuestsByProgress from "hooks/useQuestsByProgress";
import useMeditationPopularInTheOrg from "hooks/useMeditationPopularInTheOrg";
import {
  CollectionHighlightStatusFilterEnum,
  ResourceTypeFilterEnum,
} from "types/__generated__/graphql";

export default function Meditations() {
  const exploreCuratedsRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [meditationsOfTheDay, setMeditationsOfTheDay] = useState([]);

  const {
    data: meditationsOfTheDayData,
    error: meditationOfTheDayFetchingError,
    loading: fetchingMeditationOfTheDay,
  } = useMeditationsOfTheDay({
    prevData: meditationsOfTheDay,
  });

  useEffect(() => {
    if (!fetchingMeditationOfTheDay && meditationsOfTheDayData) {
      setMeditationsOfTheDay(meditationsOfTheDayData);
    }
  }, [meditationsOfTheDayData, fetchingMeditationOfTheDay]);

  const {
    data: curratedCollectionsData,
    error: curractedCollectionsFetchingError,
    loading: fetchingCurratedCollections,
  } = useChannelBySlug({
    slug: "b2b-meditations-collections-scenarios",
    highlightStatus: CollectionHighlightStatusFilterEnum.HighlightedLevelOne,
  });

  const { data: highlightedCollectionsData } = useChannelBySlug({
    slug: "b2b-meditations-collections-scenarios",
    highlightStatus: CollectionHighlightStatusFilterEnum.HighlightedLevelTwo,
  });

  const { data: featuredCollections, loading: fetchingFeaturedCollections } =
    useChannelBySlug({
      slug: "b2b-meditations-collections-scenarios",
      highlightStatus: CollectionHighlightStatusFilterEnum.Featured,
    });

  const { data: popularInOrgMeditationsData } = useMeditationPopularInTheOrg();

  const {
    data: meditationCategories,
    loading: fetchingMeditationCategories,
    error: meditationCategoriesFetchingError,
  } = useChannelBySlug({
    slug: "b2b-meditations-categories",
    highlightStatus: CollectionHighlightStatusFilterEnum.All,
  });

  const { data: questsByProgressMeditationData } = useQuestsByProgress({
    progress: "ongoing",
    first: 20,
    type: ResourceTypeFilterEnum.VocalMeditation,
  });

  useEffect(() => {
    if (!fetchingMeditationOfTheDay && meditationsOfTheDayData)
      window.scrollTo({ top: 0, behavior: "smooth" });
  }, [fetchingMeditationOfTheDay]);

  return (
    <section className="flex flex-col gap-12 md:gap-16 lg:gap-16">
      <div className="pt-4 ">
        <CarouselPlayerBanner
          type="meditations"
          bannerTitle="Meditations of the day"
          data={meditationsOfTheDay}
          isFetching={fetchingMeditationOfTheDay}
          fetchingError={meditationOfTheDayFetchingError}
          handleScrollNavigationRef={exploreCuratedsRef}
        />
      </div>
      <div className="flex flex-col gap-12 md:gap-16 lg:gap-16 max-w-2xl px-5 mx-auto w-full">
        <div
          ref={exploreCuratedsRef}
          className="flex flex-col gap-12 md:gap-16 lg:gap-16"
        >
          <CuratedCollections
            type="meditations"
            data={curratedCollectionsData}
            isFetching={fetchingCurratedCollections}
            fetchingError={curractedCollectionsFetchingError}
            handleViewCollectionNavigation={() =>
              navigate("/meditations/collections")
            }
          />
          {highlightedCollectionsData?.collections?.map((collection) => (
            <Collections
              type="meditations"
              key={collection?.id}
              collection={collection}
            />
          ))}
        </div>
      </div>
      <FeaturedCollectionSection
        type="meditations"
        data={featuredCollections}
        loading={fetchingFeaturedCollections}
        handleViewCollectionNavigation={() => {
          navigate("/meditations/collections");
        }}
      />
      <div className="flex flex-col gap-12 md:gap-16 lg:gap-16 max-w-2xl px-5 mx-auto w-full">
        <PopularInOrganisation data={popularInOrgMeditationsData} />
        <MyQuestMeditationsSection data={questsByProgressMeditationData} />
      </div>
      <CategoriesSection
        data={meditationCategories}
        loading={fetchingMeditationCategories}
        error={meditationCategoriesFetchingError}
        handleSeeAllNavigation={() => navigate("/meditations/categories")}
        handleCategoryNavigation={(slug) =>
          navigate(`/meditations/categories/${slug}`)
        }
        containerStyle="bg-purple-50"
      />
    </section>
  );
}
