import { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { Button } from "components/Button";
import { Tooltip } from "components/Tooltip";
import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import type { Task, Page, Section } from "types/__generated__/graphql";

const RenderFileContentType = ({
  section,
  owned = false,
}: {
  section: Section;
  owned: boolean;
}) => {
  const [openMoreInfo, setOpenMoreInfo] = useState(false);

  return (
    <div
      key={section.id}
      className="bg-brown-200 rounded-2xl p-3 flex flex-col gap-3"
    >
      <div className="flex items-center space-x-4">
        {section.media && (
          <img
            className="rounded-lg object-cover w-[100px] h-40"
            src={section.media?.coverAsset?.url}
            alt={section.media?.title}
          />
        )}
        {section.primaryAsset && (
          <img
            alt={section.primaryAsset?.name}
            className="rounded-lg object-cover"
            src="https://cdn-assets.workway.app/images/quests/doc-4601e1025fd4b39d8aec5b684e6634ba.svg?vsn=d"
          />
        )}
        <div className="flex flex-col space-y-1">
          <span className="flex items-center gap-1">
            <Icons name="file-text-outlined" height={12} width={12} />
            <p className="title-10 text-teal-600">Document</p>
          </span>
          <div className="title-8">
            {section.info?.title || section.media?.title}
          </div>
          <div className="caption-disclaimer">
            {section.media?.author?.name}
          </div>
        </div>
        {!owned && <Icons name="lock-filled" className="text-black" />}
      </div>
      <div className="flex items-end justify-between w-full">
        {section.media?.description &&
          (!openMoreInfo ? (
            <button
              className="flex items-end text-cool-grey-350 gap-1"
              type="button"
              onClick={() => setOpenMoreInfo(true)}
            >
              <Icons name="info-outlined" className="!text-cool-grey-500" />
              <div className="title-10 !text-cool-grey-500">More info</div>
            </button>
          ) : (
            <button
              className="flex items-end text-cool-grey-350 gap-1"
              type="button"
              onClick={() => setOpenMoreInfo(false)}
            >
              <Icons
                name="minus-circle-outlined"
                className="text-cool-grey-500"
              />
              <div id="more-info-text" className="title-10 text-cool-grey-500">
                Minimize
              </div>
            </button>
          ))}
        <Button asChild variant="neutralLight" className="px-2 py-2">
          <a
            href={section.primaryAsset?.url || section.media?.mediaAsset?.url}
            target="_blank"
            className="!no-underline"
            rel="noreferrer"
          >
            Download
          </a>
        </Button>
      </div>
      {openMoreInfo && <hr className="text-cool-grey-500 mt-2" />}
      {section.media?.description && (
        <div
          className={cn(
            "mt-2 body-small text-cool-grey-500",
            openMoreInfo ? "" : "hidden"
          )}
        >
          {section.media.description}
        </div>
      )}
    </div>
  );
};

const RenderMediaType = ({
  section,
  owned = false,
  handleResourcePlay,
}: {
  section: Section;
  owned: boolean;
  handleResourcePlay: (media: any) => void;
}) => {
  const type = section.type;

  const [openMoreInfo, setOpenMoreInfo] = useState(false);

  // TEMP FIX, Because the return query is not a resource type
  function generateResourceCompatibleType() {
    return {
      id: section.media?.id,
      mediaAsset: section.media?.mediaAsset,
      supportingResource: section.media?.supportingResource,
      coverAsset: section.media?.coverAsset,
      title: section.media?.title,
      author: section.media?.author,
      headline: section.media?.author?.headline,
      description: section.media?.description,
      totalDuration: section.media?.mediaAsset?.duration,
      averageRating: section.media?.averageRating,
      type: section.media?.type,
    };
  }

  function handlePlay() {
    handleResourcePlay(generateResourceCompatibleType());
  }

  if (section.media?.type === "course") return null; // TODO::  Not supported or no clear flow for this type
  if (section.media?.type === "video") {
    return (
      <div
        key={section.id}
        className="audio-player-gradient flex flex-col gap-3 p-3 rounded-2xl text-white"
      >
        <div className="flex space-x-4 w-full">
          <img
            className={cn("rounded-lg object-cover", "w-[140px] h-[80px]")}
            src={section.media?.coverAsset?.url}
            alt="media cover"
          />
          <div className="flex justify-between items-start w-full">
            <div className="flex flex-col space-y-1 justify-center">
              <span className="flex items-center">
                <Icons
                  name="film-outlined"
                  height={12}
                  width={12}
                  className="text-teal-300 mr-0.5"
                />
                <p className="title-10 text-teal-300">Video</p>
              </span>
              <div className="title-8 text-white">{section.info?.title}</div>
              <div className="caption-disclaimer">
                {section.media?.author?.name}
              </div>
            </div>
            {!owned && <Icons name="lock-filled" className="text-white" />}
          </div>
        </div>
        <div className="flex items-end justify-between">
          {type === "media" && (
            <>
              {section.media?.description && !openMoreInfo ? (
                <button
                  className="flex items-end text-cool-grey-350 gap-1"
                  type="button"
                  onClick={() => setOpenMoreInfo(true)}
                >
                  <Icons
                    name="info-outlined"
                    className={"text-cool-grey-350"}
                  />
                  <div className="title-10 text-cool-grey-350">More info</div>
                </button>
              ) : (
                <button
                  className="flex items-end text-cool-grey-350 gap-1"
                  type="button"
                  onClick={() => setOpenMoreInfo(false)}
                >
                  <Icons
                    name="minus-circle-outlined"
                    className={"text-cool-grey-350"}
                  />
                  <div
                    id="more-info-text"
                    className="title-10 text-cool-grey-350"
                  >
                    Minimize
                  </div>
                </button>
              )}
            </>
          )}
          <div className="flex gap-1">
            {/* <Tooltip tooltipContent={<p className="caption-disclaimer">Favorite</p>}>
                <button
                  type="button"
                  className="bg-black-4a button-text-medium rounded-[100px]"
                  disabled={!owned}
                >
                  <Icons name="bookmark-outlined" className={"h-6 w-6 text-white mr-1"} />
                </button>
              </Tooltip> */}
            <Tooltip
              tooltipContent={<p className="caption-disclaimer">Play</p>}
            >
              <button
                type="button"
                onClick={handlePlay}
                className="bg-black-4a button-text-medium rounded-[100px] opacity-80"
                disabled={!owned}
              >
                <Icons
                  name="play-circle-filled"
                  height={40}
                  width={40}
                  className="text-white mr-1"
                />
              </button>
            </Tooltip>
          </div>
        </div>
        {openMoreInfo && <hr className="text-cool-grey-500 mt-2" />}
        <div
          className={cn(
            "mt-2 body-small text-cool-grey-350",
            openMoreInfo ? "" : "hidden"
          )}
        >
          {section.media?.description}
        </div>
      </div>
    );
  }

  if (section.media?.type === "file") {
    return <RenderFileContentType section={section} owned={owned} />;
  }

  if (section.media?.type === "meditation") {
    return (
      <div
        key={section.id}
        className="audio-player-gradient flex flex-col gap-3 p-3 rounded-2xl text-white"
      >
        <div className="flex space-x-4 w-full">
          <img
            className={cn("rounded-lg object-cover", "w-[90px] h-[90px]")}
            src={section.media?.coverAsset?.url}
            alt="media cover"
          />
          <div className="flex justify-between items-center w-full">
            <div className="flex flex-col space-y-1 justify-center">
              <span className="flex items-center">
                <Icons
                  name="spiritual-outlined"
                  height={12}
                  width={12}
                  className="text-purple-300 mr-0.5"
                />
                <p className="title-10 text-purple-300">
                  {section.media?.type === "meditation"
                    ? "Meditation"
                    : section.media?.type === "video"
                    ? "Video"
                    : "Audio"}
                </p>
              </span>
              <div className="title-8 text-white">{section.info?.title}</div>
              <div className="caption-disclaimer">
                {section.media?.author?.name}
              </div>
            </div>
            {!owned && <Icons name="lock-filled" className="text-white" />}
          </div>
        </div>
        <div className="flex items-end justify-between">
          {type === "media" && (
            <>
              {section.media?.description && !openMoreInfo ? (
                <button
                  className="flex items-end text-cool-grey-350 gap-1"
                  type="button"
                  onClick={() => setOpenMoreInfo(true)}
                >
                  <Icons
                    name="info-outlined"
                    className={"text-cool-grey-350"}
                  />
                  <div className="title-10 text-cool-grey-350">More info</div>
                </button>
              ) : (
                <button
                  className="flex items-end text-cool-grey-350 gap-1"
                  type="button"
                  onClick={() => setOpenMoreInfo(false)}
                >
                  <Icons
                    name="minus-circle-outlined"
                    className={"text-cool-grey-350"}
                  />
                  <div
                    id="more-info-text"
                    className="title-10 text-cool-grey-350"
                  >
                    Minimize
                  </div>
                </button>
              )}
            </>
          )}
          <div className="flex gap-1">
            {/* <Tooltip tooltipContent={<p className="caption-disclaimer">Favorite</p>}>
                <button
                  type="button"
                  className="bg-black-4a button-text-medium rounded-[100px]"
                  disabled={!owned}
                >
                  <Icons name="bookmark-outlined" className={"h-6 w-6 text-white mr-1"} />
                </button>
              </Tooltip> */}
            <Tooltip
              tooltipContent={<p className="caption-disclaimer">Play</p>}
            >
              <button
                type="button"
                onClick={handlePlay}
                className="bg-black-4a button-text-medium rounded-[100px] opacity-80"
                disabled={!owned}
              >
                <Icons
                  name="play-circle-filled"
                  height={40}
                  width={40}
                  className="text-white mr-1"
                />
              </button>
            </Tooltip>
          </div>
        </div>
        {openMoreInfo && <hr className="text-cool-grey-500 mt-2" />}
        <div
          className={cn(
            "mt-2 body-small text-cool-grey-350",
            openMoreInfo ? "" : "hidden"
          )}
        >
          {section.media?.description}
        </div>
      </div>
    );
  }

  return null;

  // return (
  //   <div
  //     key={section.id}
  //     className="audio-player-gradient flex flex-col gap-3 p-3 rounded-2xl text-white"
  //   >
  //     <div className="flex space-x-4 w-full">
  //       <img
  //         className={cn("rounded-lg object-cover", "w-[90px] h-[90px]")}
  //         src={section.media?.coverAsset?.url}
  //         alt="media cover"
  //       />
  //       <div className="flex justify-between items-center w-full">
  //         <div className="flex flex-col space-y-1 justify-center">
  //           <span className="flex items-center">
  //             <Icons
  //               name="spiritual-outlined"
  //               height={12}
  //               width={12}
  //               className="text-purple-300 mr-0.5"
  //             />
  //             <p className="title-10 text-purple-300">
  //               {section.media?.type === "meditation"
  //                 ? "Meditation"
  //                 : section.media?.type === "video"
  //                 ? "Video"
  //                 : "Audio"}
  //             </p>
  //           </span>
  //           <div className="title-8 text-white">{section.info?.title}</div>
  //           <div className="caption-disclaimer">
  //             {section.media?.author?.name}
  //           </div>
  //         </div>
  //         {!owned && <Icons name="lock-filled" className="text-white" />}
  //       </div>
  //     </div>
  //     <div className="flex items-end justify-between">
  //       {type === "media" && (
  //         <>
  //           {section.media?.description && !openMoreInfo ? (
  //             <button
  //               className="flex items-end text-cool-grey-350 gap-1"
  //               type="button"
  //               onClick={() => setOpenMoreInfo(true)}
  //             >
  //               <Icons name="info-outlined" className={"text-cool-grey-350"} />
  //               <div className="title-10 text-cool-grey-350">More info</div>
  //             </button>
  //           ) : (
  //             <button
  //               className="flex items-end text-cool-grey-350 gap-1"
  //               type="button"
  //               onClick={() => setOpenMoreInfo(false)}
  //             >
  //               <Icons
  //                 name="minus-circle-outlined"
  //                 className={"text-cool-grey-350"}
  //               />
  //               <div
  //                 id="more-info-text"
  //                 className="title-10 text-cool-grey-350"
  //               >
  //                 Minimize
  //               </div>
  //             </button>
  //           )}
  //         </>
  //       )}
  //       <div className="flex gap-1">
  //         {/* <Tooltip tooltipContent={<p className="caption-disclaimer">Favorite</p>}>
  //             <button
  //               type="button"
  //               className="bg-black-4a button-text-medium rounded-[100px]"
  //               disabled={!owned}
  //             >
  //               <Icons name="bookmark-outlined" className={"h-6 w-6 text-white mr-1"} />
  //             </button>
  //           </Tooltip> */}
  //         <Tooltip tooltipContent={<p className="caption-disclaimer">Play</p>}>
  //           <button
  //             type="button"
  //             onClick={handlePlay}
  //             className="bg-black-4a button-text-medium rounded-[100px] opacity-80"
  //             disabled={!owned}
  //           >
  //             <Icons
  //               name="play-circle-filled"
  //               height={40}
  //               width={40}
  //               className="text-white mr-1"
  //             />
  //           </button>
  //         </Tooltip>
  //       </div>
  //     </div>
  //     {openMoreInfo && <hr className="text-cool-grey-500 mt-2" />}
  //     <div
  //       className={cn(
  //         "mt-2 body-small text-cool-grey-350",
  //         openMoreInfo ? "" : "hidden"
  //       )}
  //     >
  //       {section.media?.description}
  //     </div>
  //   </div>
  // );
};

const RenderAudioType = ({
  section,
  owned = false,
  authorName,
  handleResourcePlay,
}: {
  section: Section;
  owned: boolean;
  authorName?: string;
  handleResourcePlay: (media: any) => void;
}) => {
  const [openMoreInfo, setOpenMoreInfo] = useState(false);

  // TEMP FIX, Because the return query is not a resource type
  function generateResourceCompatibleType() {
    return {
      id: section.primaryAsset?.id,
      mediaAsset: section.primaryAsset,
      coverAsset: section.coverAsset,
      title: section.info?.title,
      totalDuration: section.primaryAsset?.duration,
    };
  }

  function handlePlay() {
    handleResourcePlay(generateResourceCompatibleType());
  }

  return (
    <div
      key={section.id}
      className="audio-player-gradient flex flex-col gap-3 p-3 rounded-2xl text-white"
    >
      <div className="flex space-x-4 w-full">
        <img
          className={cn("rounded-lg object-cover", "w-[140px] h-[80px]")}
          src={section.coverAsset?.url}
          alt="media cover"
        />
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col space-y-1 justify-center">
            <span className="flex items-center">
              <Icons
                name="music-outlined"
                height={12}
                width={12}
                className="text-teal-300 mr-0.5"
              />
              <p className="title-10 text-teal-300">audio</p>
            </span>
            <div className="title-8 text-white">{section.info?.title}</div>
            <div className="caption-disclaimer text-cool-grey-200">
              {section.media?.author?.name || authorName}
            </div>
          </div>
          {!owned && <Icons name="lock-filled" className="text-white" />}
        </div>
      </div>
      <div className="flex items-end justify-between">
        <>
          {openMoreInfo ? (
            <button
              className="flex items-end text-cool-grey-350 gap-1"
              type="button"
              onClick={() => setOpenMoreInfo(true)}
            >
              <Icons name="info-outlined" className={"text-cool-grey-350"} />
              <div className="title-10 text-cool-grey-350">More info</div>
            </button>
          ) : (
            <>
              {section.media?.description ? (
                <button
                  className="flex items-end text-cool-grey-350 gap-1"
                  type="button"
                  onClick={() => setOpenMoreInfo(false)}
                >
                  <Icons
                    name="minus-circle-outlined"
                    className={"text-cool-grey-350"}
                  />
                  <div
                    id="more-info-text"
                    className="title-10 text-cool-grey-350"
                  >
                    Minimize
                  </div>
                </button>
              ) : (
                <div />
              )}
            </>
          )}
        </>

        <div className="flex gap-1">
          {/* <Tooltip
            tooltipContent={<p className="caption-disclaimer">Favorite</p>}
          >
            <button
              type="button"
              className="bg-black-4a button-text-medium rounded-[100px]"
              disabled={!owned}
            >
              <Icons
                name="bookmark-outlined"
                className={"h-6 w-6 text-white mr-1"}
              />
            </button>
          </Tooltip> */}
          <Tooltip tooltipContent={<p className="caption-disclaimer">Play</p>}>
            <button
              type="button"
              onClick={handlePlay}
              className="bg-black-4a button-text-medium rounded-[100px] opacity-80"
              disabled={!owned}
            >
              <Icons
                name="play-circle-filled"
                height={40}
                width={40}
                className="text-white mr-1"
              />
            </button>
          </Tooltip>
        </div>
      </div>
      {openMoreInfo && <hr className="text-cool-grey-500 mt-2" />}
      <div
        className={cn(
          "mt-2 body-small text-cool-grey-350",
          openMoreInfo ? "" : "hidden"
        )}
      >
        {section.media?.description}
      </div>
    </div>
  );
};

const GuideContent = ({
  section,
  owned = true,
  authorName,
  handleResourcePlay,
}: {
  section: Section;
  owned: boolean;
  authorName?: string;
  handleResourcePlay: (media: any) => void;
}) => {
  useEffect(() => {
    const links = document.querySelectorAll(".quest-guide-text-content a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
    });
  }, [section]);

  if (section.position === 1) return null;
  switch (section.type) {
    case "text":
      return (
        <div
          key={section.id}
          className="quest-guide-text-content"
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: section.info?.body
              ? DOMPurify.sanitize(section.info.body)
              : "",
          }}
        />
      );
    case "file":
      return <RenderFileContentType section={section} owned={owned} />;
    case "audio":
      return (
        <RenderAudioType
          section={section}
          owned={owned}
          authorName={authorName}
          handleResourcePlay={handleResourcePlay}
        />
      );
    case "media":
      return (
        <RenderMediaType
          section={section}
          owned={owned}
          handleResourcePlay={handleResourcePlay}
        />
      );
    default:
      return null;
  }
};

export const SidebarGuideTabContent = ({
  page,
  onToggleTask,
  handleResourcePlay,
}: {
  page?: Page;
  owned?: boolean;
  onToggleTask: (task: Task) => void;
  handleResourcePlay: (media: any) => void;
}) => {
  const tasks = page?.tasks;
  const questStarted = page?.quest?.userProgress?.started;

  return (
    <div className="pt-4 px-4 lg:pl-6 lg:pr-9 space-y-6 quest-guide-text-content">
      {page?.sections?.map((section) => (
        <GuideContent
          key={section.id}
          section={section}
          authorName={page.quest?.authors?.[0]?.name}
          owned={page.quest?.owned ?? true}
          handleResourcePlay={handleResourcePlay}
        />
      ))}
      {questStarted && tasks && tasks.length > 0 && (
        <div>
          <p className="title-7">
            {tasks?.filter((task) => task.completed).length}/{tasks?.length}{" "}
            tasks completed
          </p>
          <ul className="!pl-0">
            {tasks?.map((task) => (
              <li
                key={task.id}
                className="mt-4 border border-cool-grey-300 rounded-lg flex space-x-2 items-center py-4 px-6"
              >
                <button type="button" onClick={() => onToggleTask(task)}>
                  {task.completed && (
                    <div className="text-brown-700">
                      <Icons
                        name="checklist-circle-filled"
                        height={24}
                        width={24}
                      />
                    </div>
                  )}
                  {!task.completed && (
                    <div className="h-6 w-6 pr-1 cursor-pointer">
                      <span className="text-black-18a">
                        <Icons name="circle-filled" height={24} width={24} />
                      </span>
                    </div>
                  )}
                </button>

                <p className="body">{task.name}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="min-h-[20px]"> </div>
    </div>
  );
};
