import { useEffect, useRef } from "react";
import { createApp, type App } from "vue";
import { ENV_ENUMS } from "config/teamsConfig";
import MVSoundscapesWrapper from "./MVSoundscapesWrapper";
import MVUniversalPlayer from "@mindvalley/mv-universal-player";
import svgSprite from "@mindvalley/design-system/dist/icons/sprites/ui-icons.svg";
import type { Resource } from "types/__generated__/graphql";
import "./videoPlayer.css";

const iconPath =
  process.env.NODE_ENV === ENV_ENUMS.DEVELOPMENT
    ? ""
    : `${process.env.REACT_APP_TAB_ENDPOINT}/`;

const MVSoundscapeResource = ({
  media,
  nowPlayingTitle,
  muted,
  autoPlay,
  showCollection,
  onClose,
  handleCollectionOpen,
}: {
  media: Resource;
  nowPlayingTitle?: {
    title?: string;
    subtitle?: string;
  };
  muted: boolean;
  autoPlay: boolean;
  showCollection?: boolean;
  onClose: () => void;
  handleCollectionOpen?: () => void;
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const vueAppRef = useRef<App<Element> | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const app = createApp(MVSoundscapesWrapper, {
      media,
      nowPlayingTitle,
      muted,
      autoPlay,
      showCollection,
      onClose,
      handleCollectionOpen,
    });
    app.use(MVUniversalPlayer, {
      svgSpriteOptions: { url: `${iconPath}${svgSprite}` },
    });
    app.mount(containerRef.current);

    vueAppRef.current = app;

    return () => {
      if (vueAppRef.current) {
        vueAppRef.current.unmount();
      }
    };
  }, [
    media,
    nowPlayingTitle,
    muted,
    autoPlay,
    showCollection,
    handleCollectionOpen,
  ]);

  return <div ref={containerRef} className="mv-universal-player" />;
};

export default MVSoundscapeResource;
