import { authentication } from "@microsoft/teams-js";
import { UserAccessStateEnum } from "context/userContext";

export async function authLoginPopUp(authUrl: string) {
  try {
    const result = await authentication.authenticate({
      url: authUrl,
      width: 600,
      height: 535,
    });

    const data = JSON.parse(result);

    const accessToken = data.accessToken;
    const idToken = data.idToken;

    // const userProfile = jwtDecode(idToken);

    // Store tokens and user profile as needed
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("idToken", idToken);
    // localStorage.setItem("userProfile", JSON.stringify(userProfile));

    return accessToken;
  } catch (error) {
    console.error("Authentication failed:", error);
    return null;
  }
}

export const isAuthenticated = (userAccessState: UserAccessStateEnum): boolean => {
  return userAccessState === UserAccessStateEnum.Premium || userAccessState === UserAccessStateEnum.Freemium;
};
