import { defineComponent, h, type PropType } from "vue";
import {
  useGlobal,
  useDetectBrowser,
  MVAudioPlayer,
  MVAudioResource,
  MVMeditationMixer,
  MVMeditationVolumeSlider,
  MVMeditationTrackItem,
  MVAudioDescription,
} from "@mindvalley/mv-universal-player";
import type { Media, Rendition } from "types/__generated__/graphql";
import "vue3-carousel/dist/carousel.css";

const ALLOWED_MIME_TYPES = [
  "application/x-mpegURL",
  "video/mp4",
  "audio/mpeg",
  "audio/mp3",
  "audio/ogg",
];

interface Props {
  media: Media;
  handlePlayEvent?: () => void;
  handlePauseEvent?: () => void;
  handleEndedEvent?: () => void;
  defaultStyle?: boolean;
  startFullscreen?: boolean;
}

export default defineComponent({
  name: "MVUniversalAudioPlayerWrapper",
  props: {
    media: {
      type: Object as PropType<Media>,
      required: true,
    },
    autoPlay: {
      type: Boolean,
      required: false,
      default: true,
    },
    handlePlayEvent: {
      type: Function,
      required: false,
      default: () => {},
    },
    handlePauseEvent: {
      type: Function,
      required: false,
      default: () => {},
    },
    handleEndedEvent: {
      type: Function,
      required: false,
      default: () => {},
    },
    defaultStyle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props: Props) {
    const { formatSources } = useGlobal();
    const { isiPhoneOriPadSafari } = useDetectBrowser();

    const MediaSubType = {
      VOCAL_WITH_BACKGROUND: "vocal_with_background",
      VOCAL: "vocal",
      AMBIENT: "ambient",
      BACKGROUND: "background",
    };

    const settings = {
      itemsToShow: 4,
      snapAlign: "start",
    };

    const breakpoints = {
      540: { itemsToShow: 6, snapAlign: "start" },
      640: { itemsToShow: 8, snapAlign: "start" },
      1024: { itemsToShow: 7, snapAlign: "start" },
    };

    const vocal = "VOCAL";
    const sound = "SOUND";

    // Prepare background sounds
    const backgroundSounds = [];
    if (props.media?.supportingResource) {
      backgroundSounds.push({
        id: props.media.supportingResource.id,
        item: props.media.supportingResource,
        volume: 0.5,
      });
    }

    return {
      formatSources,
      isiPhoneOriPadSafari,
      MediaSubType,
      settings,
      breakpoints,
      backgroundSounds,
      vocal,
      sound,
    };
  },
  methods: {
    handlePlay() {
      if (this.handlePlayEvent && typeof this.handlePlayEvent === "function") {
        this.handlePlayEvent();
      }
    },
    handlePause() {
      if (
        this.handlePauseEvent &&
        typeof this.handlePauseEvent === "function"
      ) {
        this.handlePauseEvent();
      }
    },
    handleEnded() {
      if (
        this.handleEndedEvent &&
        typeof this.handleEndedEvent === "function"
      ) {
        this.handleEndedEvent();
      }
    },
    formattedSources(renditions: Array<Rendition | null> = []) {
      if (
        !renditions ||
        !Array.isArray(renditions) ||
        renditions.length === 0
      ) {
        return [];
      }

      return renditions
        .filter((rendition): rendition is Rendition => {
          if (!rendition) return false;

          const mimeType =
            rendition.contentType ||
            (rendition.name
              ? this.getMimeTypeFromName(rendition.name)
              : undefined);

          return !!mimeType && ALLOWED_MIME_TYPES.includes(mimeType);
        })
        .map((rendition) => {
          const mimeType =
            rendition.contentType ||
            this.getMimeTypeFromName(rendition.name || "");

          return {
            src: rendition.url || "",
            type: mimeType,
            size: Number.parseInt(rendition.sizeOfFile || "0", 10),
          };
        });
    },
    getMimeTypeFromName(filename: string) {
      if (!filename) return undefined;
      if (filename.endsWith(".mp3")) return "audio/mpeg";
      if (filename.endsWith(".ogg")) return "audio/ogg";
      if (filename.endsWith(".wav")) return "audio/wav";
      if (filename.endsWith(".m4a")) return "audio/mp4";
      return undefined;
    },
  },
  render() {
    const media = this.media;

    return h("section", { class: "mv-universal-player" }, [
      h(
        MVAudioPlayer,
        {},
        {
          default: () => [
            h(
              MVAudioResource,
              {
                assetId: media?.mediaAsset?.id || "",
                sources: this.formattedSources(
                  media?.mediaAsset?.renditions || []
                ),
                duration: media?.mediaAsset?.duration || 0,
                title: media?.title || "",
                posterUrl: media?.coverAsset?.url || "",
                artistName: media?.author?.name || "",
                totalRatings: media?.ratingsCount || 0,
                ratings: media?.averageRating || 0,
                class:
                  this.defaultStyle &&
                  "relative overflow-hidden p-6 lg:p-8 bg-cover bg-center",
                style: this.defaultStyle && {
                  backgroundImage: `url(${media?.coverAsset?.url || ""})`,
                },
                autoplay: true,
                blurEffect: this.defaultStyle,
                overlay: this.defaultStyle,
                onPlay: this.handlePlay,
                onPause: this.handlePause,
                onEnded: this.handleEnded,
              },
              {
                // Slots
                "meditation-mixer": () => {
                  if (
                    media?.subtype === this.MediaSubType.VOCAL_WITH_BACKGROUND
                  )
                    return null;

                  return h(
                    MVMeditationMixer,
                    {},
                    {
                      default: () => [
                        h(
                          "div",
                          { class: "mb-2 text-xs text-cool-grey-350" },
                          "Mix Track"
                        ),
                        h("div", { class: "px-6" }, [
                          h(
                            "div",
                            { class: "flex space-x-4 overflow-x-auto" },
                            [
                              h("div", { class: "flex-shrink-0" }, [
                                h(
                                  MVMeditationTrackItem,
                                  {
                                    volume: 0,
                                    isSelected: !media?.supportingResource,
                                  },
                                  () => "NO BG SOUND"
                                ),
                              ]),
                              ...this.backgroundSounds.map((sound, index) => {
                                const renditions =
                                  sound.item.mediaAsset?.renditions || [];

                                return h(
                                  "div",
                                  { key: index + 1, class: "flex-shrink-0" },
                                  [
                                    h(MVMeditationTrackItem, {
                                      isSelected:
                                        sound.id ===
                                        media?.supportingResource?.id,
                                      sources:
                                        this.formattedSources(renditions),
                                      backgroundSrc:
                                        sound.item.coverAsset?.url ||
                                        sound.item.coverAsset?.thumbnailUrl ||
                                        "",
                                      title: sound.item.title || "",
                                      volume: sound.volume || 0.5,
                                    }),
                                  ]
                                );
                              }),
                            ]
                          ),
                        ]),
                        !this.isiPhoneOriPadSafari
                          ? h(
                              "div",
                              {
                                class:
                                  "mt-4 flex w-full items-center justify-center transition duration-300 ease-in",
                              },
                              [
                                h(MVMeditationVolumeSlider, {
                                  leftText: this.sound,
                                  rightText: this.vocal,
                                }),
                              ]
                            )
                          : null,
                      ],
                    }
                  );
                },
                "audio-description": () =>
                  h(MVAudioDescription, {
                    imageSrc: media?.author?.portraitAsset?.url || "",
                    name: media?.author?.name || "",
                    headline: media?.author?.headline || "",
                    description: media?.description || "",
                    class: "mt-10",
                    showMoreText: "Show More",
                    showLessText: "Show Less",
                  }),
              }
            ),
          ],
        }
      ),
    ]);
  },
});
