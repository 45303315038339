import { Button } from "./Button";
import { useUserState } from "context/userContext";
import { MediaCardComponent } from "./MediaCardComponent";

import useScreenType from "hooks/useScreenType";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./Carousel";
import type { Resource } from "types/__generated__/graphql";
import { cn } from "libs/classMerger";

interface FeaturedListComponentProps {
  title: string;
  description: string;
  backdropImagePath: string;
  buttonLink: string;
  previewEnabled?: boolean;
  data: Resource[];
  onHover: (image: string) => void;
  onClick: (image: string) => void;
  onLeave: () => void;
  type: "meditations" | "soundscapes";
  handleMoreAction: () => void;
}

const FeaturedListComponent = ({
  title,
  description,
  backdropImagePath,
  previewEnabled = false,
  data,
  onHover,
  onLeave,
  onClick,
  type,
  handleMoreAction,
}: FeaturedListComponentProps) => {
  const screenType = useScreenType();
  const { userAccessState } = useUserState();

  const isMobile = screenType.isSmallMobile || screenType.isMobile || screenType.isTablet;

  return (
    <div
      className="p-12 lg:px-10 lg:py-16 bg-black/30 rounded-3xl bg-cover bg-no-repeat bg-center overflow-hidden"
      style={{
        background: `
        linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%),
        linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%),
        url('${backdropImagePath}')
      `,
        transition: "1s ease-in-out",
      }}
    >
      <div className="grid gap-10 z-10 h-full lg:mt-[79px]">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 lg:gap-4 w-full align-end">
          <div className="lg:col-span-4 h-full flex flex-col justify-end items-start gap-1 z-30">
            <div className="heading-2 text-white">{title}</div>
            <div className="body-small text-cool-grey-200">{description}</div>
            <div className="flex-col justify-start items-start hidden lg:flex mt-3">
              <Button
                variant="neutral"
                className="bg-white text-cool-grey-700 hover:bg-white-90a"
                onClick={handleMoreAction}
              >
                Discover more
              </Button>
            </div>
          </div>

          <div className="w-full lg:col-start-6 lg:col-span-8 relative">
            <div className="items-center flex">
              <Carousel
                opts={{
                  align: "start",
                  loop: false,
                  active: isMobile,
                  dragFree: !isMobile,
                }}
                orientation="horizontal"
                className="w-full"
              >
                {screenType.isTablet && (
                  <div className="mb-4 hidden md:block lg:hidden lg:col-span-1 z-30">
                    <div className="flex items-start justify-end space-x-2 ml-auto text-white">
                      <CarouselPrevious />
                      <CarouselNext />
                    </div>
                  </div>
                )}
                <CarouselContent className="-ml-4">
                  {data.map((item, index) => (
                    <CarouselItem
                      key={item.id}
                      className={cn(
                        "basis-1/2 md:basis-1/3 lg:basis-1/4 pl-4",
                        index === 0 && "pl-4",
                        index === data.length - 1 && "pr-0"
                      )}
                    >
                      <MediaCardComponent
                        key={item.id}
                        type={type}
                        onHover={(i) => onHover(i)}
                        onLeave={onLeave}
                        onClick={onClick}
                        userAccessState={userAccessState}
                        resource={item}
                        previewEnabled={previewEnabled && index === 0}
                        titleTextClasses="text-white"
                        authorTextClasses="text-white"
                        showDuration={type === "meditations"}
                        showDurationTag={type === "meditations"}
                        durationTextType="MM:SS"
                        overrideCardThumbnailStyle={
                          type === "soundscapes" ? "circle" : undefined
                        }
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
              </Carousel>
            </div>
          </div>
        </div>
        <div className={cn("grid lg:hidden md:w-fit z-30")}>
          <Button
            variant="neutral"
            className="bg-white text-cool-grey-700 hover:bg-white-90a"
            onClick={handleMoreAction}
          >
            Discover more
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedListComponent;
