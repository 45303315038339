import { Skeleton } from "../Skeleton"

export const StoriesSkeleton = () => {
    return <div className="xl:px-10 md:px-9 px-4 md:pb-8 pb-10">
        <div className="space-y-6">
            <div className="mt-4">
                <div className="flex justify-end gap-4 items-center" >
                    <Skeleton className="w-40 h-4 rounded-xl bg-brown-100 " />
                    <Skeleton className="w-40 h-6 md:h-8 lg:h-10 rounded-xl bg-brown-100" />
                </div>
            </div>

            <div className="lg:columns-3 md:columns-2 xs:columns-1 md:gap-4 space-y-4">
                {[...Array(15)].map((_, index) => (
                    <div className="break-inside-avoid">
                        <Skeleton className="bg-brown-50 rounded-2xl">
                            <div className="p-6 flex gap-2 flex-col w-full">
                                <Skeleton className="w-full h-3 rounded-xl bg-brown-100" />
                                <Skeleton className="w-3/4 h-3 rounded-xl bg-brown-100" />
                                <div className="flex space-x-3 pt-4 md:pt-6">
                                    <Skeleton className="rounded-md w-16 h-16l bg-brown-100" />
                                    <div className="flex flex-col justify-center gap-1">
                                        <Skeleton className="w-40 h-3 w-[167px] rounded-xl bg-brown-100" />
                                        <Skeleton className="w-40 h-3 w-[167px] rounded-xl bg-brown-100" />
                                        <Skeleton className="w-40 h-3 w-[167px] rounded-xl bg-brown-100" />
                                    </div>
                                </div>
                            </div>
                        </Skeleton>
                    </div>
                ))}
            </div>
        </div>
    </div>
}