import { useQuery } from "@apollo/client";
import { GET_MEDITATION_OF_THE_DAY } from "services/graphql/queries/dsApiQueries";

function useMeditationsOfTheDay({ prevData = [] }) {
  const { data, loading, error } = useQuery(GET_MEDITATION_OF_THE_DAY, {
    variables: {
      limit: 4,
      skip: prevData.length > 0,
    },
    context: {
      clientName: "rest",
    },
  });

  const meditations = data?.meditationOfTheDay || prevData;

  return { data: meditations, loading, error };
}

export default useMeditationsOfTheDay;
