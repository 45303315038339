import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { cn } from "libs/classMerger";
import "./tooltip.css";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = ({
  tooltipContent,
  children,
  delayDuration,
  isOpen,
  onOpenChange,
}: {
  tooltipContent: JSX.Element;
  children: JSX.Element;
  delayDuration?: number | undefined;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
}) => {
  return (
    <TooltipProvider>
      <TooltipPrimitive.Root
        delayDuration={delayDuration}
        open={isOpen}
        onOpenChange={onOpenChange}
      >
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent sideOffset={10} className="border-0">
          {tooltipContent}
        </TooltipContent>
      </TooltipPrimitive.Root>
    </TooltipProvider>
  );
};

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden max-w-[530px] rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 bg-black text-white",
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
