import { useCallback, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import { Carousel, CarouselContent, CarouselItem } from "components/Carousel";
import Icons from "components/icons/Icons";
import type { UseEmblaCarouselType } from "embla-carousel-react";
import useScreenType from "hooks/useScreenType";
import { getNextQuestNavigationUrl } from "utils/navigation";
import type { ICurrentProfile } from "types/interfaces";
import { cn } from "../../../libs/classMerger";
import { ContinueLearningBigCard } from "../components/continueLearning/ContinueLearningBigCard";
import { ContinueLearningNavigation } from "../components/continueLearning/ContinueLearningNavigation";
import { ContinueLearningSmallCard } from "../components/continueLearning/ContinueLearningSmallCard";
import type {
  B2bQuest,
  WorkwayQuestAssignment,
} from "/types/__generated__/graphql";
import React from "react";

export interface ContinueLearningSectionProps {
  profile?: ICurrentProfile;
  questsAssigned: WorkwayQuestAssignment[] | null;
  questsInProgress: B2bQuest[] | null;
}

type CarouselApi = UseEmblaCarouselType[1];

export const ContinueLearningSection = ({
  profile,
  questsAssigned = [],
  questsInProgress = [],
}: ContinueLearningSectionProps) => {
  const navigate = useNavigate();

  const [currentItem, setCurrentItem] = useState<number>(0);
  const carouselRef = useRef<any>(null);
  const setCarouselApi = useCallback((api: CarouselApi) => {
    carouselRef.current = api || null;
  }, []);

  const screenType = useScreenType();
  const [isMuted, setMuted] = useState(true);

  // function handleCardClick(clickedCourse: IQuestNode): void {
  //   const clickedIndex = questsInProgress.findIndex(
  //     (course: IQuestNode) => course.id === clickedCourse.id
  //   );

  //   if (clickedIndex === -1 || clickedIndex === currentItem) {
  //     return;
  //   }
  //   // carouselRef.current?.scrollTo(clickedIndex);
  //   setCurrentItem(clickedIndex);
  // }

  function nextPreviousArrowHandler(value: number) {
    if (!questsInProgress) return;

    const newIndex = Math.max(
      0,
      Math.min(currentItem + value, questsInProgress.length - 1)
    ); // Ensure index stays within bounds

    if (value > 0 && newIndex > currentItem) {
      carouselRef.current?.scrollNext();
    } else if (value < 0 && newIndex < currentItem) {
      carouselRef.current?.scrollPrev();
    }

    setCurrentItem(newIndex);
  }

  const hasMultipleCourses = questsInProgress && questsInProgress.length > 1;

  if (!questsInProgress || questsInProgress.length === 0) return <></>;

  return (
    <div className="max-w-2xl mx-auto px-5">
      <div className="heading-3">Welcome {`${profile?.firstName}`}</div>

      <div className="absolute right-0 h-full">
        <img src="/patterns/pattern-hero.svg" alt="pattern" />
      </div>
      <div className="relative w-full flex flex-nowrap flex-col gap-6 md:pr-0 mt-6">
        <div className="grid gap-6">
          <div className="flex md:grid md:grid-template-rows-[auto_auto] md:grid-cols-6 gap-x-6">
            <div className="w-full hidden md:flex md:row-span-2 md:col-span-4 lg:col-span-4 xl:col-span-3">
              <ContinueLearningBigCard
                quest={questsInProgress[currentItem]}
                questsAssigned={questsAssigned}
                isMuted={isMuted}
                setMuted={setMuted}
                screenType={screenType}
                handleContinueLearning={() =>
                  navigate(
                    getNextQuestNavigationUrl({
                      quest: questsInProgress[currentItem],
                    })
                  )
                }
              />
            </div>

            <div className="md:col-span-2 md:col-start-5 lg:col-start-5 lg:col-span-2 xl:col-start-4 xl:col-span-3 h-full">
              <div
                className="grid overflow-visible"
                style={{
                  clipPath: "inset(0 -9999px 0 0)",
                  WebkitClipPath: "inset(0 -9999px 0 0)",
                }}
              >
                {hasMultipleCourses && (
                  <Carousel
                    opts={{
                      align: "start",
                    }}
                    className="pointer-events-none"
                    setApi={setCarouselApi}
                  >
                    <CarouselContent
                      ref={carouselRef}
                      className="-ml-6 xl:ml-0 xl:gap-6"
                    >
                      {questsInProgress
                        .map((item, index) => (
                          <React.Fragment key={item.id + (item.name ?? "")}>
                            <CarouselItem
                              className={cn(
                                "basis-full md:basis-1/2 lg:basis-1/2 xl:basis-1/2 2xl:basis-1/2 pl-6 xl:pl-0",
                                index === questsInProgress.length - 1 && "mr-0"
                              )}
                            >
                              {screenType.isMobile ? (
                                <ContinueLearningBigCard
                                  quest={item}
                                  questsAssigned={questsAssigned}
                                  isMuted={isMuted}
                                  setMuted={setMuted}
                                  screenType={screenType}
                                  handleContinueLearning={() =>
                                    navigate(
                                      getNextQuestNavigationUrl({
                                        quest: questsInProgress[currentItem],
                                      })
                                    )
                                  }
                                />
                              ) : (
                                <ContinueLearningSmallCard
                                  quest={item}
                                  questsAssigned={questsAssigned}
                                  currentItem={currentItem}
                                  handleContinueLearning={() =>
                                    navigate(
                                      getNextQuestNavigationUrl({
                                        quest: item,
                                      })
                                    )
                                  }
                                />
                              )}
                            </CarouselItem>
                            {questsInProgress.length >= 4 &&
                              index === questsInProgress.length - 1 && (
                                <CarouselItem
                                  className={cn(
                                    "basis-full md:basis-1/2 lg:basis-1/2 xl:basis-1/2 2xl:basis-1/2 ml-6 xl:ml-0",
                                    "grid items-end end-card rounded-3xl"
                                  )}
                                >
                                  <Link
                                    to={`${NavigationEnum.Library}`}
                                    className="pointer-events-auto"
                                  >
                                    <div className="w-full p-4">
                                      <div className="w-full grid px-4 py-5 items-center gap-0.5 grow rounded-lg bg-white-18a backdrop-blur-[6px] ">
                                        <p className="text-brown-800 caption-disclaimer">
                                          There's more ...
                                        </p>
                                        <div className="flex items-center gap-1">
                                          <p className="text-brown-900 heading-7">
                                            Go to my library
                                          </p>
                                          <Icons
                                            name="arrow-right-circle-outlined"
                                            width={24}
                                            height={24}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </CarouselItem>
                              )}
                          </React.Fragment>
                        ))
                        .slice(screenType.isMobile ? 0 : 1)}
                      {questsInProgress.length === 2 && (
                        <Link
                          to={NavigationEnum.Programs}
                          className="cursor-pointer"
                        >
                          <div className="bg-white/60 rounded-2xl overflow-hidden relative  min-w-[392px] h-full flex flex-col items-start justify-between">
                            <div className="px-4 flex flex-col items-start justify-center h-1/2">
                              <div className="flex items-center gap-4">
                                <div className="text-2xl font-semibold">
                                  Discover more
                                </div>
                                <div className="border-2 border-black rounded-full mt-1">
                                  <Icons
                                    name="arrow-right"
                                    width={14}
                                    height={14}
                                    fill="black"
                                  />
                                </div>
                              </div>
                              <div className="text-sm">
                                programs to take your skills to the next level
                              </div>
                            </div>
                            <div className="shape-image absolute right-0 w-1/3 h-full" />
                            <div className="w-full h-1/2 absolute bottom-0 card-background" />
                          </div>
                        </Link>
                      )}

                      {!screenType.isMobile && (
                        <>
                          <CarouselItem>
                            <div className="size-full hidden min-w-0 shrink-0 grow-0" />
                          </CarouselItem>
                          <CarouselItem>
                            <div className="size-full hidde min-w-0 shrink-0 grow-0" />
                          </CarouselItem>
                        </>
                      )}
                    </CarouselContent>
                  </Carousel>
                )}
              </div>
            </div>

            <div className="hidden xl:flex md:row-start-2 md:col-start-4 md:col-span-3">
              <ContinueLearningNavigation
                nextPerviousArrowHandler={nextPreviousArrowHandler}
                currentItem={currentItem}
                courses={questsInProgress}
              />
            </div>
          </div>
          <div className="flex xl:hidden">
            <ContinueLearningNavigation
              nextPerviousArrowHandler={nextPreviousArrowHandler}
              currentItem={currentItem}
              courses={questsInProgress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
