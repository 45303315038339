import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CarouselPlayerBanner } from "components/soundscapesAndMeditations/CarouselPlayerBanner";
import { CuratedCollections } from "components/soundscapesAndMeditations/CuratedCollections";
import { Collections } from "components/soundscapesAndMeditations/Collections";
import { CategoriesSection } from "../../components/soundscapesAndMeditations/CategoriesSection";
import { FeaturedCollectionSection } from "components/soundscapesAndMeditations/FeaturedCollection";
import { PopularInOrganisation } from "../../components/soundscapesAndMeditations/PopularInOrganisationSection";
import { SoundscapeMixerSection } from "./sections/SoundscapeMixerSection";
import useSoundscapesOfTheWeek from "hooks/useSoundscapesOfTheWeek";
import useChannelBySlug from "hooks/useChannelBySlug";
import useSoundscapePopularInTheOrg from "hooks/useSoundscapePopularInTheOrg";
import { CollectionHighlightStatusFilterEnum } from "types/__generated__/graphql";

export default function Soundscapes() {
  const exploreCuratedsRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [soundscapesOfTheWeek, setSoundscapesOfTheWeek] = useState([]);

  const {
    data: soundscapesOfTheWeekData,
    loading: fetchingSoundscapeOfTheWeek,
    error: soundscapeOfTheWeekFetchingError,
  } = useSoundscapesOfTheWeek({ prevData: soundscapesOfTheWeek });

  useEffect(() => {
    if (soundscapesOfTheWeekData && !fetchingSoundscapeOfTheWeek) {
      setSoundscapesOfTheWeek(soundscapesOfTheWeekData);
    }
  }, [soundscapesOfTheWeekData, fetchingSoundscapeOfTheWeek]);
  const {
    data: curratedCollectionsData,
    loading: fetchingCurratedCollections,
    error: curratedCollectionsFetchingError,
  } = useChannelBySlug({
    slug: "b2b-soundscape-collections",
    highlightStatus: CollectionHighlightStatusFilterEnum.HighlightedLevelOne,
  });

  const { data: highlightedCollectionsData } = useChannelBySlug({
    slug: "b2b-soundscape-collections",
    highlightStatus: CollectionHighlightStatusFilterEnum.HighlightedLevelTwo,
  });

  const { data: featuredCollections, loading: fetchingFeaturedCollections } =
    useChannelBySlug({
      slug: "b2b-soundscape-collections",
      highlightStatus: CollectionHighlightStatusFilterEnum.Featured,
    });

  const { data: popularInOrgSoundscapeData } = useSoundscapePopularInTheOrg();

  const {
    data: soundscapeCategoriesData,
    loading: fetchingSoundscapeCategories,
    error: soundscapeCategoriesFetchingError,
  } = useChannelBySlug({
    slug: "b2b-soundscape-categories",
    highlightStatus: CollectionHighlightStatusFilterEnum.All,
  });

  useEffect(() => {
    if (!fetchingSoundscapeOfTheWeek && soundscapesOfTheWeek)
      window.scrollTo({ top: 0, behavior: "smooth" });
  }, [fetchingSoundscapeOfTheWeek]);

  return (
    <section className="flex flex-col gap-12 md:gap-16 lg:gap-16">
      <div className="pt-4">
        <CarouselPlayerBanner
          type="soundscapes"
          bannerTitle="Soundscapes of the week"
          data={soundscapesOfTheWeek}
          isFetching={fetchingSoundscapeOfTheWeek}
          fetchingError={soundscapeOfTheWeekFetchingError}
          handleScrollNavigationRef={exploreCuratedsRef}
        />
      </div>

      <div className="flex flex-col gap-12 md:gap-16 lg:gap-16 max-w-2xl px-5 mx-auto w-full">
        <div
          ref={exploreCuratedsRef}
          className="flex flex-col gap-12 md:gap-16 lg:gap-16"
        >
          <CuratedCollections
            type="soundscapes"
            data={curratedCollectionsData}
            isFetching={fetchingCurratedCollections}
            fetchingError={curratedCollectionsFetchingError}
            handleViewCollectionNavigation={() =>
              navigate("/soundscapes/collections")
            }
          />
          {highlightedCollectionsData?.collections?.map((collection) => (
            <Collections
              key={collection?.id}
              type="soundscapes"
              collection={collection}
            />
          ))}
        </div>
      </div>
      <FeaturedCollectionSection
        type="soundscapes"
        data={featuredCollections}
        loading={fetchingFeaturedCollections}
        handleViewCollectionNavigation={() =>
          navigate("/soundscapes/collections")
        }
      />
      <div className="flex flex-col gap-12 md:gap-16 lg:gap-16 max-w-2xl px-5 mx-auto w-full">
        <PopularInOrganisation data={popularInOrgSoundscapeData} />
        <SoundscapeMixerSection />
      </div>

      <CategoriesSection
        data={soundscapeCategoriesData}
        loading={fetchingSoundscapeCategories}
        error={soundscapeCategoriesFetchingError}
        handleSeeAllNavigation={() => navigate("/soundscapes/categories")}
        handleCategoryNavigation={(slug) =>
          navigate(`/soundscapes/categories/${slug}`)
        }
        containerStyle="bg-teal-50"
      />
    </section>
  );
}
