import { Button } from "components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "components/Dropdown";
import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import type { B2bChannel, B2bCollection } from "types/__generated__/graphql";

export const CategoriesSelectorDropdownMenu = ({
  collectionsData,
  selectedData,
  handleCategoryChange,
}: {
  collectionsData: B2bChannel;
  selectedData: B2bCollection;
  handleCategoryChange: (category: B2bCollection) => void;
}) => {
  const collections = collectionsData?.collections || [];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="dropdown">
          {selectedData.title}
          <Icons name="chevron-down-filled" width={16} height={16} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-fit p-4 ">
        <ul
          role="menu"
          aria-label="Categories"
          className="grid grid-rows-4 grid-flow-col gap-y-4 gap-x-14"
        >
          {collections.map((category) => {
            const isSelected = selectedData.title === category.title;

            return (
              <DropdownMenuItem key={category.id} role="listitem">
                <Button
                  variant="gostLink"
                  size="link"
                  onClick={() => handleCategoryChange(category)}
                  className={cn(
                    "text-brown-700 !body-small",
                    isSelected && "font-semibold"
                  )}
                >
                  <div
                    className={cn(
                      "size-1.5 rounded-full mr-1.5",
                      isSelected ? "bg-red-600" : "bg-transparent"
                    )}
                  />
                  <span>{category.title}</span>
                </Button>
              </DropdownMenuItem>
            );
          })}
        </ul>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
