import { cn } from "libs/classMerger";

interface ResourceListItemProps {
    title: string;
    imageUrl?: string;
    authorName?: string;
    duration?: number;
    resourceType?: string;
    isPlaying?: boolean;
}

export default function ResourceListItem({
    title,
    imageUrl,
    authorName,
    duration,
    resourceType,
    isPlaying
}: ResourceListItemProps) {
    const isResourceTypeSoundscape = resourceType?.toLowerCase() === 'soundscapes';

    return (
        <div className="flex items-center gap-3 rounded-2xl p-2 hover:bg-gray-50">
            <div className="relative min-w-[90px]">
                <div className="relative">
                    <img
                        src={imageUrl}
                        alt={title}
                        className={cn(
                            "w-[90px] h-[90px] object-cover",
                            isResourceTypeSoundscape ? "rounded-full" : "rounded-lg"
                        )}
                    />
                    {isPlaying && (
                        <div className="absolute inset-0 flex items-center justify-center bg-black/50 text-white text-sm font-medium rounded-lg">
                            Now sharing
                        </div>
                    )}
                </div>
                {!isResourceTypeSoundscape && duration && (
                    <p className="absolute bottom-1 right-1 text-xs text-white bg-black/50 px-1.5 py-0.5 rounded">
                        {Math.ceil(duration / 60)} mins
                    </p>
                )}
            </div>
            <div className="flex flex-col gap-0.5">
                <p className="text-base font-medium text-cool-grey-700">{title}</p>
                {authorName && (
                    <p className="text-sm text-cool-grey-600">{authorName}</p>
                )}
            </div>
        </div>
    );
}