import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { GET_CATEGORIES } from "services/graphql/queries/index";
import type {
  ICategoryEdge,
  ICategoryNode,
  ICategoryNodeAdditionalInfo,
  QuestCategoryEnum,
} from "types/interfaces";
import { questBanners } from "../services/cdn/questsPageBanners";
import prodAdditionalInfo from "../services/mocks/category_node_additional_info/prod.json";
import freeCategories from "../services/mocks/freeCategories.json";

function useCategories() {
  const additionalInfo: ICategoryNodeAdditionalInfo = prodAdditionalInfo;

  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    skip: true,
  });

  const categoriesData = useMemo<ICategoryNode[]>(() => {
    const sourceData = data || freeCategories.data;

    return (
      sourceData?.categories?.edges?.map((edge: ICategoryEdge) => {
        const additional = additionalInfo[edge.node.id] || {
          title: "",
          description: "",
        };
        const name = edge.node.name.replace(/-B2B/g, "").trim();
        return {
          id: edge.node.id,
          name,
          assets: {
            banners: questBanners[edge.node.id as QuestCategoryEnum],
          },
          title: additional.title,
          description: additional.description,
          color: additional.color,
          pattern: additional.pattern,
        };
      }) || []
    );
  }, []);

  return { categoriesData, loading, error };
}

export default useCategories;
