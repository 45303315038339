import { useState } from "react";
import { ProgressBar } from "components/ProgressBar";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { QuestLandingPage } from "../QuestLanding/QuestLandingPage";
import type { B2bQuest } from "types/__generated__/graphql";

export const SidebarHeader = ({
  quest,
  onStartProgram,
  onLandinPageOpen,
}: {
  quest: B2bQuest;
  onStartProgram: () => void;
  onLandinPageOpen?: () => void;
}) => {
  const [landingPageOpen, setLandingPageOpen] = useState(false);

  const handleOpenLandingPage = () => {
    setLandingPageOpen(true);
    // onLandinPageOpen();
  };

  if (!quest) {
    return null;
  }
  const completedLessons =
    quest.type === "weekly"
      ? quest.userProgress?.totalLessonsCompleted
      : quest.userProgress?.totalDaysCompleted;
  const totalLessons =
    quest.type === "weekly"
      ? quest.userProgress?.totalLessons
      : quest.userProgress?.totalDays;

  return (
    <section className="px-4 pt-2 lg:pl-4 lg:pr-9 hidden lg:flex lg:flex-col w-full gap-1">
      {/* <QuestLandingPage
        questData={quest}
        questArgs={quest}
        trigger={
          <button
            type="button"
            className="w-full flex gap-3 items-center justify-between rounded-lg p-2 transition-colors ease-in hover:bg-brown-200"
          >
            <img
              className="rounded-md w-28"
              src={quest.coverAsset?.thumbnailUrl}
              alt={quest.name}
            />
            <div className="flex flex-col flex-1 text-left">
              <h4 className="heading-9 text-cool-grey-700">{quest.name}</h4>
              {quest.authors?.[0].name && (
                <p className="title-10 text-cool-grey-450">
                  {quest.authors?.[0].name}
                </p>
              )}
            </div>
            <Icons name="chevron-right-filled" />
          </button>
        }
      /> */}
      <button
        type="button"
        onClick={handleOpenLandingPage}
        className="w-full flex gap-3 items-center justify-between rounded-lg p-2 transition-colors ease-in hover:bg-brown-200"
      >
        <img
          className="rounded-md w-28"
          src={quest.coverAsset?.thumbnailUrl}
          alt={quest.name}
        />
        <div className="flex flex-col flex-1 text-left">
          <h4 className="heading-9 text-cool-grey-700">{quest.name}</h4>
          {quest.authors?.[0].name && (
            <p className="title-10 text-cool-grey-450">
              {quest.authors?.[0].name}
            </p>
          )}
        </div>
        <Icons name="chevron-right-filled" />
      </button>
      {!quest.userProgress?.started && (
        <Button variant="primary" className="gap-2" onClick={onStartProgram}>
          <Icons name="play-filled" />
          Start Program
        </Button>
      )}
      {quest.userProgress?.started && (
        <div className="flex flex-col items-center gap-2 self-stretch justify-center mt-0.5 mx-2">
          <ProgressBar
            progressClasses="bg-yellow-500"
            value={completedLessons}
            maxValue={totalLessons}
          />
          <div className="title-9 text-cool-grey-500 text-ellipsis line-clamp-1 self-stretch">
            {completedLessons} of {totalLessons} lessons completed
          </div>
        </div>
      )}
      <QuestLandingPage
        questData={quest}
        questArgs={quest}
        onOpenChange={setLandingPageOpen}
        open={landingPageOpen}
      />
    </section>
  );
};
