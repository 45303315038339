import {
  useGetb2bCollectionsQuery,
  type Getb2bCollectionsQueryVariables,
  type GetChannelBySlugQuery,
  B2bCollection,
} from "types/__generated__/graphql";

export type ChannelBySlug = GetChannelBySlugQuery["b2bChannelBySlug"];

function useCollections({
  channelIdentifiers,
  status,
  first = 1,
  limit = 6,
  resourceFeaturedStatus,
  resourceStatus,
  type,
  sortBy,
}: Getb2bCollectionsQueryVariables) {
  const variables: Getb2bCollectionsQueryVariables = {
    channelIdentifiers,
    status,
    first,
    limit,
    resourceFeaturedStatus,
    resourceStatus,
    type,
    sortBy,
  };

  const { data, loading, error, refetch } = useGetb2bCollectionsQuery({
    variables,
  });

  const collectionsData =
    data?.b2bCollections?.edges
      ?.map((edge) => edge?.node)
      .filter((node): node is NonNullable<typeof node> => Boolean(node)) ?? [];

  return {
    data: collectionsData as B2bCollection[],
    loading,
    error,
    refetch,
  };
}

export default useCollections;
