import { useEffect, useState } from "react";
import useHarmonizedMeditation from "hooks/useHamonizedMeditation";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import { MediaCardComponent } from "components/MediaCardComponent";
import { useUserState } from "context/userContext";
import useScreenType from "hooks/useScreenType";
import { cn } from "libs/classMerger";
import type { Resource } from "/types/__generated__/graphql";

export const SoundscapeMixerSection = () => {
  const { userAccessState } = useUserState();
  const screenType = useScreenType();
  const { data, loading } = useHarmonizedMeditation({
    limit: screenType.isLargeDesktop ? 7 : 6,
  });
  const [banner, setBanner] = useState("");
  const [selectedTrackBanner, setSelectedTrackBanner] = useState(banner);

  useEffect(() => {
    if (data?.[0]?.landscapeCoverAsset?.url && banner === "") {
      const firstItemBanner = data[0]?.landscapeCoverAsset?.url;
      setBanner(firstItemBanner);
      setSelectedTrackBanner(firstItemBanner);
    }
  }, [data]);

  if (!data || loading) return null;

  return (
    <div
      className="rounded-3xl bg-cover bg-center bg-no-repeat overflow-hidden"
      style={{
        backgroundImage: `
          linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), 
          linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, #000),
          url('${banner}')
        `,
        transition: "background-image 0.5s ease",
      }}
    >
      <div className="flex flex-col gap-6 md:gap-9 lg:gap-12 px-6 py-8 md:px-10 md:py-12">
        <div className="flex flex-col gap-1 w-auto md:max-w-[616px] lg:max-w-[803px]">
          <div className="title-10 text-teal-200">SOUNDSCAPE MIXER</div>
          <div className="heading-2 md:heading-1 lg:heading-1 xl:heading-1 text-white">
            Harmonize meditations with soundscapes
          </div>
          <div className="body-small text-cool-grey-200 w-auto md:max-w-[616px] lg:max-w-[400px]">
            Create your perfect meditation experience by blending calming
            soundscapes with guided meditations. Tailor your audio journey to
            match your mood and needs.
          </div>
        </div>

        <Carousel
          opts={{
            align: "start",
            active: screenType.isSmallMobile || screenType.isMobile || screenType.isTablet,
          }}
          orientation="horizontal"
          className="w-full"
        >
          <div className="hidden md:flex lg:hidden items-center justify-end space-x-2 text-cool-grey-250 hover:text-cool-grey-100">
            <CarouselPrevious />
            <CarouselNext />
          </div>
          <CarouselContent className="-ml-2 md:-ml-4 lg:-ml-6 mt-4">
            {data.map((item: Resource, index: number) => (
              <CarouselItem
                key={item.id}
                className={cn(
                  "basis-1/2 md:basis-1/4 lg:basis-1/6 2xl:basis-[14.28%] pl-2 md:pl-4 lg:pl-6",
                  index === data.collectionResources?.length - 1 && "pr-0"
                )}
              >
                <MediaCardComponent
                  resource={item}
                  type="meditations"
                  showDuration={true}
                  onHover={(image: string) => setBanner(image)}
                  onLeave={() => setBanner(selectedTrackBanner)}
                  onClick={(image: string) => setSelectedTrackBanner(image)}
                  userAccessState={userAccessState}
                  titleTextClasses="text-white title-8"
                  authorTextClasses="body-small text-cool-grey-350"
                  durationTextClasses="xl:block caption-disclaimer text-cool-grey-350"
                  durationSuffix="min"
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  );
};
