// stores/mediaPlayerStore.ts
import { create } from "zustand";
import type { Time } from "/@types/player";
import type { Resource } from "types/__generated__/graphql";
import { set } from "lodash";

interface MediaPlayerState {
  isPlaying: boolean;
  track: Resource | null;
  trackNowPlayingTitle?: string;
  trackNowPlayingSubtitle?: string;
  mixers: any | null;
  supportingMixer: any;

  setIsPlaying: (isPlaying: boolean) => void;
  setTrack: (track: Resource | null) => void;
  setTrackNowPlayingTitle: (title?: string) => void;
  setTrackNowPlayingSubtitle: (subtitle?: string) => void;
  setMixers: (mixers: any) => void;
  setSupportingMixer: (mixer: any) => void;
}

const useMediaPlayerStore = create<MediaPlayerState>((set) => ({
  // Playback States
  isPlaying: false,
  track: null,
  trackNowPlayingTitle: "",
  trackNowPlayingSubtitle: "",
  mixers: [],
  supportingMixer: null,

  setIsPlaying: (isPlaying) => set({ isPlaying }),
  setTrack: (track) => set({ track }),
  setTrackNowPlayingTitle: (title) => set({ trackNowPlayingTitle: title }),
  setTrackNowPlayingSubtitle: (subtitle) =>
    set({ trackNowPlayingSubtitle: subtitle }),
  setMixers: (mixers) => set({ mixers }),
  setSupportingMixer: (mixer) => set({ supportingMixer: mixer }),
}));

export default useMediaPlayerStore;
