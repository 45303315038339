import { useNavigate } from "react-router-dom";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { useEffect, useState } from "react";
import { cn } from "libs/classMerger";
import EmptyStateSearch from "./components/EmptyStateSearch";
import { searchQuests, searchMeditations, searchSoundscapes } from "./search";
import { Resource } from "types/__generated__/graphql";
import Preloader from "components/loading/Preloader";
import ResourceListItem from "./components/ResourceListItem";
import { SidepanelCard } from "./components/SidepanelCard";
import { useShareToStage } from "./hooks/useShareToStage";
import { app } from "@microsoft/teams-js";
import useGetResourceById from "hooks/useGetResourceById";

type Tab = "Programs" | "Meditations" | "Soundscapes";

type SearchResult = {
    id: string;
    slug: string;
    title?: string;
    name?: string;
    thumbnailUrl?: string;
    coverAssetUrl?: string;
    author?: { // Meditation and soundscape
        name: string;
    };
    authors?: [{ // Quest
        name: string;
    }];
    total_duration?: number;
};

export default function SearchView() {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTab, setSelectedTab] = useState<Tab>("Programs");
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [playingResourceId, setPlayingResourceId] = useState<string | null>(null);
    const [isInTeamsMeeting, setIsInTeamsMeeting] = useState(false);

    const { shareToStage } = useShareToStage({ setPlayingResourceId });
    const { fetchResource } = useGetResourceById();

    useEffect(() => {
        const initializeTeams = async () => {
            try {
                await app.initialize();
                const context = await app.getContext();
                setIsInTeamsMeeting(context.meeting?.id !== undefined);
            } catch (error) {
                console.error('Error initializing Teams:', error);
            }
        };
        initializeTeams();
    }, []);

    useEffect(() => {
        if (searchQuery) {
            handleSearch(searchQuery);
        }
    }, [selectedTab]);

    const handleSearch = async (query: string) => {
        setSearchQuery(query);
        if (!query) {
            setSearchResults([]);
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            let results;
            switch (selectedTab) {
                case "Programs":
                    results = await searchQuests(query);
                    break;
                case "Meditations":
                    results = await searchMeditations(query);
                    break;
                case "Soundscapes":
                    results = await searchSoundscapes(query);
                    break;
                default:
                    results = [];
            }
            setSearchResults(results.map((hit: any) => hit.document));
        } catch (err) {
            setError("An error occurred while searching. Please try again.");
            setSearchResults([]);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="h-full overflow-y-auto">
            <div>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row items-center gap-4 px-4">
                        <Button
                            variant="ghost"
                            size="icon"
                            className="shrink-0 bg-primary/10 hover:bg-primary/20 w-fit"
                            onClick={() => navigate(-1)}
                        >
                            <Icons name="arrow-left-outlined" height={24} width={24} />
                        </Button>

                        <div className="relative flex-1">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Icons name="search-outlined" height={20} width={20} className="text-cool-grey-400" />
                            </div>
                            <input
                                type="text"
                                className="block w-full pl-10 pr-10 py-2 border-b border-cool-grey-200 rounded-none focus:outline-none focus:ring-0 focus:border-primary"
                                placeholder="What's on your mind?"
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {searchQuery && (
                                <button
                                    type="button"
                                    onClick={() => handleSearch("")}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-cool-grey-600 hover:text-red-600"
                                >
                                    <Icons name="cross-outlined" height={20} width={20} />
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="flex overflow-x-auto hide-scrollbar px-4">
                        {["Programs", "Meditations", "Soundscapes"].map((tab) => (
                            <button
                                key={tab}
                                className={cn(
                                    "px-3 py-1.5 relative whitespace-nowrap text-sm font-medium transition-colors border rounded-lg mr-1",
                                    selectedTab === tab
                                        ? "text-white bg-cool-grey-700 border-cool-grey-700"
                                        : "text-cool-grey-700 border-cool-grey-700 hover:bg-cool-grey-50"
                                )}
                                onClick={() => setSelectedTab(tab as Tab)}
                            >
                                {tab}
                                {selectedTab === tab && (
                                    <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-transparent" />
                                )}
                            </button>
                        ))}
                    </div>

                    {/* Search results will be displayed here */}
                    <div className="mt-4">
                        {searchQuery ? (
                            <>
                                {isLoading ? (
                                    <Preloader showWhiteLogo={false} autoRedirect={false} />
                                ) : error ? (
                                    <p className="text-red-500 text-center">{error}</p>
                                ) : searchResults.length === 0 ? (
                                    <p className="text-cool-grey-500 text-center">No results found</p>
                                ) : (
                                    <div className="space-y-2">
                                        {searchResults.map((result) => (
                                            selectedTab === "Programs" ? (
                                                <div key={result.id} onClick={() => navigate(`/sidepanel/program/${result.slug}`)}>
                                                    <SidepanelCard
                                                        key={result.id}
                                                        item={{
                                                            name: result.title || result.name || '',
                                                            coverAsset: { url: result.coverAssetUrl },
                                                            authors: result.authors ? [{ name: result.authors?.[0].name }] : []
                                                        }}
                                                        category={{ title: "Quest" }}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    key={result.id}
                                                    onClick={() => isInTeamsMeeting ? (async () => {
                                                        try {
                                                            const { data } = await fetchResource({
                                                                variables: {
                                                                    id: result.id
                                                                }
                                                            });
                                                            const resource = data?.standaloneResource;
                                                            if (resource) {
                                                                shareToStage({
                                                                    id: resource.id,
                                                                    mediaUrl: resource.mediaAsset?.url || "",
                                                                    coverAssetUrl: resource.coverAsset?.url || ""
                                                                });
                                                            }
                                                        } catch (error) {
                                                            console.error('Error fetching resource:', error);
                                                        }
                                                    })() : result.id && setPlayingResourceId(result.id)}
                                                    className="cursor-pointer"
                                                >
                                                    <ResourceListItem
                                                        title={result.title || result.name || ''}
                                                        imageUrl={result.thumbnailUrl}
                                                        authorName={result.author?.name}
                                                        duration={result.total_duration}
                                                        resourceType={selectedTab}
                                                        isPlaying={playingResourceId === result.id}
                                                    />
                                                </div>
                                            )
                                        ))}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="flex flex-col items-center gap-4 mt-8">
                                <EmptyStateSearch />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
}