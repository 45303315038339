export function durationToMinutes(seconds = 0) {
  if (seconds < 60) {
    return `${Math.round(seconds)}s`;
  }
  return `${Math.round(seconds / 60)}`;
}

export const convertSecondsToReadableFormat = (seconds = 0): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const hoursString = hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""}` : "";
  const minutesString =
    minutes > 0 ? `${minutes} min${minutes > 1 ? "s" : ""}` : "";

  return [hoursString, minutesString].filter(Boolean).join(" ");
};

export function calculateTotalDurationInSeconds(
  hours: number,
  minutes: number
) {
  return hours * 3600 + minutes * 60;
}

export function secondsToMinutesFormat(seconds = 0): string {
  if (Number.isNaN(seconds) || seconds < 0) {
    return "0:00";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  // Add leading zero to seconds if needed
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  return `${minutes}:${formattedSeconds}`;
}
