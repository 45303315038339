import { useShallow } from "zustand/shallow";
import { Sheet, SheetClose, SheetContent } from "components/Sheet";
import Icons from "components/icons/Icons";
import { MediaCard } from "components/MediaCard";
import { useMediaPlayerHook } from "hooks/useMediaPlayer";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import useCollectionModalStore from "stores/collectionModalStore";
import type { Resource } from "types/__generated__/graphql";
import { cn } from "libs/classMerger";

// My time here is limited. The reason I put here on root is because both media and player need to access this modal. It a quick
// way to avoid modal duplication.
export const CollectionDetailModal = () => {
  const isPlaying = useMediaPlayerStore((state) => state.isPlaying);
  const setIsPlaying = useMediaPlayerStore((state) => state.setIsPlaying);
  const track = useMediaPlayerStore((state) => state.track);

  const { mediaDisplayType, collection, isOpen, toggleModal } =
    useCollectionModalStore(
      useShallow((state) => ({
        mediaDisplayType: state.mediaDisplayType,
        collection: state.collection,
        isOpen: state.isOpen,
        toggleModal: state.toggleModal,
      }))
    );

  const { playTrack } = useMediaPlayerHook();

  const handlePlayPause = async (trackData: Resource) => {
    if (track?.id === trackData.id) {
      if (isPlaying) {
        setIsPlaying(false);
      } else {
        setIsPlaying(true);
      }
      return;
    }

    playTrack({
      track: trackData,
      collection,
    });
  };

  // TODO:: we should have skelton loader for this to handle null values
  if (!collection) return null;

  return (
    <Sheet open={isOpen} onOpenChange={toggleModal}>
      <SheetContent
        side={"bottom"}
        className="bg-white shadow-zinc-700/10 ring-zinc-700/10 top-12 md:top-16 lg:top-20 rounded-2xl shadow-lg transition mx-auto lg:max-w-[1000px] xs:w-full xs:max-w-[1000px] !p-0 !rounded-t-3xl !rounded-b-none !overflow-y-scroll md:max-h-[calc(100dvh-64px)] max-h-[calc(100dvh-48px)] scrollbar-hide"
      >
        <div className="grid">
          <div
            className="w-[430px] md:w-auto h-[360px] md:h-[432px] lg:h-[563px] bg-cover bg-center flex flex-col justify-between"
            style={{
              backgroundImage: `url('${collection?.landscapeCoverAsset?.url}')`,
            }}
          >
            <div className="flex px-3 py-[16px]">
              <SheetClose asChild>
                <button
                  type="button"
                  className="flex items-center justify-between md:px-3 px-2 inset-x-0 md:py-1.5 py-1.5 text-center text-white"
                  data-testid="quest-back-button"
                >
                  <Icons
                    name="arrow-left-circle-outlined"
                    width={24}
                    height={24}
                    fill="#FFFFFF"
                  />
                  <div className="px-1 button-text-medium py-px hidden md:block">
                    Back
                  </div>
                </button>
              </SheetClose>
            </div>
            <div className="sticky top-20 z-10">
              <div className="flex py-4 px-4 md:px-6 absolute inset-x-0 bottom-0 bg-black/40 backdrop-blur items-center justify-between ">
                <div className="flex items-center gap-3">
                  <img
                    src={collection.coverAsset?.url ?? ""}
                    alt={collection.title ?? ""}
                    className="h-14 md:h-16 rounded-lg"
                  />
                  <div className="flex flex-col items-start gap-0.5">
                    <p className="xs:hidden md:block title-7 text-white">
                      {collection?.title}
                    </p>

                    <p className="body-small text-cool-grey-300">
                      There are {collection.collectionResources?.length}{" "}
                      {mediaDisplayType} from this collection
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cn("flex flex-col px-4 py-6 md:p-6 gap-6",
            isPlaying && "mb-6"
          )}>
            <div className="flex flex-col gap-3">
              <div className="heading-4">{collection.title}</div>
              <div className="body-small text-cool-grey-600">
                {collection.description}
              </div>
            </div>

            {mediaDisplayType === "meditations" && (
              <div className="py-2 border-b border-black-8a flex flex-row justify-between sticky z-10 top-0 bg-white">
                <div>
                  <div className="body-2-xs text-cool-grey-450 uppercase">
                    {mediaDisplayType} from collection
                  </div>
                  <div className="heading-9 text-cool-grey-600">
                    {collection.title}
                  </div>
                </div>
              </div>
            )}

            <div
              className={cn(
                "items-start content-start xs:gap-3 xs:grid-cols-1 md:gap-y-4 md:gap-x-10 md:grid-cols-2 lg:gap-y-6 xl:grid-cols-2 flex-wrap self-stretch grid",
                track && "pb-8 mb-8"
              )}
            >
              {collection.collectionResources?.map((item) => (
                <MediaCard
                  key={item.id}
                  item={item as Resource}
                  // @ts-expect-error TODO:: Update the type for the track component
                  currentTrack={track}
                  isPlaying={isPlaying}
                  thumbnailStyle={
                    mediaDisplayType === "soundscapes" ? "circle" : "square"
                  }
                  // @ts-ignore TODO:: Update the type for the track component
                  handlePlayPause={() => handlePlayPause(item)}
                />
              ))}
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
