import { useMemo } from "react";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
} from "components/Dialog";
import MVUniversalMediaPlayerReact from "components/MVPlayer/MVUniversalMediaPlayerReact";
import type { Resource, Media } from "types/__generated__/graphql";

export const MediaModal = ({
  media,
  open = false,
  onClose,
}: {
  media: Resource | Media | null;
  open?: boolean;
  onClose: () => void;
}) => {
  if (media?.type !== "video" || !media) return null;

  const memoizedMedia = useMemo(
    () => ({
      key: media?.id,
      id: media?.id,
      mediaAsset: media.mediaAsset,
      duration: media?.totalDuration,
      posterUrl: media.coverAsset?.url,
      title: media?.title,
      description: media?.description,
      artistId: media.author?.id,
      artistName: media.author?.name,
      authorImage: media.author?.avatarAsset?.url,
      headline: media?.author?.headline,
      ratings: media?.averageRating,
      totalRatings: media?.ratingsCount,
    }),
    [media]
  );

  return (
    <Dialog open={open}>
      <DialogContent
        className="z-[100] h-screen w-screen max-w-screen bg-cover bg-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-transparent"
        onInteractOutside={onClose}
      >
        <div
          className="size-full absolute top-0 left-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${media.coverAsset?.url})`,
          }}
        />
        <div className="size-full absolute top-0 left-0 overflow-y-auto w-full rounded-0 backdrop-blur-[125px] bg-black/60" />
        <DialogHeader className="z-50 absolute top-5 right-5">
          <DialogClose>
            <Button variant="icon" className="text-white" onClick={onClose}>
              <Icons name="x-filled" height={20} width={20} />
            </Button>
          </DialogClose>
        </DialogHeader>
        <div className="flex items-center justify-center min-h-screen">
          <MVUniversalMediaPlayerReact
            media={memoizedMedia}
            muted={false}
            autoplay={false}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
