import { useMemo } from "react";
import { cn } from "libs/classMerger";

interface ProgressBarProps {
  value?: number;
  maxValue?: number;
  progressClasses?: string;
  containerClasses?: string;
  progressColor?: string;
  ariaLabel?: string;
}

export const ProgressBar = ({
  value = 0,
  maxValue = 100,
  progressClasses = "",
  containerClasses = "",
  ariaLabel = "Progress bar",
}: ProgressBarProps) => {
  if (value < 0 || maxValue < 0) {
    console.error("ProgressBar: value and maxValue must be non-negative.");
    return null;
  }

  if (value > maxValue) {
    console.error("ProgressBar: value cannot exceed maxValue.");
    return null;
  }

  const percentage = useMemo(() => {
    if (maxValue === 0) return 0; // Avoid division by zero
    return ((maxValue - value) / maxValue) * 100;
  }, [value, maxValue]);

  return (
    <div
      className={cn(
        "w-full h-1 bg-cool-grey-300 rounded-full relative flex flex-col gap-2",
        containerClasses
      )}
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={maxValue}
      aria-label={ariaLabel}
    >
      <div
        className={cn(
          "absolute top-0 bottom-0 left-0 rounded-full bg-yellow-500",
          progressClasses
        )}
        style={{ right: `${percentage}%` }}
      />
    </div>
  );
};
