import type { Rendition } from "types/__generated__/graphql";

export const getRendition = (
  renditions: Rendition[] | null | undefined,
  preferredContentTypes: string[],
  warningMessage?: string
): { src: string; mimeType: string } | null => {
  if (!renditions || renditions.length === 0) {
    if (warningMessage) console.warn(warningMessage);
    return null;
  }

  const preferredRendition = renditions.find(
    (r) =>
      r.contentType && r.url && preferredContentTypes.includes(r.contentType)
  );

  if (preferredRendition?.url && preferredRendition.contentType) {
    return {
      src: preferredRendition.url,
      mimeType: preferredRendition.contentType,
    };
  }

  if (warningMessage) console.warn(warningMessage);

  return null;
};
