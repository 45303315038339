import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import { GET_TRENDING_GLOBALLY_QUESTS } from "services/graphql/queries/dsApiQueries";
import {
  useGetRecommendedQuestQuery,
  type GetRecommendedQuestQueryVariables,
  type B2bQuest,
} from "types/__generated__/graphql";

function useTrendingGloballyQuests(
  variables: Omit<GetRecommendedQuestQueryVariables, "orgId">
) {
  const { isAuthenticated, organizationData } = useUserState();

  const orgId = (isAuthenticated && organizationData?.id?.toString()) || "469"; // TODO: Default org id for freemium, I think should handle on context lvl

  const {
    data: publicData,
    loading: publicDataLoading,
    error: publicDataError,
  } = useQuery(GET_TRENDING_GLOBALLY_QUESTS, {
    skip: isAuthenticated,
    variables: {
      limit: 5,
    },
    context: {
      clientName: "rest",
    },
  });

  const {
    data: orgData,
    loading: orgDataLoading,
    error: orgDataError,
  } = useGetRecommendedQuestQuery({
    skip: !isAuthenticated,
    variables: {
      ...variables,
      orgId,
    },
  });

  const publicQuest: B2bQuest[] =
    publicData?.trendingGloballyRecommendations || [];

  const quests = orgData?.recommendedB2bQuests?.edges?.map(
    (edge) => edge?.node?.quest as B2bQuest
  );

  return {
    data: quests || publicQuest,
    loading: orgDataLoading || publicDataLoading,
    error: orgDataError || publicDataError,
  };
}

export default useTrendingGloballyQuests;
