import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import QuestsSkeleton from "components/loading/QuestsSkeleton";
import { QuestCard } from "components/quest/QuestCard";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import useTrendingGloballyQuests from "hooks/useTrendingGloballyQuests";
import useScreenType from "hooks/useScreenType";
import { cn } from "libs/classMerger";

export function RecommendedForYou() {
  const { userAccessState } = useUserState();
  const screenType = useScreenType();

  const isMobile = screenType.isSmallMobile || screenType.isMobile || screenType.isTablet;

  const { data: quests, loading: fetchingTrendingGloballyQuestsData } =
    useTrendingGloballyQuests({
      first: 10,
    });

  if (!quests || fetchingTrendingGloballyQuestsData) return <QuestsSkeleton />;

  if (!quests?.length)
    return <QuestsSkeleton infoHeader="No Quest available" />;

  const filteredQuests = screenType.isLargeDesktop
    ? quests
    : quests.slice(0, 4);

  return (
    <section className="bg-[#F9F8F4]">
      <div className="grid grid-cols-12 py-10 max-w-2xl mx-auto px-5">
        <div className="w-full col-span-12">
          <Carousel
            opts={{
              align: "start",
              loop: false,
              active: isMobile,
              dragFree: !isMobile,
            }}
            orientation="horizontal"
            className="w-full"
          >
            <div className="flex justify-between items-center">
              <div className="flex flex-col items-start gap-0.5">
                <div className="heading-7">
                  {userAccessState === UserAccessStateEnum.Premium
                    ? "Recommended"
                    : "Trending globally"}
                </div>
              </div>
              {(screenType.isSmallMobile || screenType.isMobile || screenType.isTablet) && (
                <div className="flex items-start space-x-2">
                  <CarouselPrevious />
                  <CarouselNext />
                </div>
              )}
            </div>

            <CarouselContent className="-ml-4 mt-6">
              {filteredQuests.map((quest, index) => (
                <CarouselItem
                  key={quest?.id}
                  className={cn(
                    "basis-full basis-1/2 md:basis-1/2 lg:basis-1/4 2xl:basis-1/5 pl-4",
                    index === filteredQuests.length - 1 && "pr-0"
                  )}
                >
                  <QuestCard
                    quest={quest}
                    canHover={
                      (screenType.isDesktop || screenType.isLargeDesktop) &&
                      userAccessState === UserAccessStateEnum.Premium
                    }
                    userAccessState={userAccessState}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </div>
      </div>
    </section>
  );
}
