import { Button } from "components/Button";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  DrawerClose,
} from "components/Drawer";
import Icons from "components/icons/Icons";
import type { B2bChannel, B2bCollection } from "types/__generated__/graphql";

export const CategoriesSelectorDrawerMenu = ({
  collectionsData,
  selectedData,
  handleCategoryChange,
}: {
  collectionsData: B2bChannel;
  selectedData: B2bCollection;
  handleCategoryChange: (category: B2bCollection) => void;
}) => {
  const collections = collectionsData?.collections || [];

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button variant="dropdown">
          {selectedData.title}
          <Icons name="chevron-down-filled" width={16} height={16} />
        </Button>
      </DrawerTrigger>
      <DrawerContent className="bg-white">
        <div className="mx-auto w-full">
          <DrawerHeader className="border-b border-black-12a px-4 py-3">
            <DrawerTitle className="text-center !heading-8-mobile">
              Categories
            </DrawerTitle>
          </DrawerHeader>
          <div className="p-4">
            <ul className="space-y-1.5">
              {collections.map((category) => (
                <DrawerClose key={category.id} asChild>
                  <li>
                    <Button
                      variant="gostLink"
                      size="link"
                      onClick={() => handleCategoryChange(category)}
                      className="flex items-center justify-between title-8-mobile p-2 w-full"
                    >
                      <span>{category.title}</span>
                      {selectedData.title === category.title && (
                        <Icons name="check-filled" width={16} height={16} />
                      )}
                    </Button>
                  </li>
                </DrawerClose>
              ))}
            </ul>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
