import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./Programs.css";
import useCategories from "hooks/useCategories";
import { useSortedLanguages } from "hooks/useLanguages";
import useQuest from "hooks/useQuest";
import useScreenType from "hooks/useScreenType";
import { cn } from "libs/classMerger";
import { useLocation, useSearchParams } from "react-router-dom";
import { QuestCategoryEnum } from "types/interfaces";
import type { ICategoryNode } from "types/interfaces";
import { QuestsCategorySection } from "./sections/QuestsCategorySection";
import { ProgramsHeroContainer } from "./components/ProgramsHeroContainer";
import { Button } from "components/Button";
import { LanguageType } from "types/__generated__/graphql";
import { LanguagePicker } from "./components/LanguagePicker";
import { LanguageMenu } from "./components/LanguageMenu";
import { MenuDrawer } from "components/MenuDrawer";

const useMenuSticky = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsScrolled(!entry.isIntersecting);
      },
      { threshold: 0 }
    );

    if (triggerRef.current) {
      observer.observe(triggerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return { isScrolled, menuRef, triggerRef };
};

export default function Programs() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category");
  const categoryView = useRef<HTMLDivElement>(null);
  const screenType = useScreenType();

  // State
  const [selectedTab, setSelectedTab] = useState<QuestCategoryEnum>(
    QuestCategoryEnum.Career
  );
  const [selectedLang, setSelectedLang] = useState("en");

  // Data fetching
  const {
    data: nonEnglishQuestsData,
    loading: fetchingNonEnglishQuestsData,
    fetchNextPage: fetchMoreNonEnglishQuests,
    pageInfo: nonEnglishPageInfo,
  } = useQuest({
    variables: {
      first: 20,
      category: selectedTab,
      language: selectedLang,
    },
    skippable: selectedLang === "en",
  });

  const {
    data: englishQuestsData,
    loading: fetchingEnglishQuestsData,
    fetchNextPage: fetchMoreEnglishQuests,
    pageInfo: englishPageInfo,
  } = useQuest({
    variables: {
      first: 20,
      category: selectedTab,
      language: "en",
    },
  });

  const { categoriesData, loading: categoryDataLoading } = useCategories();
  const { data: languages } = useSortedLanguages({
    first: 20,
    type: LanguageType.Preferred,
  });

  // Derived state
  const sortedLanguages = useMemo(() => {
    const selected = languages.find((lang) => lang.iso2Code === selectedLang);
    const others = languages.filter((lang) => lang.iso2Code !== selectedLang);
    return selected ? [selected, ...others] : languages;
  }, [selectedLang, languages]);

  const category = useMemo(
    () => categoriesData.find((cat) => cat.id === selectedTab),
    [categoriesData, selectedTab]
  );

  const bannerImage = useMemo(() => {
    return category?.assets?.banners[
      screenType.isDesktop
        ? "desktop"
        : screenType.isMobile
        ? "mobile"
        : "tablet"
    ];
  }, [category, screenType]);

  const languageSelected = languages.find(
    (lang) => lang.iso2Code === selectedLang
  );

  // Event handlers
  const onCategorySelect = useCallback(
    debounce((category: ICategoryNode) => {
      setSelectedTab(category.id as QuestCategoryEnum);
      categoryView.current?.scrollIntoView({ behavior: "smooth" });
    }, 300),
    []
  );

  const onLanguageSelect = useCallback(
    debounce((language: string) => {
      setSelectedLang(language);
    }, 300),
    []
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (location.state?.subPageId) {
      setSelectedTab(location.state.subPageId as QuestCategoryEnum);
    }
  }, [location]);

  useEffect(() => {
    if (categoriesData.length > 0 && categoryParam) {
      const categoryFound = categoriesData.find(
        (cat) => cat.id.toLowerCase() === categoryParam.toLowerCase()
      );
      if (categoryFound) {
        setSelectedTab(categoryFound.id as QuestCategoryEnum);
        setSearchParams({ category: "" });
      }
    }
  }, [categoryParam, categoriesData, setSearchParams]);

  const DesktopMenu = () => {
    const { isScrolled, menuRef, triggerRef } = useMenuSticky();

    return (
      <>
        <div ref={triggerRef} className="h-0 w-full hidden lg:block" />
        <div
          ref={menuRef}
          className={cn(
            "border-y border-cool-grey-200 bg-white z-40 w-full transition-all duration-200",
            isScrolled && "fixed top-0"
          )}
        >
          <div className="hidden lg:flex items-center max-w-2xl mx-auto px-5">
            <div
              className="flex w-full gap-9"
              data-testid="category-sticky-tabs-section"
            >
              {categoriesData?.map((programCategory: ICategoryNode) => (
                <div
                  key={programCategory.id}
                  className="flex flex-col items-center"
                >
                  <Button
                    key={programCategory.id}
                    variant="inherit"
                    onClick={() =>
                      setSelectedTab(programCategory.id as QuestCategoryEnum)
                    }
                    data-selected={programCategory.id === category?.id}
                    className={cn(
                      "flex h-full py-5 px-0 hover:border-b-2 border-cool-grey-300 rounded-none",
                      "data-[selected=true]:border-b-2 data-[selected=true]:border-red-300",
                      programCategory.name === "Career" && "text-blue-500",
                      programCategory.name === "Mindset" && "text-teal-500",
                      programCategory.name === "Collaboration" &&
                        "text-purple-500",
                      programCategory.name === "Soul" && "text-orange-500",
                      programCategory.name === "Health" && "text-green-500",
                      programCategory.name === "Intrapreneurship" &&
                        "text-yellow-500"
                    )}
                  >
                    <span className="heading-8 opacity-80">
                      {programCategory.name.toLowerCase()}
                    </span>
                  </Button>
                </div>
              ))}
            </div>

            <LanguagePicker
              languages={sortedLanguages}
              selectedLang={selectedLang}
              setSelectedLang={setSelectedLang}
            />
          </div>
        </div>
      </>
    );
  };

  const MobileMenu = () => {
    const { isScrolled, menuRef, triggerRef } = useMenuSticky();

    return (
      <>
        <div ref={triggerRef} className="h-0 w-full lg:hidden" />
        <aside
          ref={menuRef}
          className={cn(
            "flex lg:hidden py-2 bg-white px-3 z-40 transition-all duration-200",
            isScrolled && "fixed top-0 w-full"
          )}
        >
          <div className="w-full rounded-lg border border-md border-cool-grey-200 gap-1 flex">
            <MenuDrawer
              trigger={
                <button
                  type="button"
                  className="flex flex-col w-1/2 px-3 py-2 items-center"
                >
                  <div>
                    <h2 className="heading-9 text-cool-grey-400 uppercase">
                      By Category
                    </h2>
                    <h3
                      className={cn(
                        category?.name?.toLowerCase(),
                        "heading-8 mt-0.5 lowercase"
                      )}
                    >
                      <span>{category?.name}</span>
                    </h3>
                  </div>
                </button>
              }
            >
              <LanguageMenu
                currentOption={{
                  name: category?.name ?? "",
                  value: category?.id ?? "",
                }}
                title="Category"
                options={categoriesData.map(
                  (programCategory: ICategoryNode) => ({
                    value: programCategory.id,
                    name: programCategory.name.toLowerCase(),
                  })
                )}
                onSelectOption={(option) => {
                  const category = categoriesData.find(
                    (cat) => cat.id === option.value
                  );
                  if (category) onCategorySelect(category);
                }}
              />
            </MenuDrawer>
            <MenuDrawer
              trigger={
                <button
                  type="button"
                  className="relative flex items-center flex-col w-1/2 px-3 py-2 before:inline-block before:h-[calc(100%-16px)] before:bg-black-12a before:w-px before:absolute before:left-0 justify-center"
                >
                  <div>
                    <h2 className="heading-9 text-cool-grey-400 uppercase">
                      By Language
                    </h2>
                    <h3 className="title-9 text-cool-grey-600 mt-0.5">
                      {languageSelected?.label}
                    </h3>
                  </div>
                </button>
              }
            >
              <LanguageMenu
                currentOption={{
                  value: languageSelected?.iso2Code ?? "",
                  name: languageSelected?.label ?? "",
                }}
                title="Language"
                options={sortedLanguages.map((lang) => ({
                  value: lang.iso2Code ?? "",
                  name: lang.label ?? "",
                }))}
                onSelectOption={(option) => {
                  const languageFound = sortedLanguages.find(
                    (lang) => lang.iso2Code === option.value
                  );
                  if (languageFound)
                    onLanguageSelect(languageFound.iso2Code ?? "");
                }}
              />
            </MenuDrawer>
          </div>
        </aside>
      </>
    );
  };

  return (
    <div className="flex flex-col pt-4">
      <ProgramsHeroContainer
        categoriesData={categoriesData}
        onCategoryClick={onCategorySelect}
        loading={categoryDataLoading}
      />

      <DesktopMenu />
      <MobileMenu />

      <div ref={categoryView}>
        <div className="mt-8 max-w-2xl mx-auto px-5">
          <QuestsCategorySection
            category={category}
            bannerImage={bannerImage}
            selectedLanguageLabel={
              languages.find((lang) => lang.iso2Code === selectedLang)?.label
            }
            nonEnglishQuestsData={nonEnglishQuestsData}
            englishQuestsData={englishQuestsData}
            loading={fetchingEnglishQuestsData || fetchingNonEnglishQuestsData}
            fetchMoreQuests={
              selectedLang === "en"
                ? fetchMoreEnglishQuests
                : fetchMoreNonEnglishQuests
            }
            pageInfo={
              selectedLang === "en" ? englishPageInfo : nonEnglishPageInfo
            }
          />
        </div>
      </div>
    </div>
  );
}
