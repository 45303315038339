import { useState } from "react";
import {
  isMeditationMedia,
  isSoundscapeMedia,
  useMediaPlayerHook,
} from "hooks/useMediaPlayer";
import { cn } from "libs/classMerger";
import Icons from "./icons/Icons";
import { Tooltip } from "components/ui/tooltip";
import LockedModal from "./LockedModal";
import useMediaPlayerStore from "stores/mediaPlayerStore";
import { UserAccessStateEnum } from "context/userContext";
import { durationToMinutes, secondsToMinutesFormat } from "utils/time";
import type { Resource, B2bCollection } from "types/__generated__/graphql";
import type { MediaDisplayType } from "stores/collectionModalStore";

const getDefaultThumbnailCardStyle = (
  trackSubtype?: string
): "circle" | "square" => {
  if (trackSubtype) {
    if (isMeditationMedia(trackSubtype)) {
      return "square";
    }
    if (isSoundscapeMedia(trackSubtype)) {
      return "circle";
    }
  }

  return "square";
};

// PlayButton component (internal to this file)
interface PlayButtonProps {
  isCurrentTrack: boolean;
  isPlaying: boolean;
  cardOrientation?: "vertical" | "horizontal";
  isCardHovered?: boolean;
}

const PlayButton = ({
  isCurrentTrack,
  isPlaying,
  cardOrientation = "vertical",
  isCardHovered = false,
}: PlayButtonProps) => {
  const iconName = isCurrentTrack && isPlaying ? "pause-filled" : "play-filled";

  return (
    <Tooltip
      delayDuration={0}
      tooltipContent={<p className="caption-disclaimer">Play</p>}
      isOpen={isCardHovered}
    >
      <div
        className={cn(
          "flex items-center justify-center z-10 rounded-full transition-all duration-500 ease-in-out ",
          cardOrientation === "vertical" &&
            "size-10 p-2.5 mr-3 self-end gap-2.5 opacity-0 group-hover:opacity-100 group-hover:-translate-y-5 bg-cool-grey-700 text-white",
          cardOrientation === "horizontal" &&
            "flex size-9 border border-black-12a text-cool-grey-500 group-hover:bg-black-8a group-hover:text-cool-grey-700"
        )}
      >
        <Icons
          name={iconName}
          // fill={cardOrientation === "vertical" ? "white" : "black"}
          fill="inherit"
          height={20}
          width={20}
        />
      </div>
    </Tooltip>
  );
};

// Base props shared between both card types
interface MediaCardProps {
  type: MediaDisplayType;
  overrideCardThumbnailStyle?:
    | "circle"
    | "square"
    | "circle-with-blurred-background";
  cardOrientation?: "vertical" | "horizontal";
  resource: Resource;
  collectionsData?: B2bCollection;
  onHover?: (image: string) => void;
  onLeave?: () => void;
  onClick?: (image: string) => void;
  userAccessState: UserAccessStateEnum;
  containerClasses?: string;
  tileClasses?: string;
  bannerTile?: boolean;
  titleTextClasses?: string;
  authorTextClasses?: string;
  durationTextClasses?: string;
  showDuration?: boolean;
  showDurationTag?: boolean;
  durationTextType?: "MM:SS" | "minutes";
  durationSuffix?: string;
  previewEnabled?: boolean;
}

const VerticalMediaCard = ({
  userAccessState,
  resource,
  containerClasses,
  tileClasses,
  titleTextClasses,
  authorTextClasses,
  durationTextClasses,
  showDuration = false,
  showDurationTag = false,
  durationTextType = "minutes",
  durationSuffix = "",
  onHover,
  onLeave,
  onClick,
  handlePlayPause,
  handleKeyDown,
  isCurrentTrack,
  isPlaying,
  thumbnailCardStyle,
}: {
  handlePlayPause: () => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isCurrentTrack: boolean;
  isPlaying: boolean;
  thumbnailCardStyle: "circle" | "square" | "circle-with-blurred-background";
} & Omit<
  MediaCardProps,
  "cardOrientation" | "collectionsData" | "previewEnabled"
>) => {
  function durationTag() {
    const totalDuration = Number.parseInt(
      durationToMinutes(resource.mediaAsset?.duration),
      10
    );
    return totalDuration > 20
      ? `Over ${totalDuration} mins`
      : `Under ${totalDuration} mins`;
  }

  return (
    <div
      className={cn(
        "flex flex-col justify-start items-start gap-3 group size-full",
        containerClasses
      )}
      onMouseEnter={() => {
        if (onHover) onHover(resource?.landscapeCoverAsset?.url ?? "");
      }}
      onMouseLeave={() => {
        if (onLeave) onLeave();
      }}
      onClick={() => {
        if (onClick) onClick(resource.landscapeCoverAsset?.url ?? "");
        handlePlayPause();
      }}
      onKeyUp={handleKeyDown}
      tabIndex={0}
      role="button"
    >
      <div
        className={cn(
          "group flex flex-col justify-end cursor-pointer relative aspect-square size-full",
          tileClasses
        )}
      >
        <div
          className={cn(
            "absolute size-full overflow-hidden group",
            thumbnailCardStyle === "circle" && "rounded-full",
            thumbnailCardStyle === "square" && "rounded-2xl"
          )}
        >
          <div className="relative">
            <img
              src={resource.coverAsset?.url}
              alt={resource.title}
              className="size-full object-cover aspect-square group-hover:scale-110 ease-in-out duration-500 transform transition-all"
            />
          </div>

          {showDurationTag &&
            userAccessState === UserAccessStateEnum.Premium && (
              <div className="absolute bottom-0 w-full px-3 py-1 rounded-b-2xl bg-black/40 backdrop-blur-md justify-start items-start inline-flex">
                <div className="grow shrink basis-0 h-[15px] justify-start items-center flex">
                  <div className="justify-start items-center gap-1.5 flex">
                    <div className="scale-75 relative">
                      <Icons
                        name="past-outlined"
                        height={24}
                        width={24}
                        fill="white"
                        className="pointer-events-none"
                      />
                    </div>
                    <div className="text-white text-[10px] body-2xs leading-[15px] tracking-wide">
                      {durationTag()}
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>

        <div className="ml-auto">
          <PlayButton
            isCurrentTrack={isCurrentTrack}
            isPlaying={isPlaying}
            cardOrientation="vertical"
          />
        </div>
      </div>

      <div className="flex flex-col justify-start items-start gap-1">
        <div
          className={cn(
            "self-stretch text-cool-grey-700 title-8 text-ellipsis line-clamp-1",
            titleTextClasses
          )}
        >
          {resource.title}
        </div>

        {resource.author?.name && (
          <div
            className={cn(
              "body-small text-cool-grey-500 truncate",
              authorTextClasses
            )}
          >
            {resource.author?.name}
          </div>
        )}

        {resource.mediaAsset?.duration && showDuration && (
          <div
            className={cn(
              "text-cool-grey-200 leading-none tracking-wide flex caption-disclaimer",
              durationTextClasses
            )}
          >
            {durationTextType === "minutes"
              ? durationToMinutes(resource.mediaAsset.duration)
              : secondsToMinutesFormat(resource.mediaAsset.duration)}{" "}
            {durationSuffix}
          </div>
        )}
      </div>
    </div>
  );
};

const HorizontalMediaCard = ({
  resource,
  containerClasses,
  tileClasses,
  titleTextClasses,
  authorTextClasses,
  durationTextClasses,
  showDuration = false,
  showDurationTag = false,
  durationTextType = "minutes",
  durationSuffix,
  onHover,
  onLeave,
  onClick,
  handlePlayPause,
  handleKeyDown,
  isCurrentTrack,
  isPlaying,
  thumbnailCardStyle,
}: {
  handlePlayPause: () => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isCurrentTrack: boolean;
  isPlaying: boolean;
  thumbnailCardStyle: "circle" | "square" | "circle-with-blurred-background";
} & Omit<
  MediaCardProps,
  "cardOrientation" | "userAccessState" | "collectionsData" | "previewEnabled"
>) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={cn("flex flex-row items-center gap-3 group", containerClasses)}
      onMouseEnter={() => {
        setIsHovered(true);
        if (onHover) onHover(resource?.landscapeCoverAsset?.url ?? "");
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        if (onLeave) onLeave();
      }}
      onClick={() => {
        if (onClick) onClick(resource.landscapeCoverAsset?.url ?? "");
        handlePlayPause();
      }}
      onKeyUp={handleKeyDown}
      tabIndex={0}
      role="button"
    >
      <div
        className={cn(
          "group flex flex-col justify-end items-start cursor-pointer relative aspect-square",
          "size-[7.5rem]",
          tileClasses
        )}
      >
        <div
          className={cn(
            "absolute size-full overflow-hidden",
            thumbnailCardStyle === "circle" && "rounded-full",
            thumbnailCardStyle === "square" && "rounded-lg"
          )}
        >
          <img
            src={resource.coverAsset?.url}
            alt={resource.title}
            className="size-full object-cover group-hover:scale-110 ease-in-out duration-500 transform transition-all"
          />
        </div>
      </div>

      <div
        className={cn("flex-col w-full justify-start items-start gap-1 flex")}
      >
        <div
          className={cn(
            "self-stretch w-fit text-white title-8 text-ellipsis line-clamp-1",
            titleTextClasses
          )}
        >
          {resource.title}
        </div>

        {resource.author?.name && (
          <div
            className={cn(
              "body-small text-cool-grey-500 truncate",
              authorTextClasses
            )}
          >
            {resource.author?.name}
          </div>
        )}

        {resource.mediaAsset?.duration && showDuration && (
          <div
            className={cn(
              "leading-none tracking-wide hidden md:flex body-2-xs text-cool-grey-500 whitespace-nowrap",
              durationTextClasses
            )}
          >
            {durationTextType === "minutes"
              ? durationToMinutes(resource.mediaAsset.duration)
              : secondsToMinutesFormat(resource.mediaAsset.duration)}
          </div>
        )}
      </div>

      <div className="ml-auto">
        <PlayButton
          isCurrentTrack={isCurrentTrack}
          isPlaying={isPlaying}
          cardOrientation="horizontal"
          isCardHovered={isHovered}
        />
      </div>
    </div>
  );
};

export const MediaCardComponent = ({
  cardOrientation = "vertical",
  type,
  overrideCardThumbnailStyle,
  resource,
  collectionsData,
  onHover,
  onLeave,
  onClick,
  userAccessState,
  containerClasses,
  tileClasses,
  bannerTile = false,
  titleTextClasses,
  authorTextClasses,
  durationTextClasses,
  showDuration = false,
  showDurationTag = false,
  durationTextType = "minutes",
  durationSuffix = "",
  previewEnabled = false,
}: MediaCardProps) => {
  const { playTrack } = useMediaPlayerHook();
  const isPlaying = useMediaPlayerStore((state) => state.isPlaying);
  const setIsPlaying = useMediaPlayerStore((state) => state.setIsPlaying);
  const track = useMediaPlayerStore((state) => state.track);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const thumbnailCardStyle = overrideCardThumbnailStyle
    ? overrideCardThumbnailStyle
    : getDefaultThumbnailCardStyle(resource?.subtype);

  const isCurrentTrack =
    track?.id === resource.id && track?.title === resource.title;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      handlePlayPause();
    }
  };

  const handlePlayPause = async () => {
    if (!previewEnabled && userAccessState !== UserAccessStateEnum.Premium) {
      setIsModalOpen(true);
      return;
    }

    if (isCurrentTrack) {
      setIsPlaying(!isPlaying);
      return;
    }

    playTrack({
      track: resource,
      collection: collectionsData,
    });
  };

  if (!resource) return null;

  const sharedProps = {
    userAccessState,
    type,
    resource,
    bannerTile,
    containerClasses,
    tileClasses,
    titleTextClasses,
    authorTextClasses,
    durationTextClasses,
    showDuration,
    showDurationTag,
    durationTextType,
    durationSuffix,
    onHover,
    onLeave,
    onClick,
    handlePlayPause,
    handleKeyDown,
    isCurrentTrack,
    isPlaying,
    thumbnailCardStyle,
  };

  return (
    <>
      {cardOrientation === "vertical" ? (
        <VerticalMediaCard {...sharedProps} />
      ) : (
        <HorizontalMediaCard {...sharedProps} />
      )}

      <LockedModal
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        userAccessState={userAccessState}
      />
    </>
  );
};
