import { useUserState } from "context/userContext";
import {
  type RecommendedB2bSoundscapesPopularInTheOrgQueryVariables,
  useRecommendedB2bSoundscapesPopularInTheOrgQuery,
  type Resource,
} from "types/__generated__/graphql";

function useSoundscapePopularInTheOrg(first = 6) {
  const { organizationData } = useUserState();
  const variables: RecommendedB2bSoundscapesPopularInTheOrgQueryVariables = {
    orgId: organizationData?.id?.toString() ?? "0",
    first,
  };

  const { data, loading, error, refetch } =
    useRecommendedB2bSoundscapesPopularInTheOrgQuery({
      variables,
      fetchPolicy: "no-cache", // TODO:: There's caching issue with this query
    });

  const soundscapes: Resource[] | null =
    data?.recommendedB2bSoundscapesPopularInTheOrg?.edges
      ?.map((edge) => edge.node?.soundscape)
      .filter((soundscape): soundscape is Resource => soundscape != null) ??
    null;

  return {
    data: soundscapes,
    loading,
    error,
    refetch,
  };
}

export default useSoundscapePopularInTheOrg;
