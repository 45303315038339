import { useLogout } from "hooks/useLogout";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "./Button";
import Icons from "./icons/Icons";
import { NavigationEnum } from "router/navigationType";

enum DropdownSections {
  profile = "profile",
  support = "support",
  logout = "logout",
}

const cellStyle = "flex flex-col px-4 py-2 border-b border-cool-grey-200";
const menuStyle =
  "flex gap-3 py-2 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-2 rounded-lg text-center items-center";

const NavDropdownMenu = ({
  userData,
  organizationLogo,
  setIsMenuOpen,
  userAccessState,
}: {
  userData: any;
  organizationLogo?: string | null;
  setIsMenuOpen: () => void;
  userAccessState?: string;
}) => {
  const logout = useLogout();
  const navigate = useNavigate();

  const links = () => {
    return [
      {
        icon: <Icons name="user-outlined" height={20} width={20} />,
        label: "My profile",
        section: DropdownSections.profile,
        link: NavigationEnum.Account,
        onClick: setIsMenuOpen,
      },
      {
        icon: <Icons name="layers-outlined" height={20} width={20} />,
        label: "My library",
        section: DropdownSections.profile,
        link: NavigationEnum.Library,
        onClick: () => setIsMenuOpen,
      },
      {
        icon: <Icons name="life-buoy-help-outlined" height={20} width={20} />,
        label: "Support",
        section: DropdownSections.support,
        link: "https://support.getworkway.com/",
        onClick: () => setIsMenuOpen,
      },
      {
        icon: <Icons name="log-out-filled" height={20} width={20} />,
        label: "Log out",
        section: DropdownSections.logout,
        link: "/",
        onClick: () => logout(),
      },
    ];
  };

  const FreemiumNavDropDown = () => {
    return (
      <>
        {userData && (
          <div className={cellStyle}>
            <div className="title-9 text-cool-grey-600">{userData?.name}</div>
            <div className="body-2-xs text-cool-grey-500">
              {userData?.email}
            </div>
          </div>
        )}

        <div className="px-2 py-2 border-b border-cool-grey-200">
          <Button
            className="rounded-[8px] w-full button-text-small"
            onClick={() => navigate(NavigationEnum.InterestForm)}
          >
            Get Workway
          </Button>
        </div>
        <div className="px-2 py-2">
          <Link
            className={menuStyle}
            to="https://support.getworkway.com/"
            target="_blank"
          >
            <Icons name="life-buoy-help-outlined" height={20} width={20} />
            <div className="button-text-small"> Support</div>
          </Link>
          <Link to={"/"} key={"logout"} className={menuStyle} onClick={logout}>
            <Icons name="log-out-filled" height={20} width={20} />
            <div className="button-text-small">Logout</div>
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className="nav-dropdown-menu w-[288px] absolute z-50 right-0 top-10 border-cool-grey-200 border border-light rounded-2xl shadow-light bg-white">
      {userAccessState !== "freemium" ? (
        <>
          {organizationLogo && (
            <div className="py-3 px-4 border-b border-cool-grey-200">
              <img src={organizationLogo} alt="user" />
            </div>
          )}
          {userData && (
            <>
              <div className={cellStyle}>
                <div className="text-base font-semibold text-cool-grey-600">
                  {userData?.name}
                </div>
                <div className="text-sm text-cool-grey-500">
                  {userData?.email}
                </div>
              </div>
              {userData?.isAdmin && (
                <div className="px-4 py-2 border-b border-cool-grey-200">
                  <div className="py-2 text-center bg-cool-grey-200 rounded-lg text-cool-grey-600 hover:bg-red-8a hover:text-red-600 cursor-pointer">
                    <Link
                      className="body-sm font-semibold"
                      to={userData?.adminPanelURL}
                    >
                      Go to admin panel
                    </Link>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="px-2 py-2 border-b border-cool-grey-200">
            {links()
              .filter(({ section }) => section === DropdownSections.profile)
              .map((el) => (
                <Link
                  to={el.link}
                  key={el.label}
                  className="flex gap-3 py-2 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-2 rounded-lg"
                  onClick={el.onClick}
                >
                  <div>{el.icon}</div>
                  <div className="font-semibold">{el.label}</div>
                </Link>
              ))}
          </div>
          <div className="px-4 py-2">
            {links()
              .filter(({ section }) => section === DropdownSections.support)
              .map((el) => (
                <div
                  key={el.label}
                  className="flex gap-3 py-1 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-1 rounded-lg"
                >
                  <div>{el.icon}</div>
                  <div className="font-semibold">
                    <a target="_blank" rel="noreferrer" href={el.link}>
                      {el.label}
                    </a>
                  </div>
                </div>
              ))}
          </div>
          <div className="px-4 ">
            {links()
              .filter(({ section }) => section === DropdownSections.logout)
              .map((el) => (
                <Link
                  to={el.link}
                  key={el.label}
                  className="flex gap-3 py-1 mb-2 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-1 rounded-lg"
                  onClick={el.onClick}
                >
                  <div>{el.icon}</div>
                  <div className="font-semibold">{el.label}</div>
                </Link>
              ))}
          </div>
        </>
      ) : (
        <FreemiumNavDropDown />
      )}
    </div>
  );
};

export default NavDropdownMenu;
