import { Button } from "components/Button";
import Icons from "components/icons/Icons";

export const ViewCollectionButton = ({
  handleViewCollection,
  handleNavigate,
}: {
  handleViewCollection: () => void;
  handleNavigate: () => void;
}) => {
  return (
    <div className="flex gap-1">
      <Button
        variant="inherit"
        onClick={handleViewCollection}
        className="flex rounded-100 items-center justify-center enabled:cursor-pointer text-center focus:outline-none text-cool-grey-700 bg-white enabled:hover:bg-white/90 disabled:bg-white/10 disabled:text-white/30 button-text-medium h-10 px-3.5 py-3 w-full md:w-auto"
      >
        <Icons name="list-filled" width={20} height={20} fill="black" />
        <p className="px-[6px]">View collection</p>
      </Button>
      <Button
        variant="ghost"
        size="roundedIcon"
        onClick={handleNavigate}
        className="bg-brown-12a hover:bg-brown-24a text-white w-10 h-10"
      >
        <Icons name="arrow-right-filled" width={20} height={20} fill="white" />
      </Button>
    </div>
  );
};
