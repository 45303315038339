import { useUserState } from "context/userContext";
import { GET_RESOURCE_BY_ID } from "services/graphql/queries/index";
import { useQuery } from "@apollo/client";

function useGetResourceById() {
  const { isAuthenticated } = useUserState();

  const { loading, error, data, refetch } = useQuery(GET_RESOURCE_BY_ID, {
    skip: !isAuthenticated,
    fetchPolicy: "cache-and-network",
  });

  const fetchResource = ({ variables }: { variables: { id: string } }) => {
    return refetch(variables);
  };

  return { fetchResource, data, loading, error };
}

export default useGetResourceById;
