import { useEffect, useState } from "react";

export const DEFAULT_BREAKPOINTS = {
  smallMobile: 375,
  mobile: 480,
  tablet: 768,
  tabletLandscape: 992,
  desktop: 1280,
  largeDesktop: 1800,
};

type BreakpointConfig = typeof DEFAULT_BREAKPOINTS;
type ScreenType = {
  isSmallMobile: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isTabletLandscape: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
};

export const calculateScreenType = (
  width: number,
  breakpoints: BreakpointConfig
): ScreenType => ({
  isSmallMobile: width < breakpoints.smallMobile,
  isMobile: width >= breakpoints.smallMobile && width < breakpoints.tablet,
  isTablet: width >= breakpoints.tablet && width < breakpoints.tabletLandscape,
  isTabletLandscape:
    width >= breakpoints.tabletLandscape && width < breakpoints.desktop,
  isDesktop: width >= breakpoints.desktop && width < breakpoints.largeDesktop,
  isLargeDesktop: width >= breakpoints.largeDesktop,
});

function useScreenType(
  breakpoints: BreakpointConfig = DEFAULT_BREAKPOINTS
): ScreenType {
  const [screenType, setScreenType] = useState<ScreenType>(() => {
    // Safe default for SSR
    if (typeof window === "undefined") {
      return {
        isSmallMobile: false,
        isMobile: false,
        isTablet: false,
        isTabletLandscape: false,
        isDesktop: true,
        isLargeDesktop: false,
      };
    }
    return calculateScreenType(window.innerWidth, breakpoints);
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenType(calculateScreenType(window.innerWidth, breakpoints));
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoints]);

  return screenType;
}

export default useScreenType;
