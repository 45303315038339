import {
  useEnrollUserMutation,
  useMarkPageAsCompletedMutation,
  useToggleTaskCompletionMutation,
  useUnenrollUserMutation,
  type B2bQuest,
  type Task,
} from "types/__generated__/graphql";
import { useToast } from "hooks/use-toast";
import { useApolloClient } from "@apollo/client";

const useQuestMutations = (
  quest?: B2bQuest,
  refetchFn?: () => Promise<any>
) => {
  const client = useApolloClient();
  const { toast } = useToast();

  // ENROLLMENT
  const [enrollUser, { loading: enrollLoading, error: enrollError }] =
    useEnrollUserMutation();

  const [
    unenrollUserMutation,
    {
      data: unenrollUserResult,
      loading: unenrollLoading,
      error: unenrollError,
    },
  ] = useUnenrollUserMutation();

  const [
    markPageAsCompleted,
    { loading: markPageLoading, error: markPageError },
  ] = useMarkPageAsCompletedMutation();

  const [
    toggleTaskCompletion,
    { loading: markTaskLoading, error: markTaskError },
  ] = useToggleTaskCompletionMutation();

  async function handleMarkPageAsCompleted(pageId?: string) {
    if (!pageId) {
      toast({
        variant: "destructive",
        description: "Cannot mark page as completed: Missing page ID.",
      });
      return { success: false };
    }

    try {
      const res = await markPageAsCompleted({
        variables: { input: { pageId } },
      });

      if (refetchFn) await refetchFn();

      // toast({
      //   description: "Page completed!",
      // });

      return {
        success: true,
        nextPage: res.data?.markPageAsCompleted?.result?.nextPage,
      };
    } catch (error) {
      console.error("Error marking page as completed:", error);
      toast({
        variant: "destructive",
        description: "Failed to mark page as completed. Please try again!",
      });

      return { success: false };
    }
  }

  async function handleToggleTaskCompletion(task: Task) {
    const taskId = task.id;
    const isCompleted = task.completed;

    if (!taskId) {
      toast({
        variant: "destructive",
        description: "Cannot mark task as completed: Missing task ID.",
      });
      return { success: false };
    }

    try {
      await toggleTaskCompletion({
        variables: {
          input: { taskId },
        },
        optimisticResponse: {
          toggleTaskCompletion: {
            __typename: "ToggleTaskCompletionPayload",
            successful: true,
            messages: [],
            result: {
              __typename: "TaskCompletion",
              id: Number.parseInt(taskId),
              completed: !isCompleted,
              task: {
                __typename: "Task",
                id: taskId,
                completed: !isCompleted,
              },
            },
          },
        },
      });

      if (refetchFn) await refetchFn();

      return {
        success: true,
      };
    } catch (error) {
      toast({
        variant: "destructive",
        description: "Failed to update task completion status.",
      });
      console.error("Task completion error:", error);
      return { success: false };
    }
  }

  const handleStartQuest = async ({
    questId,
    releaseId,
  }: {
    questId?: string;
    releaseId?: string;
  }) => {
    if (!questId) {
      toast({
        variant: "destructive",
        description: "Cannot start quest: Missing quest ID.",
      });
      return { success: false };
    }

    if (!releaseId) {
      toast({
        variant: "destructive",
        description: "Cannot start quest: No release information available.",
      });
      return { success: false };
    }

    // toast({
    //   description: "Starting quest...",
    // });

    try {
      await enrollUser({
        variables: {
          input: {
            releaseId,
          },
        },
      });

      if (refetchFn) await refetchFn();

      // toast({
      //   description: "Quest started successfully!",
      // });

      return {
        success: true,
      };
    } catch (error) {
      console.error("Failed to start quest:", error);
      toast({
        variant: "destructive",
        description: "Failed to start quest. Please try again later.",
      });
      return { success: false };
    }
  };

  const handleRestartQuest = async ({
    questId,
    releaseId,
  }: {
    questId?: string;
    releaseId?: string;
  }) => {
    if (!questId) {
      toast({
        variant: "destructive",
        description: "Cannot restart quest: Missing quest ID.",
      });
      return { success: false };
    }

    if (!releaseId) {
      toast({
        variant: "destructive",
        description: "Cannot restart quest: No release information available.",
      });
      return { success: false };
    }

    // toast({
    //   description: "Restarting quest...",
    // });

    try {
      // First step: unenroll user
      await unenrollUserMutation({
        variables: {
          input: {
            questId,
          },
        },
      });

      //NOTE:: PLATFORM SHOULD  FIX THIS. NEED SOFT DELETION OR SOMETHING FOR USER PROGRESS

      try {
        // Second step: re-enroll user
        await enrollUser({
          variables: {
            input: {
              releaseId: releaseId,
            },
          },
        });

        // Success: both operations completed successfully
        if (refetchFn) await refetchFn();
        toast({
          description: "Quest restarted successfully!",
        });

        return {
          success: true,
        };
      } catch (enrollError) {
        console.error("Failed to re-enroll after unenrolling:", enrollError);
        toast({
          variant: "destructive",
          title: "Partial Failure",
          description:
            "You were unenrolled but couldn't be re-enrolled automatically. Please try enrolling manually.",
        });

        if (refetchFn) await refetchFn();
        return { success: false };
      }
    } catch (unenrollError) {
      console.error("Failed to unenroll user:", unenrollError);
      toast({
        variant: "destructive",
        description: "Failed to restart quest. Please try again later.",
      });
      return { success: false };
    }
  };

  const handleLeaveQuest = async (quest: B2bQuest) => {
    if (!quest.id) {
      toast({
        variant: "destructive",
        description: "Cannot unenroll from quest: Missing quest ID.",
      });
      return { success: false };
    }

    const questId = quest.id;

    // toast({
    //   description: "Unenrolling from quest...",
    // });

    try {
      await unenrollUserMutation({
        variables: {
          input: {
            questId,
          },
        },
      });

      if (refetchFn) await refetchFn();

      // toast({
      //   description: "Successfully unenrolled from quest.",
      // });

      return {
        success: true,
      };
    } catch (error) {
      console.error("Failed to unenroll from quest:", error);
      toast({
        variant: "destructive",
        description: "Failed to unenroll. Please try again later.",
      });
      return { success: false };
    }
  };

  return {
    handleMarkPageAsCompleted,
    handleToggleTaskCompletion,
    handleStartQuest,
    handleRestartQuest,
    handleLeaveQuest,
    enrollLoading,
    markPageLoading,
    markTaskLoading,
    enrollError,
    markPageError,
    markTaskError,
  };
};

export default useQuestMutations;
