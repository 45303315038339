import { MyLibraryListSkeleton } from "components/loading/MyLibraryListSkeleton";
import { CardVariant3 } from "components/quest/QuestCardVariant3";
import { QuestStatus } from "types/interfaces";
import useLibrary from "hooks/useLibrary";
import EmptyState from "../components/EmptyState";
import { Button } from "components/Button";
import { useState } from "react";
import { cn } from "libs/classMerger";
import useQuestsByProgress from "hooks/useQuestsByProgress";
import { ResourceTypeFilterEnum } from "types/__generated__/graphql";
import useScreenType from "hooks/useScreenType";
import { QuestMediationLanding } from "tabs/Meditations/sections/QuestMeditationLanding";
import { useLocation } from "react-router-dom";

enum Categories {
  ALL = "All",
  QUEST = "Quests",
  MEDITATION = "My Quest meditations",
}

const InProgress = () => {
  const location = useLocation();
  const initialCategory = location.pathname.includes("/ongoing")
    ? Categories.MEDITATION
    : Categories.QUEST;
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const screenType = useScreenType();
  const isMobile = screenType.isSmallMobile || screenType.isMobile || screenType.isTablet;

  const { data: quests, loading } = useLibrary({
    first: 30,
    status: "ongoing",
  });

  const { data: questsByProgressMeditationData } = useQuestsByProgress({
    progress: QuestStatus.InProgress,
    first: 20,
    type: ResourceTypeFilterEnum.VocalMeditation,
  });

  if (loading) return <MyLibraryListSkeleton />;

  const menuButton = (title: Categories, style: string) => (
    <Button
      key={title}
      id={title}
      variant="outline"
      size="xs"
      data-selected={title === selectedCategory}
      className={style}
      onClick={() => setSelectedCategory(title)}
    >
      {title}
    </Button>
  );

  const menuBar = (
    <ul className="flex gap-1 flex-wrap">
      {/* {menuButton(Categories.ALL, "!title-9")} */}
      <div
        className={cn(
          "flex flex-row rounded-lg border border-black-12a h-9 group",
          (selectedCategory === Categories.QUEST ||
            selectedCategory === Categories.MEDITATION) &&
          "bg-brown-100"
        )}
      >
        {menuButton(
          Categories.QUEST,
          cn(
            "!title-9 hover:bg-brown-100 border-0",
            Categories.MEDITATION === selectedCategory && "hover:bg-brown-200",
            Categories.QUEST === selectedCategory && "text-white bg-brown-800"
          )
        )}
        {(Categories.QUEST === selectedCategory ||
          Categories.MEDITATION === selectedCategory) &&
          menuButton(
            Categories.MEDITATION,
            "!title-9 border-0 hover:bg-brown-200"
          )}
      </div>
    </ul>
  );

  return (
    <div className="flex flex-col gap-6 pb-6">
      <p className="heading-5 hidden lg:block">In progress</p>
      {menuBar}
      {quests && quests.length === 0 ? (
        <EmptyState section="in-progress" />
      ) : (
        <div className="items-stretch space-y-1">
          {(selectedCategory === Categories.ALL ||
            selectedCategory === Categories.QUEST) && (
              <div className={"flex flex-col w-full gap-5"}>
                {quests?.map((quest) => (
                  <CardVariant3 key={quest.id} quest={quest} />
                ))}
              </div>
            )}
          {selectedCategory === Categories.MEDITATION && (
            <div className="flex w-full overflow-hidden">
              <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6">
                {questsByProgressMeditationData
                  ?.filter((quest) => quest.questResources.length > 0)
                  ?.map((quest) => (
                    <div key={quest?.id} className="basis-full">
                      <div className="relative">
                        <img
                          src={quest.headshotCoverAsset?.url}
                          alt={quest.name}
                          className="size-full rounded-2xl"
                        />
                        <div className="flex w-full items-center absolute rounded-b-2xl bottom-0 p-4 gap-2 backdrop-blur-[6px] bg-black/40">
                          <img
                            src={quest.coverAsset?.thumbnailUrl}
                            alt="thumbnail"
                            className="rounded-lg h-[48px]"
                          />
                          <div className="flex flex-col w-full items-center">
                            <h3 className="title-8 text-white w-full line-clamp-1">
                              {quest.name}
                            </h3>
                            <h3 className="caption-disclaimer text-cool-grey-300 w-full">
                              {quest.questResources.length}{" "}
                              {quest.questResources.length > 1
                                ? "tracks"
                                : "track"}
                            </h3>
                          </div>
                          <QuestMediationLanding quest={quest} />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InProgress;
