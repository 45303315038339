import React, { useEffect } from "react";
import Lottie from "lottie-react";
import RedWingTransparent from "./RedWingTransparent.json";
import WhiteWingTransparent from "./WhiteWingTransparent.json";
import { useLocation, useNavigate } from "react-router-dom";

const Preloader = ({
  showWhiteLogo = true,
  autoRedirect = true,
}: {
  showWhiteLogo?: boolean;
  autoRedirect?: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const animationData = showWhiteLogo ? WhiteWingTransparent : RedWingTransparent;

  useEffect(() => {
    if (autoRedirect) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, []);

  return (
    <div
      className={`flex flex-col  h-screen items-center ${animationData === WhiteWingTransparent ? "bg-red-500" : "bg-white"
        } w-full justify-center`}
    >
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ width: 100, height: 100 }}
      />
      {location?.state &&
        <div className="flex flex-col items-center heading-9 text-white">
          {location?.state?.responseMessage.map((text: string, i: any) => (
            <p key={i}>{text}</p>
          ))}
        </div>
      }
    </div>
  );
};

export default Preloader;
