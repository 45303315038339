import { ProgressBar } from "components/ProgressBar";
import Icons from "components/icons/Icons";
import { Label } from "components/ui/label";
import { Tooltip } from "components/ui/tooltip";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { QuestLandingPage } from "tabs/Quest/QuestLanding/QuestLandingPage";
import type { B2bQuest } from "types/__generated__/graphql";

type QuestProgressData = {
  completed: number | undefined;
  total: number | undefined;
  currentLesson: number;
  currentLessonDuration: number;
};

type DueDateInfo = {
  formattedDate: string;
  isDueSoon: boolean;
  hasEndDate: boolean;
};

// Action buttons component - reused in both mobile and desktop
function ActionButtons({
  quest,
  handleContinue,
  className = "",
}: {
  quest: B2bQuest;
  handleContinue: () => void;
  className?: string;
}) {
  return (
    <div className={`flex gap-2 ${className}`}>
      <button
        className="bg-red-600 rounded-full w-9 h-9 flex items-center justify-center hover:bg-gradient-to-b from-red-400 to-red-600 focus:ring-2 focus:ring-red-400 focus:outline-none"
        onClick={handleContinue}
        type="button"
        aria-label="Continue quest"
      >
        <Icons name="play-filled" width={20} height={21} fill="white" />
      </button>

      <Tooltip
        tooltipContent={<p className="caption-disclaimer">Learn more</p>}
      >
        <button
          className="border border-cool-grey-250 rounded-full w-9 h-9 flex items-center justify-center hover:bg-cool-grey-200 focus:ring-2 focus:ring-cool-grey-300 focus:outline-none"
          type="button"
          onClick={(e) => e.stopPropagation()}
          aria-label="Quest details"
        >
          <QuestLandingPage
            questArgs={quest}
            trigger={
              <Icons name="list-filled" height={20} width={20} fill="#595E67" />
            }
          />
        </button>
      </Tooltip>
    </div>
  );
}

// Progress display component - reused in both mobile and desktop
function ProgressDisplay({
  progressData,
  className = "",
  containerWidth = "w-4/5",
}: {
  progressData: QuestProgressData;
  className?: string;
  containerWidth?: string;
}) {
  return (
    <span
      className={`flex flex-col ${containerWidth} justify-center items-start ${className}`}
    >
      <ProgressBar
        value={progressData.completed}
        maxValue={progressData.total}
        containerClasses="my-2"
        progressColor="bg-yellow-500"
      />
      <p className="title-10">
        {progressData.completed ?? 0} / {progressData.total ?? 0} lessons
        completed
      </p>
    </span>
  );
}

// Due date label component
function DueDateLabel({ dueDateInfo }: { dueDateInfo: DueDateInfo }) {
  if (!dueDateInfo.hasEndDate) return null;

  return (
    <Label className="hidden lg:flex w-fit gap-2 bg-cool-grey-250 rounded p-1">
      <Icons
        name="calendar-reminder-outlined"
        width={14}
        height={14}
        fill="black"
      />
      <p className={`title-10 ${dueDateInfo.isDueSoon ? "text-red-500" : ""}`}>
        Complete by {dueDateInfo.formattedDate}
      </p>
    </Label>
  );
}

// Desktop view
function DetailsView({
  quest,
  progressData,
  dueDateInfo,
  handleContinue,
}: {
  quest: B2bQuest;
  progressData: QuestProgressData;
  dueDateInfo: DueDateInfo;
  handleContinue: () => void;
}) {
  return (
    <div className="lg:flex items-start flex-col w-full justify-between lg:px-6 lg:py-4 gap-3 hidden">
      <div className="flex flex-col gap-y-4 my-auto">
        <div className="md:p-0">
          <p className="heading-10 text-cool-grey-450">Quests</p>
          <p className="lg:heading-8 md:title-9 text-start">
            {quest.userProgress?.resumePage?.name}
          </p>
          <span className="flex justify-between gap-2 w-fit title-10 text-cool-grey-450">
            <p>lesson {progressData.currentLesson}</p>
            <p>•</p>
            <p>{progressData.currentLessonDuration} mins</p>
          </span>
        </div>

        <DueDateLabel dueDateInfo={dueDateInfo} />
      </div>

      <div className="flex w-full justify-between">
        <ProgressDisplay progressData={progressData} />
        <ActionButtons quest={quest} handleContinue={handleContinue} />
      </div>
    </div>
  );
}

// Mobile progress bar container
function ProgressElementMobile({
  quest,
  progressData,
  handleContinue,
}: {
  quest: B2bQuest;
  progressData: QuestProgressData;
  handleContinue: () => void;
}) {
  return (
    <div className="lg:hidden flex w-full justify-between p-1.5 items-center gap-10">
      <ProgressDisplay progressData={progressData} containerWidth="w-full" />
      <ActionButtons quest={quest} handleContinue={handleContinue} />
    </div>
  );
}

// Mobile header view
function DetailsViewMobile({
  quest,
  progressData,
  dueDateInfo,
}: {
  quest: B2bQuest;
  progressData: QuestProgressData;
  dueDateInfo: DueDateInfo;
}) {
  return (
    <div className="lg:hidden block px-4">
      <div className="md:p-0">
        <p className="heading-10 text-cool-grey-450">Quests</p>
        <p className="heading-8 text-cool-grey-700 text-ellipsis line-clamp-2 overflow-hidden text-start">
          {quest.userProgress?.resumePage?.name}
        </p>
        <span className="flex justify-between gap-2 w-fit title-10">
          <p>lesson {progressData.currentLesson}</p>
          <p>•</p>
          <p>{progressData.currentLessonDuration} mins</p>
        </span>
      </div>

      {dueDateInfo.hasEndDate && (
        <Label className="w-fit gap-2 bg-cool-grey-250 rounded p-1 mt-2 inline-flex">
          <Icons
            name="calendar-reminder-outlined"
            width={14}
            height={14}
            fill="black"
          />
          <p className="title-10">Complete by {dueDateInfo.formattedDate}</p>
        </Label>
      )}
    </div>
  );
}

export function CardVariant3({
  quest,
  endDate,
}: {
  quest: B2bQuest;
  endDate?: string;
}) {
  const navigate = useNavigate();

  // Calculate progress data once using useMemo
  const progressData = useMemo((): QuestProgressData => {
    const completed =
      quest.type === "daily"
        ? quest.userProgress?.totalDaysCompleted
        : quest.userProgress?.totalLessonsCompleted;

    const total =
      quest.type === "daily"
        ? quest.userProgress?.totalDays
        : quest.userProgress?.totalLessons;

    const currentLesson = (completed ?? 0) + 1;

    const currentLessonDuration = Math.round(
      (quest.userProgress?.resumePage?.duration ?? 0) / 60
    );

    return { completed, total, currentLesson, currentLessonDuration };
  }, [quest]);

  // Process due date information once using useMemo
  const dueDateInfo = useMemo((): DueDateInfo => {
    if (!endDate) {
      return { hasEndDate: false, formattedDate: "", isDueSoon: false };
    }

    const dueDateObj = new Date(endDate);
    const isValidDate =
      dueDateObj instanceof Date && !Number.isNaN(dueDateObj.getTime());

    if (!isValidDate) {
      return { hasEndDate: false, formattedDate: "", isDueSoon: false };
    }

    const currentDate = new Date();
    const differenceInDays =
      (dueDateObj.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);

    return {
      hasEndDate: true,
      formattedDate: dueDateObj.toDateString(),
      isDueSoon: differenceInDays < 30,
    };
  }, [endDate]);

  // Single handler for continuing the quest
  const handleContinue = () => {
    const pageId = quest.userProgress?.resumePage?.id;
    const url = pageId
      ? `/quests/${quest.language}/${quest.slug}?pageId=${pageId}`
      : `/quests/${quest.language}/${quest.slug}`;
    navigate(url);
  };

  return (
    <div
      className="flex w-full rounded-xl border border-cool-grey-250 hover:shadow-light cursor-pointer transition-shadow duration-200"
      onClick={handleContinue}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleContinue();
        }
      }}
    >
      <div className="flex flex-col lg:w-fit w-full">
        <div className="flex border-b border-cool-grey-250 items-center">
          <img
            src={quest.coverAsset?.url}
            alt={quest.name || "Quest thumbnail"}
            className="aspect-video max-w-[200px] lg:max-w-[320px] xl:max-w-[460px] rounded-tl-xl lg:rounded-l-xl object-cover"
            loading="lazy"
          />

          <DetailsViewMobile
            quest={quest}
            progressData={progressData}
            dueDateInfo={dueDateInfo}
          />
        </div>

        <div className="lg:hidden p-[6px]">
          <ProgressElementMobile
            quest={quest}
            progressData={progressData}
            handleContinue={handleContinue}
          />
        </div>
      </div>

      <DetailsView
        quest={quest}
        progressData={progressData}
        dueDateInfo={dueDateInfo}
        handleContinue={handleContinue}
      />
    </div>
  );
}
