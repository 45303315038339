import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import FeatureCardSkeleton from "components/loading/FeatureCardSkeleton";
import { QuestCard, QuestCardVariants } from "components/quest/QuestCard";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import useGetPickedForYouQuestIds from "hooks/useGetPickedForYouQuests";
import useScreenType from "hooks/useScreenType";
import { cn } from "libs/classMerger";

export function PickedForYouSection() {
  const { data: questsData, loading: fetchingQuests } =
    useGetPickedForYouQuestIds({
      first: 4,
    });

  const { isAuthenticated, userAccessState } = useUserState();
  const quests = questsData || [];
  const screenType = useScreenType();

  if (fetchingQuests) return <FeatureCardSkeleton />;

  if (quests.length === 0) return null;

  const filteredQuests =
    screenType.isMobile || screenType.isLargeDesktop
      ? quests
      : quests.slice(0, 3);

  const isDraggable = screenType.isSmallMobile || screenType.isMobile || screenType.isTablet;

  return (
    <section className="z-40 max-w-2xl mx-auto px-5">
      {filteredQuests && (
        <div className="flex flex-col w-full gap-6 md:gap-0">
          <Carousel
            opts={{
              align: "start",
              loop: false,
              active: isDraggable,
            }}
            orientation="horizontal"
            className="w-full"
          >
            <div className="flex justify-between">
              <div className="flex flex-col gap-[2px] justify-center">
                <div className="heading-6">Picked for you</div>
                {isAuthenticated && (
                  <div className="caption-disclaimer hidden lg:flex">
                    Quests based on your organization’s goals
                  </div>
                )}
              </div>

              <div className="flex lg:hidden gap-1 px-1">
                <CarouselPrevious />
                <CarouselNext />
              </div>
            </div>

            <CarouselContent className="-ml-6 mt-6">
              {filteredQuests.map((quest, index) => (
                <CarouselItem
                  key={quest.id}
                  className={cn(
                    "basis-full md:basis-1/2 lg:basis-1/3 2xl:basis-1/4 pl-6",
                    index === filteredQuests.length - 1 && "pr-0"
                  )}
                >
                  <QuestCard
                    quest={quest}
                    cardVariant={QuestCardVariants.Wordmark}
                    canHover={
                      (screenType.isDesktop || screenType.isLargeDesktop) &&
                      userAccessState === UserAccessStateEnum.Premium
                    }
                    userAccessState={userAccessState}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </div>
      )}
    </section>
  );
}
