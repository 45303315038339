import { MediaCardComponent } from "components/MediaCardComponent";
import { CategoriesSelectorDropdownMenu } from "../../tabs/Soundscapes/components/CategoriesSelectorDropdownMenu";
import { useUserState } from "context/userContext";
import { CategoriesSelectorDrawerMenu } from "../../tabs/Soundscapes/components/CategoriesSelectorDrawerMenu";
import { Skeleton } from "components/Skeleton";
import useScreenType from "hooks/useScreenType";
import type {
  B2bChannel,
  B2bCollection,
  Resource,
} from "types/__generated__/graphql";

export function CategoryListGridSection({
  type,
  copy,
  sectionRef,
  isFetching,
  collectionsData,
  filteredCategoriesData,
  handleCategoryChange,
}: {
  type: "meditations" | "soundscapes";
  copy: {
    title: string;
  };
  sectionRef: React.RefObject<HTMLDivElement>;
  isFetching: boolean;
  collectionsData: B2bChannel | null;
  filteredCategoriesData: B2bCollection | null;
  handleCategoryChange: (category: B2bCollection) => void;
}) {
  const { userAccessState } = useUserState();
  const screenType = useScreenType();

  const collectionResources =
    filteredCategoriesData?.collectionResources as Resource[]; // TODO:: figure out why

  return (
    <section ref={sectionRef} className="grid gap-4">
      {isFetching || !collectionsData || !filteredCategoriesData ? (
        <>
          <Skeleton className="h-6  w-40" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-6">
            {[...Array(8)].map((_, index) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <Skeleton key={index} className="w-full h-[231px]" />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="py-2 w-full h-12 flex gap-1 xl:gap-2 items-center heading-6 bg-white sticky top-0 z-10 ">
            {copy.title}
            {screenType.isDesktop || screenType.isLargeDesktop ? (
              <CategoriesSelectorDropdownMenu
                collectionsData={collectionsData}
                selectedData={filteredCategoriesData}
                handleCategoryChange={handleCategoryChange}
              />
            ) : (
              <CategoriesSelectorDrawerMenu
                collectionsData={collectionsData}
                selectedData={filteredCategoriesData}
                handleCategoryChange={handleCategoryChange}
              />
            )}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-6">
            {collectionResources?.map((item) => (
              <div key={item.id} className="basis-auto pl-0 justify-center">
                <MediaCardComponent
                  type={type}
                  resource={item}
                  // containerClasses="flex-row lg:flex-col items-center md:justify-center w-full"
                  // tileClasses="lg:size-full aspect-square"
                  // titleClasses="h-full self-center w-full"
                  bannerTile={false}
                  userAccessState={userAccessState}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </section>
  );
}
