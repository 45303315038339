import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  from,
  type DefaultOptions,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RestLink } from "apollo-link-rest";
import { apiConfig } from "config/apiConfig";
import { getAuth0Client, microsoftAuthUrl } from "config/authConfig";
import { authLoginPopUp } from "utils/authHelper";
import { AuthRequirement, getAuthRequirement } from "./graphqlOperationsHelper";

export async function getToken(
  { operationName }: { operationName: string | null } = { operationName: null }
): Promise<string | null> {
  try {
    const auth0 = await getAuth0Client();
    return await auth0.getTokenSilently();
  } catch (error: any) {
    console.warn("Silent token retrieval failed, attempting login if needed");

    const authRequirement = getAuthRequirement(operationName);
    if (
      (authRequirement !== AuthRequirement.OPTIONAL &&
        error.error === "login_required") ||
      error.error === "consent_required"
    ) {
      try {
        return await authLoginPopUp(microsoftAuthUrl);
      } catch (popupError) {
        console.error("Login popup failed:", popupError);
        return null;
      }
    }

    console.error("Unexpected error during token retrieval:", error);
    return null;
  }
}

const httpLink = createHttpLink({
  uri: apiConfig.platformApiEndpoint,
});

const restLink = new RestLink({
  uri: apiConfig.dsApiEndpoint,
});

const authLink = setContext(async (operation, { headers }) => {
  const token = await getToken({
    operationName: operation.operationName ?? null,
  });

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-mv-app": "mv-web",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        extensions
      );

      if (extensions?.code === "UNAUTHENTICATED") {
        console.warn(
          "Authentication error detected, will re-authenticate on next request"
        );
      }
    });
  }

  if (networkError) {
    if (operation.getContext().clientName === "rest") {
      console.error(`[REST API network error]: ${networkError}`);
    } else {
      console.error(`[GraphQL network error]: ${networkError}`);
    }
  }
});

// Default options
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

const combinedLink = from([
  errorLink,
  authLink,
  ApolloLink.split(
    (operation) => operation.getContext().clientName === "rest",
    restLink,
    httpLink
  ),
]);

export const apolloClient = new ApolloClient({
  link: combinedLink,
  cache: new InMemoryCache({
    typePolicies: {},
  }),
  defaultOptions,
  connectToDevTools: process.env.NODE_ENV !== "production",
  queryDeduplication: true,
});

export function clearEntireCache() {
  return apolloClient.clearStore();
}

export function clearSpecificType(fieldName: string) {
  apolloClient.cache.evict({ fieldName });
  return apolloClient.cache.gc();
}
