import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { Author, Resource } from "types/__generated__/graphql";
import { useState, useEffect } from "react";
import { app, FrameContexts } from "@microsoft/teams-js";
import { useShareToStage } from "./hooks/useShareToStage";
import ResourceListItem from "./components/ResourceListItem";
import { teamsConfig } from "config/teamsConfig";
import { cn } from "libs/classMerger";

export default function ResourceDetailView() {
    const { resourceId, resourceType } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const collection = location.state?.collection;
    const [isExpanded, setIsExpanded] = useState(false);
    const [playingResourceId, setPlayingResourceId] = useState<string | null>(null);
    const [isInTeamsMeeting, setIsInTeamsMeeting] = useState(false);
    const [isSidePanel, setIsSidePanel] = useState(false);

    const [teamsContext, setTeamsContext] = useState<app.Context | null>(null);

    useEffect(() => {
        const initializeTeams = async () => {
            try {
                await app.initialize();
                const context = await app.getContext();
                setTeamsContext(context);
                setIsInTeamsMeeting(context.meeting?.id !== undefined);
            } catch (error) {
                console.error('Error initializing Teams:', error);
            }
        };
        initializeTeams();

        const init = async () => {
            const context = await app.getContext();
            const frameContext = context.page.frameContext;
            setIsSidePanel(frameContext == FrameContexts.sidePanel);
        };
        init();
    }, []);

    const { shareToStage } = useShareToStage({ setPlayingResourceId });

    const getResourceTypeTitle = () => {
        switch (resourceType?.toLowerCase()) {
            case 'meditation':
                return 'Meditation collection';
            case 'soundscape':
                return 'Soundscape collection';
            default:
                return 'Collection';
        }
    };

    const getSubtitle = () => {
        if (!collection) return '';
        const resourceCount = collection.collectionResources?.length || 0;
        const resourceLabel = resourceType?.toLowerCase() === 'meditation' ? 'Meditations' : 'Soundscapes';
        return `${resourceCount} ${resourceLabel}`;
    };

    return (
        <div className="h-full overflow-y-auto">
            <div className={cn("flex flex-col gap-4",
                !isSidePanel && "p-4"
            )}>
                <Button
                    variant="ghost"
                    size="icon"
                    className="shrink-0 bg-primary/10 hover:bg-primary/20 w-fit"
                    onClick={() => navigate(-1)}
                >
                    <Icons name="arrow-left-outlined" height={24} width={24} />
                    <p className="text-gray-600">Back</p>
                </Button>
                <div className="flex flex-col gap-1">
                    <h1 className="text-2xl font-semibold text-left text-cool-grey-700">{getResourceTypeTitle()}</h1>
                    <p className="text-xl text-cool-grey-600">{collection?.title || resourceId}</p>
                    <p className="text-base text-cool-grey-500">{getSubtitle()}</p>
                    <div className="mt-2">
                        <p className={`text-sm text-cool-grey-500 ${!isExpanded ? 'line-clamp-4' : ''}`}>
                            {collection?.description}
                        </p>
                        {collection?.description && collection.description.split('\n').length > 4 && (
                            <Button
                                variant="link"
                                className="p-0 h-fit text-sm text-primary mt-1"
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                {isExpanded ? 'Read less' : 'Read more'}
                            </Button>
                        )}
                    </div>
                    <div className="mt-6">
                        <h2 className="text-lg font-semibold text-cool-grey-600">
                            {resourceType?.toUpperCase()} FROM COLLECTION
                        </h2>
                        <p className="text-base text-cool-grey-500 mt-1">{collection?.title}</p>
                    </div>
                    <div className="w-full h-[1px] bg-cool-grey-200 my-4" />
                    <div className="space-y-2">
                        {collection?.collectionResources?.map((resource: Resource) => (
                            <div
                                key={resource.id}
                                onClick={() => isInTeamsMeeting ? shareToStage({
                                    id: resource.id,
                                    mediaUrl: resource.mediaAsset?.url || "",
                                    coverAssetUrl: resource.coverAsset?.url || ""
                                }) : resource.id && setPlayingResourceId(resource.id)}
                                className="cursor-pointer"
                            >
                                <ResourceListItem
                                    title={resource.title || ''}
                                    imageUrl={resource.coverAsset?.url}
                                    authorName={resource.author?.name}
                                    duration={resource.totalDuration}
                                    resourceType={resourceType}
                                    isPlaying={playingResourceId === resource.id}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}