import { gql } from "@apollo/client";

const getQuestByCategoryDeprecated = `
  query questsByCategory(
    $category: ID
    $membershipFilter: QuestMembershipFilterValues = ALL
    $first: Int = 6
  ) {
    quests(category: $category, membership: $membershipFilter, first: $first) {
      edges {
        node {
          id
          description
          name
          type
          url
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
          coverAsset {
            url
            thumbnailUrl
          }
          resources {
            mediaAsset {
              url
              edgeUrl
              contentType
            }
          }
          authors {
            name
          }
        }
        courseEndedAt
        owned
        progress {
          completed
          completedAt
          ended
          endedAt
          enrolledAt
          enrollmentStartedAt
          started
          startedAt
          totalDays
          totalDaysCompleted
          totalDaysMissed
          totalIntros
          totalIntrosCompleted
          totalLessons
          totalLessonsCompleted
          totalLessonsMissed
        }
      }
    }
    totalCount
  }
`;

const getQuestByCategory = gql`
  query questsByCategory(
    $category: ID
    $membershipFilter: QuestMembershipFilterValues = ALL
    $first: Int = 6
  ) {
    quests(category: $category, membership: $membershipFilter, first: $first) {
      edges {
        node {
          id
          description
          name
          type
          url
          slug
          language
          trailerAsset {
            url
          }
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
          coverAsset {
            url
            thumbnailUrl
          }
          resources {
            mediaAsset {
              url
              edgeUrl
              contentType
            }
          }
          releases {
            id
            status
            perpetual
            publishedAt
          }
          authors {
            name
          }
          userProgress {
            completed
            totalDays
            totalDaysCompleted
            daysCompleted
            totalLessonsCompleted
            totalLessons
            started
            resumePage {
              id
              name
              duration
              position
              url
            }
          }
        }
      }
    }
  }
`;

const getCategories = gql`
  query getCategories {
    categories(first: 100, type: "b2b") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const getPickedForYouQuestsDeprecated = `query recommendedForYou {
  recommendedB2bQuestsPickedForYou(first: 3) {
    edges {
      node {
        quest {
          authors {
            name
          }
          coverAsset {
            thumbnailUrl
            url
          }
          description
          id
          name
          pages {
            position
            sections {
              primaryAsset {
                contentType
                edgeUrl
                url
              }
            }
          }
          type
          url
          wordmarkAsset {
            url
          }
          categories {
            name
          }
        }
      }
    }
  }
}`;

const getPickedForYouQuests = gql`
  query recommendedForYou {
    recommendedB2bQuestsPickedForYou(first: 4) {
      edges {
        node {
          quest {
            slug
            language
            authors {
              name
            }
            coverAsset {
              thumbnailUrl
              url
            }
            description
            id
            name
            pages {
              position
              sections {
                primaryAsset {
                  contentType
                  edgeUrl
                  url
                }
              }
            }
            type
            url
            wordmarkAsset {
              url
            }
            categories {
              name
            }
          }
        }
      }
    }
  }
`;

const getCurrentOrgDeprecated = `query currentOrganization {
  currentOrganization {
    name
    division
    logoFullUrl
    logoSquareUrl
  }
}`;

const getCurrentOrg = gql`
  query currentOrganization {
    currentOrganization {
      name
      division
      logoFullUrl
      logoSquareUrl
    }
  }
`;
const getCitiesList = gql`
  query cities($keyword: String, $first: Int) {
    cities(keyword: $keyword, first: $first) {
      edges {
        node {
          id
          name
          adminDivision {
            name
          }
          country {
            name
            iso3Code
          }
        }
      }
    }
  }
`;

const getQuestAssignments = gql`
  query currentWorkwayQuestAssignments($first: Int) {
    currentWorkwayQuestAssignments(first: $first) {
      edges {
        node {
          startDate
          endDate
          createdAt
          quest {
            authors {
              name
            }
            description
            url
            slug
            language
            enrollmentStartedAt
            courseStartedAt
            courseEndedAt
            coverAsset {
              thumbnailUrl
              url
            }
            id
            name
            wordmarkAsset {
              url
            }
          }
        }
      }
    }
  }
`;

const getRecommendedQuests = gql`
  query recommendedB2bQuests($first: Int, $language: String = "en") {
    recommendedB2bQuests(first: $first, language: $language) {
      edges {
        node {
          quest {
            url
            authors {
              name
            }
            coverAsset {
              thumbnailUrl
              url
            }
            id
            name
            wordmarkAsset {
              url
            }
          }
        }
      }
    }
  }
`;

const getMeditationsOfTheDay = `query recommendedMeditations($first: Int,) {
  recommendedMeditations(first: $first) {
    edges {
      node {
        resource{
        title
          id
    			coverAsset {
            url
          }
          mediaAsset{
            url
          } 
          author {
            name
          }
        }
      }
    }
  }
}`;

const getProfile = gql`
  query currentProfile {
    currentProfile {
      firstName
      lastName
      email
      role
      spokenLanguages {
        id
        displayName
        iso2Code
      }
      country
      location
      industry
      avatarUrl
      tags
      bio
      userLevel {
        level
      }
    }
  }
`;

const getLibrary = gql`
  query library($status: String) {
    quests(first: 100, progress: $status, membership: ALL) {
      edges {
        node {
          type
          name
          description
          enrollmentStartedAt
          courseStartedAt
          courseEndedAt
          slug
          language
          userProgress {
            totalDays
            totalDaysCompleted
            totalLessons
            totalLessonsCompleted
            started
            endedAt
          }
          url
          authors {
            name
          }
          coverAsset {
            url
          }
          resources {
            mediaAsset {
              url
              thumbnailUrl
              duration
            }
          }
          groups {
            id
            locked
            name
            position
            type
            unlockAfterDays
          }
          trailerAsset {
            url
            thumbnailUrl
          }
          wordmarkAsset {
            url
          }
        }
      }
    }
  }
`;

const getQuestAssignmentLibrary = gql`
  query currentWorkwayQuestAssignments($first: Int) {
    currentWorkwayQuestAssignments(first: $first) {
      edges {
        node {
          startDate
          endDate
          slug
          language
          createdAt
          quest {
            authors {
              name
            }
            url
            enrollmentStartedAt
            courseStartedAt
            courseEndedAt
            coverAsset {
              thumbnailUrl
              url
            }
            id
            name
            wordmarkAsset {
              url
            }
          }
        }
      }
    }
  }
`;

const updateCurrentProfile = gql`
  mutation updateCurrentProfile($input: UpdateProfileInput!) {
    updateCurrentProfile(input: $input) {
      successful
      messages {
        code
        field
        message
      }
      result {
        avatarUrl
        bio
        firstName
        lastName
        location
        spokenLanguages {
          id
          displayName
          iso2Code
        }
      }
    }
  }
`;

const languages = gql`
  query languages($first: Int) {
    languages(first: $first) {
      edges {
        node {
          id
          iso2Code
          displayName
        }
      }
      totalCount
    }
  }
`;

const updateUserAvatar = gql`
  mutation updateCurrentProfileAvatar($input: UpdateProfileAvatarInput!) {
    updateCurrentProfileAvatar(input: $input) {
      successful
      messages {
        code
        field
        message
      }
      result {
        avatarUrl
        bio
        firstName
        lastName
        location
        spokenLanguages {
          id
          displayName
          iso2Code
        }
      }
    }
  }
`;

const enrollUserMutation = gql`
  mutation enrollUser($input: EnrollUserInput!) {
    enrollUser(input: $input) {
      successful
      messages {
        message
        code
      }
    }
  }
`;
const unEnrollUserMutation = gql`
  mutation unenrollUser($input: UnenrollUserInput!) {
    unenrollUser(input: $input) {
      successful
      result {
        quest {
          id
          slug
          language
        }
        user {
          id
        }
      }
    }
  }
`;
const startLearningPathwayMutation = gql`
  mutation startLearningPathway($input: StartLearningPathwayInput!) {
    startLearningPathway(input: $input) {
      successful
      messages {
        message
        code
      }
    }
  }
`;

const markPageAsCompletedMutation = gql`
  mutation markPageAsCompleted($input: MarkPageAsCompletedInput!) {
    markPageAsCompleted(input: $input) {
      successful
      messages {
        message
        code
      }
    }
  }
`;

const markTaskAsCompletedMutation = gql`
  mutation markTaskAsCompleted($input: MarkTaskAsCompletedInput!) {
    markTaskAsCompleted(input: $input) {
      successful
      messages {
        message
        code
      }
    }
  }
`;

const freeUserQuests = gql`
  query quests($first: Int, $owned: Boolean, $language: [String]) {
    b2bQuests(first: $first, owned: $owned, language: $language) {
      edges {
        node {
          type
          name
          description
          enrollmentStartedAt
          courseStartedAt
          courseEndedAt
          slug
          language
          userProgress {
            totalDays
            totalDaysCompleted
            totalLessons
            totalLessonsCompleted
            started
          }
          url
          authors {
            name
          }
          coverAsset {
            url
          }
          resources {
            mediaAsset {
              url
              thumbnailUrl
              duration
            }
          }
          groups {
            id
            locked
            name
            position
            type
            unlockAfterDays
          }
          trailerAsset {
            url
            thumbnailUrl
          }
          wordmarkAsset {
            url
          }
        }
      }
      totalCount
    }
  }
`;

const getQuestBySlugAndLanguage = gql`
  query questBySlugAndLanguage($slugAndLanguage: QuestSlugAndLanguage!) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      name
      description
      slug
      language
      currentAssignment {
        startDate
        endDate
      }
      type
      enrollmentsCount
      publishedAt
      duration
      authors {
        name
        bioTitle
        headline
        avatarAsset {
          url
        }
        bannerAsset {
          url
        }
        metadataTitle
        description
        headline
      }
      categories {
        name
      }
      groups {
        id
        locked
        name
        position
        type
        unlockAfterDays
      }
      userProgress {
        completed
        totalDays
        totalDaysCompleted
        daysCompleted
        totalLessonsCompleted
        totalLessons
        started
        resumePage {
          id
          name
          duration
          position
          url
        }
      }
      wordmarkAsset {
        url
      }
      owned
      pages {
        id
        name
        type
        groupName
        position
        locked
        completed
        duration
        preview
        nextPage {
          id
        }
        url
        coverAsset {
          thumbnailUrl
          url
        }
        tasks {
          id
          type
          name
          description
          position
          required
          imageUrl
          completed
          coverAsset {
            url
          }
        }
        sections {
          info {
            body
            title
          }
          type
          position
          media {
            type
            author {
              name
            }
            coverAsset {
              url
              edgeUrl
            }
            id
            description
            mediaAsset {
              url
              contentType
              captions {
                id
                language
                label
                default
                isDefault
                url
              }
            }
            title
          }
          primaryAsset {
            contentType
            url
            duration
            sizeOfFile
            edgeUrl
            filesize
            id
            captions {
              id
              language
              label
              default
              isDefault
              url
            }
            markers {
              id
              name
              status
              time
            }
            name
            status
            thumbnailUrl
            userProgress {
              watchProgress
              watchTime
              watched
            }
            renditions {
              url
              contentType
            }
          }
        }
      }
      outcomes {
        id
        name
      }
      daysCount
      lessonsCount
      coverAsset {
        url
      }
      trailerAsset {
        thumbnailUrl
        url
        renditions {
          id
          contentType
          url
        }
        captions {
          id
          language
          label
          default
          isDefault
          url
        }
      }
      trailerCoverAsset {
        url
      }
      releases {
        id
        status
        perpetual
        publishedAt
      }
      nextRelease {
        id
        status
        perpetual
        publishedAt
      }
      url
      productId
      owned
      language
      resources {
        isPartOfMembership
        type
        id
        title
        author {
          name
        }
        mediaAsset {
          contentType
          url
          duration
          sizeOfFile
        }
        totalDuration
        description
        coverAsset {
          thumbnailUrl
          url
        }
      }
    }
  }
`;

const gqlQuery = {
  getCategories: getCategories,
  getQuestByCategoryDeprecated: getQuestByCategoryDeprecated,
  getQuestByCategory: getQuestByCategory,
  getPickedForYouQuestsDeprecated: getPickedForYouQuestsDeprecated,
  getPickedForYouQuests: getPickedForYouQuests,
  getCurrentOrgDeprecated: getCurrentOrgDeprecated,
  getCurrentOrg: getCurrentOrg,
  getCitiesList: getCitiesList,
  getQuestAssignments: getQuestAssignments,
  getRecommendedQuests: getRecommendedQuests,
  getMeditationsOfTheDay: getMeditationsOfTheDay,
  getProfile: getProfile,
  getLibrary: getLibrary,
  updateCurrentProfile: updateCurrentProfile,
  updateUserAvatar: updateUserAvatar,
  languages: languages,
  getQuestBySlugAndLanguage: getQuestBySlugAndLanguage,
  enrollUser: enrollUserMutation,
  unEnrollUser: unEnrollUserMutation,
  markPageAsCompleted: markPageAsCompletedMutation,
  markTaskAsCompleted: markTaskAsCompletedMutation,
  freeUserQuests: freeUserQuests,
} as const;

export default gqlQuery;
