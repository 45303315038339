import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { getLessonsStatus } from "./utils";
import DOMPurify from "dompurify";
import type { Page } from "types/__generated__/graphql";

const getLessonStatusLabel = (page: Page) => {
  const { total } = getLessonsStatus(
    page.quest?.type || "",
    page.quest?.userProgress
  );
  switch (page?.type) {
    case "intro":
      return `Intro ${page?.position}`;
    case "day":
    case "lesson":
      return `Lesson ${page.position} of ${total}`;
    default:
      return "";
  }
};

const QuestDetails = ({
  page,
  onStartProgram,
  onMarkLessonAsComplete,
}: {
  page?: Page;
  onMarkLessonAsComplete: (page?: Page) => void;
  onStartProgram: () => void;
}) => {
  if (!page) return null;

  const isLastPage =
    page?.position ===
    Math.max(...(page.quest?.pages?.map((page) => page.position ?? 0) ?? [0]));

  const handleUserProgress = (
    <>
      {!page?.completed && (
        <Button
          variant="primary"
          className="gap-2"
          onClick={() => page && onMarkLessonAsComplete(page)}
        >
          <span>{isLastPage ? "Complete Quest" : "Mark as complete"}</span>
          <Icons
            name={isLastPage ? "award-outlined" : "chevron-right-filled"}
          />
        </Button>
      )}
      {page?.completed && (
        <div className="w-fit flex items-center button-text-medium gap-1 bg-green-50 text-green-600 rounded-lg px-3 py-2">
          <Icons name="checklist-circle-filled" className="text-green" />
          Completed
        </div>
      )}
      {page?.tasks && page?.tasks.length > 0 && (
        <div className="flex gap-1.5 items-center text-cool-grey-500">
          <Icons name="edit-outlined" />
          <p className="body-small text-center">
            {page?.tasks?.filter((task) => task.completed).length}/
            {page?.tasks?.length} tasks completed
          </p>
        </div>
      )}
    </>
  );

  return (
    <div className="space-y-3" data-testid="quest-lesson-intro">
      <div className="space-y-1">
        <h1 className="title-6 text-cool-grey-700">{page?.name}</h1>
        <p
          className="title-7 text-cool-grey-500 capitalize"
          data-testid="quest-lesson-sub-header"
        >
          {getLessonStatusLabel(page)}
        </p>
      </div>

      <div className="flex items-start">
        <div className="flex gap-3 items-center">
          {!page.quest?.userProgress?.started ? (
            <Button
              variant={"primary"}
              className="gap-2 px-10"
              onClick={onStartProgram}
            >
              <Icons name="play-filled" />
              Start Program
            </Button>
          ) : (
            handleUserProgress
          )}
        </div>
      </div>
      {/* <div>
        <div>
          {page?.sections
            ?.filter(
              (section) => section.position === 1 && section.type === "text"
            )
            ?.map((section) => (
              <div
                key={section.id}
                className="quest-guide-text-content"
                // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                dangerouslySetInnerHTML={{
                  __html: section.info?.body
                    ? DOMPurify.sanitize(section.info.body)
                    : "",
                }}
              />
            ))}
        </div>
      </div> */}
    </div>
  );
};

export default QuestDetails;
