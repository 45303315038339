import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import type { UserAccessStateEnum } from "context/userContext";

interface ContentAsset {
    url?: string;
}

interface ContentAuthor {
    name?: string;
}

interface ContentItem {
    name: string;
    coverAsset?: ContentAsset;
    authors?: ContentAuthor[];
}

export const SidepanelCard = <T extends ContentItem>({
    item,
    category,
}: {
    item: T;
    category?: {
        title?: string;
        description?: string;
    };
}) => {
    return (
        <div className="flex flex-col">
            <div className="relative w-full">
                <img
                    className="w-full object-resize rounded-lg"
                    src={item.coverAsset?.url}
                    alt={item.name}
                />
            </div>
            <div className="mt-2 space-y-1">
                <p className="title-8 text-cool-grey-700">
                    {item.name}
                </p>
                <p className="text-sm text-cool-grey-500">
                    {item.authors?.[0]?.name}
                </p>
            </div>
        </div>
    );
};