import LockedModal from "components/LockedModal";
import { LoginWithMicrosoftButton } from "components/LoginWithMicrosoftButton";
import { WorkwayLogoFull } from "components/logo/WorkwayLogo";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useMicrosoftLogin } from "hooks/useMicrosoftLogin";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import useScreenType from "hooks/useScreenType";
import { getAuth0Client } from "config/authConfig";

export function HeroHeading() {
  const navigate = useNavigate();
  const handleLogin = useMicrosoftLogin();
  const screenType = useScreenType();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userAccessState, isAuthenticated } = useUserState();


  const isFreemium = userAccessState === UserAccessStateEnum.Freemium;

  useEffect(() => {
    const checkAuthentication = async () => {
      if (isAuthenticated) {
        try {
          const auth0 = await getAuth0Client();
          const accessToken = await auth0.getTokenSilently();
          const tokenData = JSON.parse(atob(accessToken.split('.')[1]));
          const userSub = tokenData.sub;
          const visitedCompanyForm = localStorage.getItem("visitedCompanyForm");
          const targetRoute = isFreemium && (!visitedCompanyForm || visitedCompanyForm !== userSub)
            ? "/companyForm"
            : "/";

          if (targetRoute === "/companyForm") {
            localStorage.setItem("visitedCompanyForm", userSub);
          }
          navigate(targetRoute);
        } catch (error) {
          console.error('Error getting token:', error);
        }
      }
    };

    checkAuthentication();
  }, [isAuthenticated, isFreemium, navigate]);

  const welcomeText = "Welcome to";
  const personalGrowthText = "Where personal growth fuels collective impact.";
  const freeVersionText = " You are now accessing the free version of Workway.";
  const orText = "or ";
  const genInTouchTexts = ["Get in touch", "get in touch"];
  const salesText = " with our sales team";

  const getInTouchButton = (text: string) =>
    <button className="underline text-red-600" onClick={() => navigate(NavigationEnum.InterestForm)} type="button">
      {text}
    </button>;

  return (
    <>
      <div className="grid space-y-2 text-center mb-2">
        <div className={`flex flex-row items-center justify-center ${screenType.isMobile ? "space-x-2" : "space-x-3"}`}>
          <h1 className={`${screenType.isMobile ? "heading-5 sm:heading-3 md:heading-2" : "heading-3 lg:heading-1"} relative`} >{welcomeText}</h1>
          <div className="w-[45%] md:w-[18%] lg:size-fit pt-0 md:pt-1">
            <WorkwayLogoFull />
          </div>
        </div>
        <h2 className="heading-5 md:heading-3">{personalGrowthText}</h2>
      </div>

      {userAccessState !== UserAccessStateEnum.Premium && (
        <div className="flex flex-col items-center gap-y-2 mt-4">
          {!isAuthenticated
            ? <LoginWithMicrosoftButton handleClick={handleLogin} />
            : <div className="title-9">{freeVersionText}</div>
          }
          <p className="title-9">
            {!isFreemium && orText}
            {getInTouchButton(isFreemium ? genInTouchTexts[0] : genInTouchTexts[1])}
            {salesText}
          </p>
          <LockedModal
            onCancel={() => setIsModalOpen(false)}
            open={isModalOpen}
            userAccessState={userAccessState} />
        </div>
      )}
    </>
  );
}
