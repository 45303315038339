import { useState } from "react";
import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import { MediaCardComponent } from "components/MediaCardComponent";
import { cn } from "libs/classMerger";
import useScreenType from "hooks/useScreenType";
import { useUserState } from "context/userContext";
import useCollectionModalStore from "stores/collectionModalStore";
import { ViewCollectionButton } from "./ViewCollectionButton";
import type { B2bChannel, B2bCollection } from "types/__generated__/graphql";

const HeaderDetails = ({ data }: { data: B2bChannel }) => {
  return (
    <div>
      <div className="title-10 text-teal-200">FEATURED COLLECTION</div>
      <div className="heading-2 text-white">
        {data.collections?.[0]?.title ?? ""}
      </div>
      <div className="body-small text-cool-grey-200">
        {data.collections?.[0]?.description ?? ""}
      </div>
    </div>
  );
};

export const FeaturedCollectionSection = ({
  type,
  data,
  loading,
  handleViewCollectionNavigation,
}: {
  type: "meditations" | "soundscapes";
  data: B2bChannel | null;
  loading?: boolean;
  handleViewCollectionNavigation: () => void;
}) => {
  const screenType = useScreenType();
  const { userAccessState } = useUserState();

  const collection = data?.collections?.[0];
  const collectionResources = data?.collections?.[0]?.collectionResources ?? [];

  const openWithCollection = useCollectionModalStore(
    (state) => state.openWithCollection
  );

  const handleViewCollection = (colection?: B2bCollection) => {
    openWithCollection(colection);
  };

  function CarouselSection() {
    return (
      <Carousel
        opts={{
          align: "start",
          active: screenType.isSmallMobile || screenType.isMobile || screenType.isTablet,
        }}
        orientation="horizontal"
        className="w-full"
      >
        <div className="flex items-center justify-end space-x-2 text-cool-grey-250 hover:text-cool-grey-100">
          <CarouselPrevious />
          <CarouselNext />
        </div>
        <CarouselContent className={cn("gap-2 md:gap-4 lg:gap-6 ml-0 mt-4")}>
          {collectionResources.slice(0, 6).map((item) => (
            <CarouselItem
              key={item.id}
              className="basis-1/2 md:basis-1/4 lg:basis-1/4 pl-0"
            >
              <MediaCardComponent
                key={item.id}
                type={type}
                // @ts-expect-error TODO:: Update the type for the track component
                resource={item}
                collectionsData={collection}
                userAccessState={userAccessState}
                // containerClasses={cn(
                //   !screenType.isMobile && "w-[195px] md:w-[202px] lg:w-[193px]"
                // )}
                titleTextClasses="text-white"
                authorTextClasses="text-white"
                showDuration={type === "meditations"}
                durationTextType="MM:SS"
              // tileClasses={cn(
              //   "w-[195px] md:w-[202px] lg:w-[193px] h-[195px] md:h-[202px] lg:h-[193px]",
              //   screenType.isMobile && "size-full aspect-square",
              //   type === "soundscapes" && "rounded-full"
              // )}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    );
  }

  // TODO:: Use skelly loader
  if (!data || loading) return <></>;

  return (
    <section>
      <div className="hidden lg:flex flex-col max-w-2xl px-5 mx-auto">
        <div
          className="flex relative items-end justify-between rounded-3xl w-auto h-auto py-12 px-10 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `
            linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%),
            linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%),
            url(${collection?.landscapeCoverAsset?.url ?? ""})
          `,
          }}
        >
          <div className="flex flex-col gap-4 z-10">
            <div className="flex flex-col w-[480px] gap-1">
              <HeaderDetails data={data} />
            </div>
            <ViewCollectionButton
              handleViewCollection={() => handleViewCollection(collection)}
              handleNavigate={handleViewCollectionNavigation}
            />
          </div>

          <div className="grid grid-cols-3 gap-x-6 gap-y-8">
            {collectionResources.slice(0, 6).map((item) => (
              <div key={item.id} className="basis-full">
                <MediaCardComponent
                  key={item.id}
                  type={type}
                  // @ts-expect-error TODO:: Update the type for the track component
                  resource={item}
                  collectionsData={collection}
                  userAccessState={userAccessState}
                  titleTextClasses="text-white"
                  authorTextClasses="text-white"
                  showDuration={type === "meditations"}
                  durationTextType="MM:SS"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex lg:hidden">
        <div className="flex flex-col w-full ">
          <div
            className="relative  w-full h-[432px] bg-cover bg-no-repeat"
            style={{
              backgroundImage: `
                linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%),
                url(${collection?.landscapeCoverAsset?.url ?? ""})
              `,
            }}
          />

          <div className="flex flex-col bg-black px-6 pb-8 gap-10">
            <div className="flex flex-col gap-6 ">
              <div className="flex flex-col gap-1">
                <HeaderDetails data={data} />
              </div>

              <CarouselSection />
            </div>
            <ViewCollectionButton
              handleViewCollection={() => handleViewCollection(collection)}
              handleNavigate={handleViewCollectionNavigation}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
